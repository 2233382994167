import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, Dropdown, DropdownDivider, Table} from 'react-bootstrap';

import { useAppContext } from '../../AppContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import edit from '../../assets/edit.svg'
import deleteIcon from '../../assets/delete.svg'
import { FaTrash} from 'react-icons/fa';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import search from '../../assets/search.svg'
import adminRoute from '../../services/adminRoute';
import { BsGear } from "react-icons/bs";
import user from '../../assets/profile-circled.svg'
import { supportedLGAs, initialLgaOptions } from './CreateOrder';
import Select from 'react-select';
import Waitlist from '../../components/Modal/Waitlist';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';

const AccountSettings = () => {
    const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
    const { customerId } = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerDetail, setCustomerDetail] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: {
          streetAddress: '',
          LGA: '',
          state: '',
        },
        profilePicture:'',
        createdAt:'',
        
      });
      const [deliveryStates, setDeliveryStates] = useState([]);
      const [showWaitlist, setShowWaitlist] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
    const dropdownRef1 = useRef(null);
    const [lgaOptions, setLgaOptions] = useState([]);
    const [originalLgaOptions, setOriginalLgaOptions] = useState([]);
    const {getSingleAdminCustomers}= adminRoute();
    const {getDeliveryLGAs, getDeliveryStates}= orderRoute();

    useEffect(()=> {
      getSingleAdminCustomers (
        customerId, setLoading,showToast, setCustomerDetail
      )
    },[])

    useEffect(()=>{
      getDeliveryStates(
        showToast, setDeliveryStates 
      )
    },[])
  
    useEffect(() => {
 
      if (customerDetail?.address?.state) {
        const selectedState = deliveryStates.find(state => state.name === customerDetail?.address?.state);
        if (selectedState) {
          getDeliveryLGAs(selectedState.id, showToast, setLgaOptions, setOriginalLgaOptions);
        }
      }
    }, [customerDetail?.address?.state, deliveryStates]);

    const handleChange = (e) => {
      const { name, value } = e.target;
    
      if (name === "state") {
        const selectedState = deliveryStates.find(state => state.name === value);
        if (selectedState) {
          getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
        }
    
    
        setCustomerDetail(prev => ({
          ...prev,
          address: { ...prev.address, [name]: value }
        }));
        setCustomerDetail(prevDetails => ({
          ...prevDetails,
          address: {
            ...prevDetails.address,
            streetAddress: ''
          }
        }));
      } else if (name === "streetAddress") {
       
        setCustomerDetail(prev => ({
          ...prev,
          address: { ...prev.address, [name]: value }
        }));
      } else {
        // Handles all other fields outside of the 'address' object and special cases
        setCustomerDetail(prev => ({ ...prev, [name]: value }));
      }
    };
    
    
    const formatOptionLabel = ({ label, value }) => {
      if (value === "comingSoon") {
        // Split the label to insert styling on a specific part
        const firstPart = "We are coming to ";
        const secondPart = label.split('soon! ')[0].replace(firstPart, '');
        const thirdPart = "soon! ";
        const actionPart = "Join our waitlist and be the first to know when we do.";
       
    
        return (
          <div>
            {firstPart}
            <span>{secondPart}</span>
            {thirdPart}
            <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
          
          </div>
        );
      }
      // Return the label as is for other options
      return label;
    };
  
    const handleInputChange = (inputValue, actionMeta) => {
      if (actionMeta.action === "input-change") {
        const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
        if (filteredLgas.length === 0 && inputValue.trim() !== "") {
          setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
        } else {
          setLgaOptions(filteredLgas);
        }
      }
    };
  
    
   
  
    const handleSelectChange = (selectedOption) => {
      if (!selectedOption) {
      
        setCustomerDetail((prevDetails) => ({
          ...prevDetails,
          address:{ ...prevDetails.address,LGA: ''} 
        }));
      } else if (selectedOption.value === "comingSoon") {
      
        setCustomerDetail((prevDetails) => ({
          ...prevDetails,
          address:{ ...prevDetails.address,LGA: ''}
        }));
        setShowWaitlist(true); 
        const selectedState = deliveryStates.find(state => state.name === customerDetail?.address?.state);
        if (selectedState) {
          getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
        }
       
      } else {
       
        setCustomerDetail((prevDetails) => ({
          ...prevDetails,
          address:{ ...prevDetails.address,LGA: selectedOption.value}
        }));
      }
    };

      // useEffect(() => {
      //   let updatedLgas = [];
      //   if (customerDetail?.address?.state === "Ogun") {
      //     // For Ogun, include only "Magboro"
      //     updatedLgas = ["Magboro"];
      //   } else if (customerDetail?.address?.state === "Lagos") {
      //     // For Lagos, include all LGAs except "Magboro"
      //     updatedLgas = supportedLGAs.filter(lga => lga !== "Magboro");
      //   }
    
      //   // Update lgaOptions state
      //   const newLgaOptions = updatedLgas.map(lga => ({
      //     value: lga.replace(/\s+/g, '-').toLowerCase(),
      //     label: lga
      //   }));
    
      //   setLgaOptions(newLgaOptions);
      // }, [customerDetail?.address?.state]);

      // const handleChange = (e) => {
      //   const { name, value } = e.target;
      
      //   // Handling changes in the state selection and adjusting LGA options accordingly
      //   if (name === "state") {
      //     let updatedLgas = [];
      //     if (value === "Ogun") {
      //       // For Ogun, include only "Magboro"
      //       updatedLgas = ["Magboro"];
      //     } else if (value === "Lagos") {
      //       // For Lagos, include all LGAs except "Magboro"
      //       updatedLgas = supportedLGAs.filter(lga => lga !== "Magboro");
      //     }
        
      //     // Update lgaOptions state
      //     const newLgaOptions = updatedLgas.map(lga => ({
      //       value: lga.replace(/\s+/g, '-').toLowerCase(),
      //       label: lga
      //     }));
        
      //     setLgaOptions(newLgaOptions);
      
      //     // Also update the state in the address object
      //     setCustomerDetail(prev => ({
      //       ...prev,
      //       address: { ...prev.address, [name]: value }
      //     }));
      //   } else if (name === "streetAddress") {
      //     // Handling changes for street address within the address object
      //     setCustomerDetail(prev => ({
      //       ...prev,
      //       address: { ...prev.address, [name]: value }
      //     }));
      //   } else {
      //     // Handles all other fields outside of the 'address' object and special cases
      //     setCustomerDetail(prev => ({ ...prev, [name]: value }));
      //   }
      // };
      
      
      // const formatOptionLabel = ({ label, value }) => {
      //   if (value === "comingSoon") {
      //     // Split the label to insert styling on a specific part
      //     const firstPart = "We are coming to ";
      //     const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      //     const thirdPart = "soon! ";
      //     const actionPart = "Join our waitlist and be the first to know when we do.";
         
      
      //     return (
      //       <div>
      //         {firstPart}
      //         <span>{secondPart}</span>
      //         {thirdPart}
      //         <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
            
      //       </div>
      //     );
      //   }
      //   // Return the label as is for other options
      //   return label;
      // };
      
      // const handleInputChange = (inputValue, actionMeta) => {
      //   if (actionMeta.action === "input-change") {
      //     let filteredLgas;
          
      //     // Filter LGAs based on the state
      //     if (customerDetail?.address?.state === "Ogun") {
      //       filteredLgas = ["Magboro"];
      //     } else if (customerDetail?.address?.state === "Lagos") {
      //       filteredLgas = supportedLGAs.filter(lga => lga !== "Magboro");
      //     } else {
      //       filteredLgas = [];
      //     }
      
      //     // Filter the LGAs based on the input value
      //     let updatedLgas = filteredLgas.filter(lga => lga.toLowerCase().includes(inputValue.toLowerCase()));
      
      //     // Determine if we need to add the "coming soon" option
      //     if (updatedLgas.length === 0 && inputValue.trim() !== "") {
      //       setLgaOptions([
      //         ...filteredLgas.map(lga => ({
      //           value: lga.replace(/\s+/g, '-').toLowerCase(),
      //           label: lga
      //         })),
      //         { value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }
      //       ]);
      //     } else {
      //       // Reset to the filtered LGAs if there's a match or the input is cleared
      //       setLgaOptions(filteredLgas.map(lga => ({
      //         value: lga.replace(/\s+/g, '-').toLowerCase(),
      //         label: lga
      //       })));
      //     }
      //   }
      // };
      
     
    
      // const handleSelectChange = (selectedOption) => {
      //   if (!selectedOption) {
        
      //     setCustomerDetail((prevCustomerDetail) => ({
      //       ...prevCustomerDetail,
      //       address:{ ...prevCustomerDetail.address,LGA: ''} 
      //     }));
      //   } else if (selectedOption.value === "comingSoon") {
        
      //     setCustomerDetail((prevCustomerDetail) => ({
      //       ...prevCustomerDetail,
      //       address:{ ...prevCustomerDetail.address,LGA: ''}
      //     }));
      //     setShowWaitlist(true); 
      //   } else {
         
      //     setCustomerDetail((prevCustomerDetail) => ({
      //       ...prevCustomerDetail,
      //       address:{ ...prevCustomerDetail.address,LGA: selectedOption.value}
      //     }));
      //   }
      // };
      

      const formatDateToLongForm = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };
    
    
 
  const handleBack = () => {
   
    navigate(-1);
  }
  const handleEdit = () => {
    setIsEditing(true);
  };




  return (
    <div>
        <div className='d-flex  justify-content-between align items-center header-admin  py-5 pe-5 px-2 ps-4'>
        <div className='d-flex gap-3 align-items-center'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow' />
        <h3 style={{color:'#344054'}} className='my-auto'>Account Settings</h3>
        </div>
      
        </div>
        <div className=' pe-5 ps-4'>
        {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):    
    <div className='mb-5'>
   
          <Form className=''>
          <div  className='d-flex gap-3 mb-3'>
      <Image roundedCircle src={customerDetail?.profilePicture ? customerDetail?.profilePicture : user} style={{width:'100px'}}/>
   
     
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-auto'>Edit</p>
      )}
          </div>
  <Row className='py-3'>
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>First Name:</p>
                <Form.Control type='text' value={customerDetail?.firstName}  disabled={!isEditing} onChange={handleChange}/>
                </Col>
              
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Last Name:</p>
                <Form.Control type='text' value={customerDetail?.lastName}  disabled/>
                
                </Col>
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Email Address:</p>
                <Form.Control type='text' value={customerDetail?.email}  disabled/>
                
                </Col>
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Phone Number:</p>
                <Form.Control type='text' value={customerDetail?.phoneNumber}  disabled/>
                
                </Col>
                {!isEditing ? 
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Default  Address:</p>
                <Form.Control 
  type='text' 
  value={customerDetail?.address 
    ? `${customerDetail.address.streetAddress || ''}${customerDetail.address.city?.name ? ', ' + customerDetail.address.city?.name : ''}${customerDetail.address.state?.name ? ', ' + customerDetail.address.state?.name : ''}` 
    : ''} 
  disabled
/>
 </Col>: <>
                
                      <Col xs={4}className=' mb-4 px-2' >
                      <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>State/Province:</p>

<Form.Select
  className="custom-select"
  onChange={handleChange}
  name="state"
  value={customerDetail?.address?.state || ''}

>
  <option value="" disabled>Select a state</option>
  {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
</Form.Select>


                      
                      </Col>
                      <Col xs={4} className=' mb-4 px-2'>
                       
                          <p
                          style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>
                            LGA {isEditing && !customerDetail?.address?.LGA && <span>Please select a state first</span>}
                          </p>
                         
                              <Select
         formatOptionLabel={formatOptionLabel}
         options={lgaOptions}
         onInputChange={handleInputChange}
         onChange={handleSelectChange}
        required
       
          value={lgaOptions.find(option => option.value === customerDetail?.address?.LGA) || ''}

         name="LGA"
         isDisabled={!isEditing}
         isClearable={true}
         styles={{
          menuList: (provided) => ({
            ...provided,
            maxHeight: '150px', // Adjust the dropdown's max height
            overflowY: 'auto'    // Add scroll to the dropdown menu
          })
        }}
       />
                   
                      </Col>
                      <Col xs={4} className=' mb-4 px-2'>
                       
                       <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Street Address:</p>
                                
                                
                                 <Form.Control
                                   type="text"
                                   placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
                                 
                                   
                                   onChange={handleChange}
                                   name="streetAddress"
                                   value={customerDetail?.address?.streetAddress}
                                 />
                                           
                             </Col>
                </>}
                
                
                <Col xs={4} className=' mb-4 px-2'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Date Joined:</p>
                <Form.Control type='text' value={customerDetail?.createdAt ? new Date(customerDetail.createdAt).toLocaleDateString('en-CA') : 'N/A'} disabled/>
                
                </Col>

            </Row> 
            <div>
              <h3 className='mb-3' style={{ color:'#355054', fontWeight:'500'}}>Notification Settings</h3>
              <div>
                <Row className='mb-3'>
                    <Col xs={8}></Col>
                    <Col xs={4}>
                        <Row>
                        <Col xs={2}><p style={{color:'#33333380', fontSize:'12px'}}>In-App</p> </Col>
                        <Col xs={2}><p style={{color:'#33333380', fontSize:'12px'}}>Email</p></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='' style={{borderBottom:'1px solid #F1F3F9'}}>
                    <Col xs={8}>
                        <p style={{color:'#333333', fontWeight:'500'}} className='mb-0'>Order Confirmation</p>
                        <p style={{color:'#33333380', fontSize:'12px'}}>You will be notified when your order is confirmed for pickup.</p>

                    </Col>
                    <Col xs={4}>
                        <Row>
                        <Col xs={2}><Form.Check /> </Col>
                        <Col xs={2}><Form.Check checked /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='pt-3' style={{borderBottom:'1px solid #F1F3F9'}}>
                    <Col xs={8}>
                        <p style={{color:'#333333', fontWeight:'500'}} className='mb-0 '>Order Status Changed</p>
                        <p style={{color:'#33333380', fontSize:'12px'}}>You will be notified when your order is in transit.</p>

                    </Col>
                    <Col xs={4}>
                        <Row>
                        <Col xs={2}><Form.Check checked /> </Col>
                        <Col xs={2}><Form.Check checked /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='pt-3' style={{borderBottom:'1px solid #F1F3F9'}}>
                    <Col xs={8}>
                        <p style={{color:'#333333', fontWeight:'500'}} className='mb-0'>Marketing Email</p>
                        <p style={{color:'#33333380', fontSize:'12px'}}>You will be notified on marketing information of our product.</p>

                    </Col>
                    <Col xs={4}>
                        <Row>
                        <Col xs={2}><Form.Check /> </Col>
                        <Col xs={2}><Form.Check /></Col>
                        </Row>
                    </Col>
                </Row>
              </div>
              </div>

           {isEditing &&
            <div className=' d-flex justify-content-end mt-2 me-4'>
<Button className='px-5 py-2'  type='submit'>Save</Button>
</div>}
          
            </Form>
            <div className='my-5'>
                <Button variant='danger' onClick={() => 
    setShowDeleteModal(true)}>Delete Account</Button>
            </div>
         
        </div>
}
</div>
<DeleteProductModal  item={customerDetail}
      itemType="customer-account"
    />

    </div>
  )
}

export default AccountSettings

