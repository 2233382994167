

import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce, set } from 'lodash';
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useLocation, useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/deactivate.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LogoutModal from '../../components/Modal/Logout';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';


const ProductCategories = () => {
    const { showToast,showDeleteModal, setShowDeleteModal,productState,setProductState  } = useAppContext();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isStockOpen, setIsStockOpen] = useState(false);
    const [isSeasonOpen, setIsSeasonOpen] = useState(false);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [searchCategory, setSearchCategory] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);;
    const [globalProducts, setGlobalProducts] = useState([]);
    const { getGlobalProducts, getProductCategory, filterCategoryByStatus } = productRoute();
    const [exportFormat, setExportFormat] = useState(null); 
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [categories, setCategories] = useState([]);
  
    const [currentCategory, setCurrentCategory] = useState(null);
    const tableRef = useRef(null);
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    const location = useLocation()

   

      // useEffect(() => {
    

      //   getProductCategory(
      //     setLoading, showToast, setCategories,pagination,
      //     setPagination
         
      //   )
    
        
      // }, []); 

      useEffect(() => {
        // Restore the current page if available in location state
        if (location.state?.currentPage) {
            setPagination(prevState => ({
                ...prevState,
                currentPage: location.state.currentPage
            }));
           
        }
    }, [location?.state]);
     
    const [pagination, setPagination] = useState({
      currentPage: location.state?.currentPage || 1,
        itemsPerPage: 20,
        totalPages: 0,
        totalCategories: 0,
    });
    
  
  
        useEffect(() => {
          // Define a debounced version of the getProductCategory function
          const debouncedGetCategories = debounce((searchCategory) => {
              getProductCategory(
                  setLoading,
                  showToast,
                  setCategories,
                  pagination,
                  setPagination,
                  searchCategory,
                  
              );
          }, 500);
      
          // Call the debounced getProductCategory function when the search product changes and it's not empty
          if (searchCategory !== '') {
            setSearchPerformed(true)
              debouncedGetCategories(searchCategory);
          } else {
              // If the search product is empty, call getProductCategory immediately
              getProductCategory(
                  setLoading,
                  showToast,
                  setCategories,
                  pagination,
                  setPagination,
                
              );
          }
      
          // Cleanup function to cancel the debounced function
          return () => {
              debouncedGetCategories.cancel();
          };
      }, [pagination?.currentPage, searchCategory, location.state?.currentPage]);

    

        useEffect(() => {
          function handleClickOutside(event) {
            if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
              setIsOpen(false);
            }
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
              setIsOpen2(false);
            }
            if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
              setIsExportOpen(false);
            }
          }
      
          function handleScroll() {
            setIsOpen(false);
            setIsOpen2(false);
            setIsExportOpen(false);
          }
      
          // Add event listeners
          document.addEventListener("mousedown", handleClickOutside);
          window.addEventListener("scroll", handleScroll, true);
      
          return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
          };
        }, []);

        const handleCSVExport = () => {
          const csvData = prepareCSVData();
          if (csvData) {
            return (
              <CSVLink data={csvData.data} headers={csvData.headers} filename="category-list.csv" className='csv-link'>
                As CSV
              </CSVLink>
            );
          } else {
            console.error("CSV data is not available.");
          }
        };
        
        const prepareCSVData = () => {
          if (categories.length === 0) {
            console.warn("No categorys available for CSV export.");
            return null;
          }
          
          const headers = [
            { label: 'Image', key: 'categoryImage' },
            { label: 'Name', key: 'categoryName' },
            { label: 'No of products', key: 'products' },
            { label: 'Date created', key: 'dateCreated' },
         
          ];
          
          const data = categories.map(category => ({
            categoryImage: category.categoryImage,
            categoryName: category?.categoryName, 
            products: category?.products?.length || '-',
            dateCreated:category?.createdAt ? new Date(category?.createdAt).toLocaleDateString('en-CA') : 'N/A',
          }));
          
          return { headers, data };
        };
        
        

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  
  const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
  const handleFocus = () => {
    setIsFocused(true)
  };


  const handleBlur = () => {
    setIsFocused(false);
  };
  const handlePageChange = (page) => {
    setPagination(prevState => ({
      ...prevState,
      currentPage: page
    }));
  };
 

const handleEdit= (category)=> {
  navigate('/admin/categories/edit', { state: { category } });
}


const capitalizeFirstLetter = (string) => {
  return string?.replace(/\b(\w)/g, s => s?.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };

    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

const determineStatus = (category) => {
  let statusText = 'Status Unknown';
  let statusStyle = { backgroundColor: 'grey', color: 'white' };

  if (category?.status) {
 
    statusText = capitalizeFirstLetter(category.status);
  } 
  else{
    statusText = 'Status Unknown';
  }
 

  statusStyle = getStatusStyle(statusText.toLowerCase());
  return <span style={statusStyle}>{statusText}</span>;
};



const handleAdd = ()=> {
  navigate('/admin/products/add')
}





const handleStateClick = (pState) => {
  setProductState(pState);
  if(pState === 'Products'){
    navigate('/admin/products')
  }else   if(pState === 'Categories'){
    navigate('/admin/categories')
    }}



const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename: 'Category-List',
  sheet: 'categories'
})



const handlePDFExport = () => {
  const input = document.getElementById('product-table');
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
    pdf.save('product-list.pdf');
  });
};

const filterProducts = (category) => {

  const categoryName = encodeURIComponent(category?.categoryName);
   const categoryId = category?._id;
  

  navigate(`/admin/categories/${categoryName}`, { state: { id: categoryId } });
};



const handleStatusFilter = (status) => {
  let statusFilter;
  if(status === 'Inactive'){
    statusFilter='inactive'
  }else if(status === 'Active'){
    statusFilter='active'
  
  }
  filterCategoryByStatus(statusFilter, setLoading, setCategories, showToast,pagination,setPagination);
  
};


const getAllCategories = ()=> {
  getProductCategory(
    setLoading,
    showToast,
    setCategories,
    pagination,
    setPagination,
  
);
}

  return (
    <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
        <h3 style={{color:'#344054'}}>Categories</h3>
     <div className='d-flex gap-3'>
  
 

      <Button className='d-flex align-items-center gap-3 p-2' onClick={()=> navigate('/admin/categories/add')}>
      <FontAwesomeIcon icon={faPlus} />
       Add Category 
      </Button>

      
     </div>
    </div>
    <div className='d-flex gap-5 mb-3 ps-4' style={{borderBottom:'1px solid #EDEDED'}}>
    {['Products', 'Categories'].map((pState, index) => (
          <p
            key={index}
            className={`order-item pb-2 mb-0 me-4 ${productState === pState ? 'active' : ''}`}
            onClick={() => handleStateClick(pState)}
            style={{ cursor: 'pointer', marginBottom: '5px' }}
          >
            {pState}
          </p>
        ))}
      </div>

   {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):categories?.length > 0 ? <div className='px-4'>
    <div className='d-flex align-items-center justify-content-between py-4'>
      <div className='d-flex align-items-center gap-5'>
       
       
 <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'> {pagination?.totalCategories !== 0 ? pagination?.totalCategories : categories?.length} {pagination?.totalCategories > 1 || categories.length > 1 ? 'Categories' :'Category'}</p> 


        <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500'}}>
        {!isFocused && searchCategory === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
    
         </>)}
         {searchCategory !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchCategory('')}></i>}
        <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search by category name'
        className='w-100'
        value={searchCategory}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={(e)=>setSearchCategory(e.target.value)}
        />
      </div>
      </div>
       
        <div className='d-flex gap-2 align-items-center'>
           
            <Dropdown show={isOpen2}  ref={dropdownRef2}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item  className='d-flex justify-content-between' onClick={()=> {toggleDropdown2(); getAllCategories()}}>All</Dropdown.Item>
    <DropdownDivider />
    <Dropdown.Item  className='d-flex justify-content-between' onClick={()=> {toggleDropdown2(); handleStatusFilter('Active')}}>Active</Dropdown.Item>
    <DropdownDivider/>
     <Dropdown.Item className='d-flex justify-content-between' onClick={()=> {toggleDropdown2(); handleStatusFilter('Inactive')}}>Disabled</Dropdown.Item>
        
    </Dropdown.Menu>
   </Dropdown>

   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
        <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 '>
        Export<img src={exportIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.Item> <DownloadTableExcel
                    filename="categories table"
                    sheet="categories"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel></Dropdown.Item>
          <DropdownDivider />
          <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
          <DropdownDivider />
       
          {handleCSVExport()}
      
      
         
        </Dropdown.Menu>
      </Dropdown>
            
        </div>
    </div>
    <Table hover className='my-3' id="product-table" ref={tableRef} >
       <thead>
            <tr style={{fontWeight:'600'}} className='mb-2'>
            <th style={{fontWeight:'600'}} className='pb-3'>Image</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Name</th>
                <th style={{fontWeight:'600'}} className='pb-3'>No of products</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Date created</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                
            </tr>
        </thead>
            <tbody className='py-2'>
            {categories?.map((category) => (
              <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={category?.id} >
                <td style={{paddingTop: '15px', paddingBottom: '15px' }}> <img src={category?.categoryImage} style={{width:'40px', height:'40px', borderRadius:"50%"}}/>
</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px', color:'#194298', cursor:'pointer', textDecoration:'underline' }}><div onClick={(e) => {e.stopPropagation();filterProducts(category)}}className='d-flex'> {category?.categoryName}</div></td>
              
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>&nbsp; &nbsp;{category?.products?.length || '-'}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{category?.createdAt ? new Date(category?.createdAt).toLocaleDateString('en-CA') : 'N/A'}</td>
              
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(category)}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}><div onClick={(e) => e.stopPropagation()}className='d-flex'><img src={edit}  style={{cursor:'pointer'}} onClick={() => handleEdit(category)}/> &nbsp;&nbsp; &nbsp;<img src={deleteIcon} onClick={() => { setCurrentCategory(category);
    setShowDeleteModal(true);
}}/></div></td>
               <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => filterProducts(category)}>View</td>
              </tr>
            ))}
            </tbody>
    </Table> 
 
    {categories.length > 0 && pagination.totalPages > 1 &&  (

<div className='d-flex justify-content-between align-items-center my-5 pt-5' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>Showing {categories?.length} of {pagination.totalCategories} categories</p>
  {pagination.totalPages > 1 && 
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}
  </div>
    : searchPerformed && categories.length === 0 ?  <div className='fw-medium px-5' style={{padding:'40px'}}><h4 className='mb-2'>No categories found</h4><Button onClick={() => setSearchCategory('')}>View all categories</Button>
    </div>:
    <div className='fw-medium ' style={{padding:'40px'}}><h4>You have no categories</h4><Button onClick={() => setSearchCategory('')}>View all categories</Button></div>
   }
<DeleteProductModal item={currentCategory}
      itemType="category" actionType="deactivate"/>

  
    </section>
  
  )
}

export default ProductCategories;