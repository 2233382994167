import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'
import { localStorageRemoveItem } from '../../App'

const LogoutModal = () => {
    const {showLogoutModal, setShowLogoutModal}= useAppContext()
    const navigate = useNavigate();
  const handleLogout = () => {
   
    // localStorageRemoveItem("userData");
    localStorageRemoveItem("userToken");
    localStorageRemoveItem("userType");
    localStorageRemoveItem("userDelight");
    trackEvent('Logout', {
      log_out_at: new Date().toISOString(),
      button_clicked: 'Logout', 
      device_type: navigator.userAgent 
    });
    setShowLogoutModal(false);
  
    navigate("/");
   
  };
  const handleCancel = () => {
    
    setShowLogoutModal(false);
 
   
  };

  const onHide = ()=>{
    setShowLogoutModal(false)
  
  }

  return (
    <Modal show={showLogoutModal} onHide={onHide} centered>
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body className='d-flex flex-column justify-content-center text-center gap-3 px-5 pb-5'>
            <div><img src={sad} alt='success' style={{width:'70px', height:'70px'}}/></div>
            
            <div className=''>
           
            <h4 style={{color:'#00000', fontWeight:'500'}}>Are you sure you want to log out?</h4>
            </div>
            <div className='d-flex gap-3 text-center justify-content-center'>
            <Button variant='outline-danger' onClick={handleCancel}>No, Cancel</Button>
            <Button variant='danger' onClick={handleLogout}>Yes, Logout</Button>
            </div>
           

          </Modal.Body>
          
            </Modal>
  )
}

export default LogoutModal
