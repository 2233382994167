import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate} from 'react-router-dom'
import { Modal, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, InputGroup , ListGroup, Card} from 'react-bootstrap';
import { debounce } from 'lodash'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select from 'react-select';
import TemplateModal from '../../components/Modal/TemplateModal';
import { toSvg } from 'html-to-image';
import DomToImage from 'dom-to-image';
import SuccessModal from '../../components/Modal/SuccessModal';



const AddBoxes = () => {
    const navigate = useNavigate();
    const { showToast, setShowSuccessModal} = useAppContext();
    const { getGlobalProducts, getGlobalBoxTemplates,addBox } = productRoute();
    const [globalProducts, setGlobalProducts] = useState([]);
    const [globalTemplates, setGlobalTemplates] = useState([]);
    const [searchProduct, setSearchProduct] = useState('');
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [boxLoading, setBoxLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const targetRef = useRef(null);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [newlyAddedItems, setNewlyAddedItems] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState (null)
    const [boxData, setBoxData] = useState({
        boxName:'',
        boxDescription:'',
        boxPrice:'',
    })
    const [captureLoading, setCaptureLoading] = useState(false);
    const desktopPreviewRef = useRef(null);
    const mobilePreviewRef = useRef(null);
    const [capturedDesktopImage, setCapturedDesktopImage] = useState(null);
    const [capturedMobileImage, setCapturedMobileImage] = useState(null);
    const [showTemplateModal, setShowTemplateModal]= useState(false);
    useEffect(() => {
        // Define a debounced version of the getProducts function
        const debouncedGetProducts = debounce((searchProduct) => {
          getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
            searchProduct,
        );
        }, 500);
    
        if (searchProduct !== '') {
          setSearchPerformed(true)
            debouncedGetProducts(searchProduct);
        } else {
          
            getGlobalProducts(
                setLoading,
                showToast,
                setGlobalProducts,
            );
        }
    
        // Cleanup function to cancel the debounced function
        return () => {
            debouncedGetProducts.cancel();
        };
    }, [searchProduct]);


    
    useEffect(() => {

      getGlobalBoxTemplates(
        setLoading, showToast, setGlobalTemplates
       
      )
        
      }, []);

      useEffect(() => {

        if(!!capturedDesktopImage &&!!capturedMobileImage){
         setCaptureLoading(false)
        }
         }, [capturedDesktopImage,capturedMobileImage]);
   
    

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
    
            [{'list': 'ordered'}, {'list': 'bullet'}], // lists
            [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}], // indent
    
          
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
            [{'color': []}, {'background': []}], // dropdown with defaults
            [{'font': []}],
            [{'align': []}],
    
            ['clean'], // remove formatting
            ['link', 'image', 'video'] // link and image, video
        ]
    };
 
    const togglePopover = () => {
        setIsPopoverVisible(prev => !prev);
      };
    
      const hidePopover = () => setIsPopoverVisible(false);
    
      const isProductChecked = (productId) => {
        return newlyAddedItems?.some(item => item.productId.id === productId) 
      };
    

      const getProductQuantity = (productId) => {
        const product = newlyAddedItems.find(item => item.productId.id === productId) 
        return product ? product.quantity : 0;
      };
    
      const handleToggleItem = (productId) => {
        const product = globalProducts.find(p => p.id === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
        if (product.stockLevel === 'out_of_stock' || product.quantityAvailable === 0) {
          showToast({ toastText: 'This product is out of stock.', isSuccess: false });
          return;
        }
    
      
        const newItemIndex = newlyAddedItems.findIndex(item => item.productId.id === productId);
    
        if (newItemIndex !== -1) {
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems.splice(newItemIndex, 1);
          setNewlyAddedItems(updatedNewItems);
          
        } else {
          const newItem = {
            productId: product,
            quantity: 1
          };
          setNewlyAddedItems([...newlyAddedItems, newItem]);
         
        }
      };
    
      const handleChangeQuantity = (productId, delta) => {
        const product = globalProducts.find(p => p.id === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
       
        const newItemIndex = newlyAddedItems.findIndex(item => item.productId.id === productId);
    
      if (newItemIndex !== -1) {
          const item = newlyAddedItems[newItemIndex];
          const newQuantity = item.quantity + delta;
    
          if (newQuantity <= 0) {
            const updatedNewItems = [...newlyAddedItems];
            updatedNewItems.splice(newItemIndex, 1);
            setNewlyAddedItems(updatedNewItems);
           
      
            return;
          }
    
          if (newQuantity > product.quantityAvailable) {
            if( product.quantityAvailable === 0){
              showToast({ toastText: 'This product is out of stock.', isSuccess: false });
            }else{
            showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
            return;
          }
    
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems[newItemIndex] = { ...item, quantity: newQuantity };
          setNewlyAddedItems(updatedNewItems);
          
        }
      };

    const handleBack = ()=> {
        navigate(-1)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBoxData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    
    const handleDescriptionChange = (value) => {
    
        setBoxData(prev => ({ ...prev, boxDescription: value }));
    };
    
   
    

    const isFormValid = () => {
       
        return boxData?.boxName &&
        newlyAddedItems?.length > 0 &&
        boxData?.boxPrice && boxData?.boxDescription &&
        selectedTemplate && capturedDesktopImage && capturedMobileImage
      };
      const stripHtml = (html) => {
        try {
            const temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;
            return temporalDivElement.textContent || temporalDivElement.innerText || "";
        } catch (error) {
            console.error('Error stripping HTML:', error);
            return "";  // Return empty string on error
        }
    };
   
    const handleUploadTemplate = () => {
      if (!boxData.boxName) {
          showToast({ toastText: 'Please enter the box name before selecting a template.', isSuccess: false });
          return;
      }
      setShowTemplateModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
      setShowTemplateModal(false);
      setSelectedTemplate(null)
  };

  // Handle template selection
  const handleTemplateClick = (template) => {
   
      setSelectedTemplate(template);
  

  };



  const captureImage = (ref, setCapturedImage) => {
    const node = ref.current;
    
  
    if (!node) {
      console.error('Element to capture not found');
      return;
    }
  
    toSvg(node, {
      quality: 1,
      width: node.offsetWidth,
      height: node.offsetHeight,
    })
      .then((dataUrl) => {
      
        if (dataUrl === 'data:,') {
          console.error('Captured image is empty.');
          return;
        }
        setCapturedImage(dataUrl);
     })
      .catch((error) => {
        console.error('Failed to capture image:', error);
      });
  };

  const handleSelectTemplate = () => {
    
    setShowTemplateModal(false);

    const imagesLoaded = new Promise((resolve, reject) => {
    const desktopPreviewImage = new window.Image();
    const mobilePreviewImage = new window.Image();

    let loadedCount = 0;
    const onLoad = () => {
      loadedCount++;
     
      if (loadedCount === 2) {
        resolve();
      }
    };

    const onError = (error) => {
      console.error('Image failed to load:', error);
      reject(error);
    };

    
    desktopPreviewImage.src = selectedTemplate.desktopTemplate;
    mobilePreviewImage.src = selectedTemplate.mobileTemplate;

     desktopPreviewImage.onload = onLoad;
     mobilePreviewImage.onload = onLoad;

     desktopPreviewImage.onerror = onError;
     mobilePreviewImage.onerror = onError;
   });
   imagesLoaded
   .then(() => {
     
     setCaptureLoading(true)
    setTimeout(() => {
      captureImage(desktopPreviewRef, setCapturedDesktopImage);
      captureImage(mobilePreviewRef, setCapturedMobileImage);
    }, 1000);
  })
  .catch((error) => {
    console.error('Failed to load images:', error);
  });
  };




const dataURLToBlob = (dataUrl) => {
  const [header, data] = dataUrl.split(',');

  // Check if the data URL is for SVG
  if (header.includes('image/svg+xml')) {
    const svgBlob = new Blob([decodeURIComponent(data)], { type: 'image/svg+xml' });
    return svgBlob;
  }

  // For other data URL formats
  const mime = header.match(/:(.*?);/)[1];
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};


const handleSave = (e) => {
    e.preventDefault();
    const plainTextDescription = stripHtml(boxData.boxDescription);
  
 
    if (plainTextDescription.length < 8 || plainTextDescription.length > 400) {
        showToast({ toastText: "Box description must be between 8 and 400 characters.", isSuccess: false });
      return;
    
  }
 

    const formData = new FormData();
    const timestamp = Date.now();
    formData.append('name', boxData.boxName);
   
    formData.append('amount', boxData.boxPrice);
    newlyAddedItems.forEach((item, index) => {
      formData.append(`items[${index}][productId]`, item?.productId?.id);
      formData.append(`items[${index}][quantity]`, item?.quantity);
    });
    
    if (capturedDesktopImage) {
      const desktopBlob = dataURLToBlob(capturedDesktopImage);
    
      formData.append('boxImage', desktopBlob, `desktop-box-${timestamp}.svg`);
    }

    if (capturedMobileImage) {
      const mobileBlob = dataURLToBlob(capturedMobileImage);
     
      formData.append('mobileBoxImage', mobileBlob, `mobile-box-${timestamp}.svg`);
    }
    
    if (boxData.boxDescription !== null && boxData.boxDescription !== '' && boxData.boxDescription !== undefined) {
        formData.append('description', plainTextDescription);
    }

 

    addBox (
        formData,
        setBoxLoading,
        showToast,
        setShowSuccessModal
     
      )

  
};
 
 
  return (
    <div className=''>
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'>Add Subscription Box</h3>
        </div>

        <div className=' pe-5 ps-4 '>
        {boxLoading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Box Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='boxName' value={boxData.boxName} onChange={handleChange} required/>
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Price <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='boxPrice' value={boxData.boxPrice} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setBoxData({ ...boxData, boxPrice: numericValue })
                }} required/>
                
                </Col>
              
                <Col xs={12} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Item Selection:</p>
                <div onClick={togglePopover} ref={targetRef} style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px',  }} className='d-flex align-items-center justify-content-between'>
                
                <div className='item-container'>
                     {newlyAddedItems?.length > 0 ? (
                       newlyAddedItems?.map((item) => (
                         <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500',fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item.productId.id} onClick={(e) => {
                           e.stopPropagation(); // Prevent popover from toggling
                           handleToggleItem(item.productId.id);
                         }}>
                           {item.productId?.productName} ({item.quantity}) &nbsp; X
                         </Button>
                       ))
                     ) : (
                       <span className="text-muted">Select items to add to this box.</span>
                     )}
                     </div>
                     <div className='d-flex justify-content-end'>
                     <i className="bi bi-chevron-down"></i>
                     </div>
                               </div>
                              
                               <Overlay
                 show={isPopoverVisible}
                 target={targetRef.current}
                 placement="bottom"
                 rootClose={true}
                 onHide={hidePopover}
               >
              
                                 <Popover className='custom-popover' style={{ width: '430px' }}>
                                   <Popover.Body>
                                     <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
                                       {searchProduct !== '' && (
                                         <i className='bi bi-x' style={{ top: '5%', right: '1.5%', position: 'absolute', fontSize: '1.5rem', color: '#98A2B3' }} onClick={() => setSearchProduct('')}></i>
                                       )}
                                       <Form.Control
                                         style={{ border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius: '3px', fontWeight: '500' }}
                                         placeholder='Search by product name'
                                         value={searchProduct}
                                         onChange={(e) => setSearchProduct(e.target.value)}
                                         autoFocus
                                       />
                                     </div>
                                     {loading ? (
                                       <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                                         <div className="spinner-border text-success" role="status">
                                           <span className="visually-hidden">Loading...</span>
                                         </div>
                                       </div>
                                     ) : (
                                       globalProducts.length > 0 ? (
                                         <>
                                           <div style={{ borderTop: '1px solid #E1E6EF' }} className='pb-2 pt-3'>
                                             <h5 className='' style={{ fontWeight: '500' }}>Items</h5>
                                           </div>
                                           <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border: 'none' }} className='no-border-listgroup'>
                                             {globalProducts.map((product) => (
                                               <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                                                 <Form.Check
                                                   type="checkbox"
                                                   label={`${product.productName} - ₦${product.price.toLocaleString()}`}
                                                   checked={isProductChecked(product.id)}
                                                 onChange={() => handleToggleItem(product.id)}
                                                 />
                                                 {isProductChecked(product?.id) && (
                                                   <div className="btn-group btn-group-sm">
                                                     <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }} onClick={() => handleChangeQuantity(product.id, -1)}>-</Button>
                                                     <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>{getProductQuantity(product.id)}</Button>
                                                     <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}  onClick={() => handleChangeQuantity(product.id, 1)}>+</Button>
                                                   </div>
                                                 )}
                                               </ListGroup.Item>
                                             ))}
                                           </ListGroup>
                                         </>
                                       ) : (
                                         searchPerformed && globalProducts.length === 0 ? (
                                           <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                             <h4 className='mb-2'>No products found</h4>
                                             <Button onClick={() => setSearchProduct('')}>View all products</Button>
                                           </div>
                                         ) : (
                                           <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                             <h4>You have no products</h4>
                                           </div>
                                         )
                                       )
                                     )}
                                   </Popover.Body>
                                 </Popover>
                               </Overlay>
                             
                
                
                </Col>
                <Col xs={12} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Box Description:</p>
                <ReactQuill value={boxData.boxDescription}  onChange={handleDescriptionChange}  modules={modules}/>
                
                </Col>

            </Row> 

           <div className='my-4'>{selectedTemplate ? (
            <>
             <div className='d-flex gap-2 align-items-center '>
             <Button className='' variant='outline-secondary' style={{borderStyle:'dashed',color:'#353542', fontWeight:'500'}}>Selected Box Template</Button>
                                <img src={deleteIcon} onClick={()=> {setSelectedTemplate(null); setCapturedDesktopImage(null);setCapturedMobileImage(null)}} style={{ width: '24px', height: '24px', cursor: 'pointer',}} /></div>
                               { !capturedDesktopImage && !capturedMobileImage ?
                            
                            
                            <div className="d-flex  gap-3 mt-3 align-items-center">
                            
                           
                            <div className="template-preview" style={{ position: 'relative'}} ref={desktopPreviewRef}>
                        <img
                            src={selectedTemplate.desktopTemplate}
                            alt="Selected Template"
                            className="img-fluid"
                            style={{ width: '300px', height: '300px' }}
                        />

<Card.ImgOverlay  className='d-flex justify-content-center align-items-center mx-auto px-2'style={{width:'55px', height:'90px', marginTop:'205px'}}>
                            <div className='justify-content-center align-items-center'>
                        
                       
                          <p style={{ color: 'white', fontSize: '13.5px', fontWeight:'500', textTransform:'uppercase', letterSpacing:'0.5%',lineHeight:'15px',fontFamily:'DM Sans', fontWeight:'700'  }}  className='text-center text-align-center align-items-center justify-content-center '>{boxData.boxName}</p>  
                        </div>
                        </Card.ImgOverlay>
                  
                </div>
                <div className="template-preview" style={{ position: 'relative'}} ref={mobilePreviewRef}>
                        <img
                            src={selectedTemplate.mobileTemplate}
                            alt="Selected Template"
                            className="img-fluid"
                            style={{ width: '200px', height: '200px' }}
                        />
                        
                        <Card.ImgOverlay  className='d-flex justify-content-center align-items-center mx-auto px-2'style={{width:'50px', height:'60px', marginTop:'140px'}}>
                            <div className='justify-content-center align-items-center'>
                          <p style={{ color: 'white', fontSize: '9.5px', fontWeight:'500', textTransform:'uppercase', letterSpacing:'0.5%',lineHeight:'10px',fontFamily:'DM Sans', fontWeight:'700'    }}  className='text-center text-align-center align-items-center justify-content-center '>{boxData.boxName}</p>  
                        </div>
                        </Card.ImgOverlay>
                        </div>
            </div> 
             : 
                             <div className="d-flex mt-3 gap-3 align-items-center">
          
            {capturedDesktopImage && (
                 <div>
                   
                     <img src={capturedDesktopImage} alt="Captured Desktop" />
                 </div>
             )}

             {capturedMobileImage && (
                 <div>
                    
                     <img src={capturedMobileImage} alt="Captured Mobile" />
                 </div>
             )}

             </div>

             }  
  {captureLoading && 
        <div className='d-flex gap-1 justify-content-center align-items-center text-center' style={{ padding: '10px 0px' }}>
        <div className="spinner-border text-success" role="status">
         
        </div>
        <p className="my-auto">Capturing Boxes...</p>
      </div>
}

                            
                            
                   
            
            <div>


           

                            
                               </div>
                            </>
                        ) : (<>
                           
                            <Button variant="outline-secondary" onClick={handleUploadTemplate} style={{ 
                                color:'333333',borderStyle:'dashed',borderRadius:'4px',  }} className='px-4'>
                            Upload Box Template<sup className='text-danger'>*</sup>
                        </Button></>
                        )}
                        </div>

                        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>

        </Form>}

     
  
    
      </div>
      <Modal show={showTemplateModal} onHide={handleCloseModal} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
    
          <Modal.Body className='d-flex flex-column justify-content-center text-center gap-3 p-5' >
         
        
         <Row className='justify-content-center p-3' style={{border:'3px solid #808080'}}>
         {loading ? (
                                       <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                                         <div className="spinner-border text-success" role="status">
                                           <span className="visually-hidden">Loading...</span>
                                         </div>
                                       </div>
                                     ) : (<> {globalTemplates?.length > 0 ?
        <> {globalTemplates?.map((template,index)=> (
                <Col xs={6} key={index} className='d-flex align-items-center p-2 justify-content-center' onClick={() => handleTemplateClick(template)}
                style={{
                    border: selectedTemplate?.id === template.id ? '3px solid #469B12' : 'none'
                }}>
                    <img src={template?.desktopTemplate} alt={template?.groupName} style={{width:'200px'}}
                   className={`template-item ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
                                
                    />
                </Col>
         
        
        ))}</> :  <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no templates</h4></div>}</>)}
         </Row>
         <div className='d-flex gap-3 text-center justify-content-center'>
            <Button variant='danger' onClick={handleCloseModal}>Cancel</Button>
            <Button variant='primary' disabled={!selectedTemplate}  onClick={handleSelectTemplate} style={{backgroundColor: selectedTemplate ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6' }}>Select Template</Button>
            </div>
          
           
          </Modal.Body>
          
            </Modal>
            <SuccessModal modalText='New Box Added'/>
    </div>
  )
}

export default AddBoxes
