// src/mixpanel.js
import mixpanel from 'mixpanel-browser';
const projectToken = "26a00fc43216a314132c54b38fff396f";

mixpanel.init(projectToken, {debug: true});

export const trackEvent = (eventName, properties = {}) => {
  mixpanel.track(eventName, properties);
};

