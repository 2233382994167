
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '../../assets/arrow-left.svg'
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/deactivate.svg'
import calendarGreen from '../../assets/calendar-green.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import { debounce } from 'lodash';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from "react-icons/fa";
import { IoCardOutline } from "react-icons/io5";
import { IoCalendarClearOutline } from "react-icons/io5"

import { HiOutlineSpeakerphone } from 'react-icons/hi';
import adminRoute from '../../services/adminRoute';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';




const AdminPromotions = () => {
  const { showToast,showDeleteModal, setShowDeleteModal  } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchPromotion, setSearchPromotion] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [promoDetails, setPromoDetails] = useState([]);
  const [promoDisplayDetails, setPromoDisplayDetails] = useState([]);
const {getPromotions,filterByStatus, filterByDiscountType,filterPromotionsByDate,filterByPromotionType, getAllPromotions}= adminRoute();
  const [loading, setLoading] = useState(false);


  const [selectedPromotionsId, setSelectedPromotionsId] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [globalPromotions, setGlobalPromotions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isPromoOpen, setIsPromoOpen] = useState(false);
  const [isDiscountTypeOpen, setIsDiscountTypeOpen] = useState(false);
  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalPromotions: 0,
  });
  const [isDateOpen, setIsDateOpen] = useState(false);
  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const [currentPromo, setCurrentPromo] = useState(null);
  

   

useEffect(() => {
  // Define a debounced version of the getAdminCustomers function
  const debouncedGetPromotions = debounce((searchPromotion) => {
    getPromotions(
          setLoading,
          showToast,
          setPromoDetails,
          setPromoDisplayDetails,
          pagination,
          setPagination,
          searchPromotion,
      );
  }, 500);


  if (searchPromotion !== '') {
    setSearchPerformed(true)
      debouncedGetPromotions(searchPromotion);
  } else {
   
      getPromotions(
          setLoading,
          showToast,
          setPromoDetails,
        setPromoDisplayDetails,
          pagination,
          setPagination,
          
      );
  }


  return () => {
      debouncedGetPromotions.cancel();
  };
}, [pagination.currentPage, searchPromotion]);


 

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen2(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          // if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
          //   setIsStatusOpen(false);
          // }
          // if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
          //   setIsDateOpen(false);
          // }
          // if (dropdownRef6.current && !dropdownRef6.current.contains(event.target)) {
          //   setIsDiscountTypeOpen(false);
          // }
        }
    
        function handleScroll() {
          setIsExportOpen(false);
          // setIsOpen(false);
          // setIsOpen2(false);
          // setIsStatusOpen(false);
          // setIsPromoOpen(false);
         
          // setIsDateOpen(false)
          // setIsDiscountTypeOpen(false);
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

    
      
      useEffect(() => {

        getAllPromotions(
          setLoading, showToast, setGlobalPromotions
         
        )
          
        }, []);
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);

const toggleStatusDropdown = () => setIsStatusOpen(!isStatusOpen);
const toggleDiscountDropdown = () => setIsDiscountTypeOpen(!isDiscountTypeOpen);

const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};

const handleToggleSelectAll = () => {
  if (selectedPromotionsId.length === promoDisplayDetails.length) {
      setSelectedPromotionsId([]); // Deselect all
  } else {
      setSelectedPromotionsId(promoDisplayDetails.map(promo => promo.id)); // Select all
  }
};

const handleCheckboxChange = (promoId, isChecked, event) => {

  
  if (isChecked) {
      setSelectedPromotionsId(prevIds => [...prevIds, promoId]);
  } else {
      setSelectedPromotionsId(prevIds => prevIds.filter(id => id !== promoId));
  }
};




const capitalizeFirstLetter = (string) => {
  return string?.replace(/\b(\w)/g, s => s?.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };

    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

const determineStatus = (promo) => {
  let statusText = 'Status Unknown';
  let statusStyle = { backgroundColor: 'grey', color: 'white' };

  if (promo?.status) {
 
    statusText = capitalizeFirstLetter(promo.status);
  } 
  else{
    statusText = 'Status Unknown';
  }
 

  statusStyle = getStatusStyle(statusText.toLowerCase());
  return <span style={statusStyle}>{statusText}</span>;
};






const handleCSVExport = () => {
  const csvData = prepareCSVData();
  if (csvData) {
    return (
      <CSVLink data={csvData.data} headers={csvData.headers} filename="promo-list.csv" className='csv-link'>
        As CSV
      </CSVLink>
    );
  } else {
    console.error("CSV data is not available.");
  }
};

const prepareCSVData = () => {
  if (globalPromotions?.length === 0) {
    console.warn("No products available for CSV export.");
    return null;
  }
  
  const headers = [
    { label: 'Promotion Code', key: 'promotionCode' },
    { label: 'Promotion Name', key: 'promoName' },
    { label: 'Date Created', key: 'dateCreated' },
    { label: 'Valid Thru', key: 'validThru' },
    { label: 'Promotion Type', key: 'promotionType' },
    { label: 'Status', key: 'status' },
    
  ];
  
  const data = globalPromotions?.map(promo => ({
    promotionCode: promo?.couponCode,
    promoName: promo?.description,
    dateCreated: promo?.createdAt ? new Date(promo?.createdAt).toLocaleDateString('en-CA') : 'N/A',
    validThru: promo?.endDate ? new Date(promo?.endDate).toLocaleDateString('en-CA') : 'N/A',
    promotionType: promo?.promotionType,
    status: promo?.status, 
  }));
  
  return { headers, data };
};


const handleFilter = () => {
  const startOfStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));
  const isoStartDate = startOfStartDate.toISOString();

  // Set the end date to the beginning of the day in UTC
  const startOfEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));
  const isoEndDate = startOfEndDate.toISOString();
  setIsDateOpen(false);
  filterPromotionsByDate(isoEndDate, isoStartDate, setLoading, setPromoDisplayDetails, showToast,setPagination);
 // Close the date picker dropdown
};
const handlePDFExport = () => {
const input = document.getElementById('promo-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('promoton-list.pdf');
});
};

const handleStatusFilter = (status) => {
  let statusFilter;
  if(status === 'Inactive'){
    statusFilter='inactive'
  }else if(status === 'Active'){
    statusFilter='active'
  
  }
  filterByStatus(statusFilter, setLoading, setPromoDisplayDetails, showToast,setPagination);
  
};

const handleDiscountTypeFilter = (status) => {
  let statusFilter;
  if(status === 'Percentage'){
    statusFilter='percentage'
  }else if(status === 'Amount'){
    statusFilter='amount'
  
  }
  filterByDiscountType(statusFilter, setLoading, setPromoDisplayDetails, showToast,setPagination);
  
};

const handlePromotionTypeFilter = (promo) => {
  let statusFilter;
  if(promo === '% Off products'){
    statusFilter='percentage off product'
  }else {
    statusFilter=promo.toLowerCase();
  
  }
  filterByPromotionType(statusFilter, setLoading, setPromoDisplayDetails, showToast,setPagination);
  
};

const getGlobalPromotions = ()=> {
  getPromotions(
    setLoading,
    showToast,
    setPromoDetails,
  setPromoDisplayDetails,
    pagination,
    setPagination,
    
)
}

return (
  <section className=''>
  {/* <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
      <h3 style={{color:'#344054'}}>Promotions</h3>
   <div className='d-flex gap-3'>
  
  
    
     

    <Button className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/promotions/create')} >
    <HiOutlineSpeakerphone/>
   Create New Promotion
    </Button>

    
   </div>
  </div> */}
  <div className=' pe-5 ps-4'>

 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):promoDisplayDetails?.length > 0 ? <>

  <div className='d-flex align-items-center justify-content-between py-4'>
    <div className='d-flex align-items-center gap-3'>
     
     
{selectedPromotionsId.length > 0 ? (
  <Form.Check
  checked={selectedPromotionsId.length === promoDisplayDetails.length}
  label={`${selectedPromotionsId.length} selected`}
  className='selected-check'
  onClick={handleToggleSelectAll}
/>
): <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>{ pagination?.totalPromotions !== 0 ? pagination?.totalPromotions : promoDisplayDetails?.length} {pagination?.totalPromotions.length > 1 || promoDisplayDetails.length > 1 ? 'Promotions' :'Promotion'}</p>}


      <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchPromotion === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchPromotion !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchPromotion('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder="Search by promo's name"
      className='w-100'
      value={searchPromotion}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchPromotion(e.target.value)}
      />
    </div>
    </div>
     
      <div className='d-flex gap-2'>
      <Dropdown show={isOpen2}  ref={dropdownRef2}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item onClick={()=> {getGlobalPromotions();toggleDropdown2()}} className='d-flex justify-content-between align-items-center'>All</Dropdown.Item>
    <DropdownDivider/>
     <Dropdown.Item onClick={() => setIsPromoOpen(!isPromoOpen)}className='d-flex justify-content-between align-items-center'> Promo Type &nbsp;{isPromoOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
          
  {isPromoOpen && [ 'Free Delivery', 'Holidays', '% Off products', 'Product Discount', 'Welcome Gifts'].map((promo, index) => (
  <Dropdown.Item
    key={index} onClick={()=> {toggleDropdown2();setIsPromoOpen(!isPromoOpen);handlePromotionTypeFilter(promo)}}
  > {promo}
  </Dropdown.Item>
))}
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleDiscountDropdown} className='d-flex justify-content-between align-items-center'> Discount Type &nbsp; {isDiscountTypeOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
     {isDiscountTypeOpen && [ 'Percentage', 'Amount'].map((status, index) => (
<Dropdown.Item
  key={index} onClick={()=> {toggleDropdown2();toggleDiscountDropdown();handleDiscountTypeFilter(status)}}
> {status}
</Dropdown.Item>
))}
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleStatusDropdown} className='d-flex justify-content-between align-items-center'> Status {isStatusOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
     {isStatusOpen && [ 'Active', 'Inactive'].map((status, index) => (
  <Dropdown.Item
    key={index} onClick={()=> {toggleDropdown2();toggleStatusDropdown(); handleStatusFilter(status)}}
  > {status}
  </Dropdown.Item>
))}
         
     <DropdownDivider/>
     <Dropdown.Item onClick={() => setIsDateOpen(!isDateOpen)}className='d-flex justify-content-between align-items-center'> Date {isDateOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
     {isDateOpen && (
   <div className='d-flex flex-column gap-3 p-3 ' style={{width:'180px',overflowX:'hidden'}}>
     <Form.Group>
     <Form.Label>Start Date</Form.Label>
     <DatePicker
       selected={startDate}
       onChange={(date) => setStartDate(date)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
     <Form.Group>
     <Form.Label>End Date</Form.Label>
     <DatePicker
       selected={endDate}
       onChange={(date) => setEndDate(date)}
       selectsEnd
       startDate={startDate}
       endDate={endDate}
       minDate={startDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
    
     <Button className='text-center w-30' onClick={()=>{handleFilter();toggleDropdown2();}}>Filter</Button>
   </div>
 )}
    </Dropdown.Menu>
   </Dropdown>
      {/* <Button variant='secondary'  onClick={()=> getGlobalPromotions()} className='d-flex align-items-center px-2'>
        All 
        </Button>
         
          <Dropdown show={isPromoOpen}  ref={dropdownRef2}>
  
   <Button variant='secondary' id="dropdown-basic" onClick={() => setIsPromoOpen(!isPromoOpen)} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#626262'}}>
  Promo Type {isPromoOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
  {isPromoOpen && [ 'Free Delivery', 'Holidays', '% Off products', 'Product Discount', 'Welcome Gifts'].map((promo, index) => (
  <Dropdown.Item
    key={index} onClick={()=> {setIsPromoOpen(!isPromoOpen);handlePromotionTypeFilter(promo)}}
  > {promo}
  </Dropdown.Item>
))}
   
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isDiscountTypeOpen}  ref={dropdownRef6}>
 
 <Button variant='secondary' id="dropdown-basic" onClick={toggleDiscountDropdown} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#626262'}}>
  Discount Type {isDiscountTypeOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
 </Button>

<Dropdown.Menu className='me-5' >

  {isDiscountTypeOpen && [ 'Percentage', 'Amount'].map((status, index) => (
<Dropdown.Item
  key={index} onClick={()=> {toggleDiscountDropdown();handleDiscountTypeFilter(status)}}
> {status}
</Dropdown.Item>
))}
</Dropdown.Menu>
</Dropdown>

 <Dropdown show={isStatusOpen}  ref={dropdownRef4}>
 
   <Button variant='secondary' id="dropdown-basic" onClick={toggleStatusDropdown} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#626262'}}>
    Status {isStatusOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
    {isStatusOpen && [ 'Active', 'Inactive'].map((status, index) => (
  <Dropdown.Item
    key={index} onClick={()=> {toggleStatusDropdown(); handleStatusFilter(status)}}
  > {status}
  </Dropdown.Item>
))}
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isDateOpen}  ref={dropdownRef5} >
 <Button variant='secondary' id="dropdown-basic" onClick={() => setIsDateOpen(!isDateOpen)} className='d-flex align-items-center gap-1'  style={{fontWeight:'500', color:'#626262'}}>
   Date {isDateOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  

  <Dropdown.Menu className='me-5' >
 
 {isDateOpen && (
   <div className='d-flex flex-column gap-3 p-3 ' style={{width:'180px',overflowX:'hidden'}}>
     <Form.Group>
     <Form.Label>Start Date</Form.Label>
     <DatePicker
       selected={startDate}
       onChange={(date) => setStartDate(date)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
     <Form.Group>
     <Form.Label>End Date</Form.Label>
     <DatePicker
       selected={endDate}
       onChange={(date) => setEndDate(date)}
       selectsEnd
       startDate={startDate}
       endDate={endDate}
       minDate={startDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
    
     <Button className='text-center w-30' onClick={handleFilter}>Filter</Button>
   </div>
 )}

</Dropdown.Menu>
</Dropdown> */}
<Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 px-3 py-2'>
      <img src={exportIcon} />Export
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item>
      <DownloadTableExcel
                    filename="promo table"
                    sheet="promos"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>
      </div>
  </div>

  <Table hover className='my-3' ref={tableRef} id="promo-table">
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
          <th style={{fontWeight:'600'}} className='pb-3'>Promotion Code</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Promotion Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Date Created</th>
              <th style={{fontWeight:'600'}} className='pb-3'> Valid Thru</th>
              <th style={{fontWeight:'600'}} className='pb-3'> Promotion Type</th>
              
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {promoDisplayDetails?.map((promo) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={promo?.id} >
              <td style={{paddingTop: '15px', paddingBottom: '15px' }}> 
        
  <div>
      <Form.Check
          checked={selectedPromotionsId.includes(promo.id)}
          onChange={(e) => {
              handleCheckboxChange(promo.id, e.target.checked);
          }}
      />
  </div>


</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#115F04', cursor:'pointer', textDecoration:'underline' }} onClick={() => navigate(`/admin/promotions/${promo?.id}`)}>{promo?.couponCode || promo?.code}</td>
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{promo?.description}</td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{promo?.createdAt ? new Date(promo.createdAt).toLocaleDateString('en-CA') : 'N/A'}</td> 
         <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{promo?.endDate ? new Date(promo.endDate).toLocaleDateString('en-CA') : 'N/A'}</td> 
             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{capitalizeFirstLetter(promo?.promotionType)}</td>
           
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(promo)}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
          <img src={edit} onClick={() => navigate(`/admin/promotions/${promo?.id}`)}/> &nbsp;&nbsp; <img src={deleteIcon} onClick={() => { setCurrentPromo(promo);
    setShowDeleteModal(true);
}}/></td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/promotions/${promo?.id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> 

  { promoDisplayDetails.length > 0 && pagination.totalPages > 1 &&  (

<div className="d-flex justify-content-between align-items-center my-5 pt-5" style={{ height: '50px' }}>
<p style={{ fontWeight: '500', color: '#919EAB' }}>
  Showing {promoDisplayDetails.length} of {pagination.totalPromotions} promotions
</p>
{pagination.totalPages > 1 && (
  <Pagination className="custom-pagination d-flex gap-2">
    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === 1}
      onClick={() => handlePageChange(pagination.currentPage - 1)}
      style={{
        border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-left"></i>
      </h6>
    </button>

    {/* First Page */}
    <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
      1
    </Pagination.Item>

    {/* Ellipsis and Page Number Logic */}
    {pagination.totalPages > 1 && (
      <>
        {pagination.currentPage > 3 && <Pagination.Ellipsis disabled />}
        
        {/* Previous Page */}
        {pagination.currentPage > 2 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage - 1)}>
            {pagination.currentPage - 1}
          </Pagination.Item>
        )}

        {/* Current Page */}
        {pagination.currentPage !== 1 && pagination.currentPage !== pagination.totalPages && (
          <Pagination.Item active>{pagination.currentPage}</Pagination.Item>
        )}

        {/* Next Page */}
        {pagination.currentPage < pagination.totalPages - 1 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage + 1)}>
            {pagination.currentPage + 1}
          </Pagination.Item>
        )}

        {pagination.currentPage < pagination.totalPages - 2 && <Pagination.Ellipsis disabled />}

        {/* Last Page */}
        <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
          {pagination.totalPages}
        </Pagination.Item>
      </>
    )}

    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === pagination.totalPages}
      onClick={() => handlePageChange(pagination.currentPage + 1)}
      style={{
        border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-right"></i>
      </h6>
    </button>
  </Pagination>
)}
</div>)}
</>
 :
 <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no promotions</h4></div>
}
</div>
<DeleteProductModal  item={currentPromo}
      itemType="promo" actionType="deactivate"
    />

  </section>

)
}

export default AdminPromotions
