import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate} from 'react-router-dom'
import { Modal, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select from 'react-select';
import SuccessModal from '../../components/Modal/SuccessModal';

const AddBoxTemplates = () => {
    const navigate = useNavigate();
    const { showToast, showSuccessModal, setShowSuccessModal}= useAppContext()
    const { addBoxTemplate } = productRoute();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [boxData, setBoxData] = useState({
        templateGroupName:'',
        mobileTemplate:null,
        desktopTemplate:null,
    })
   

    
  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBoxData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleImageChange = (event, field) => {
        const file = event.target.files[0];
      
        // Check if the file is of type PNG or JPEG and ensure it is not an SVG
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg'|| 
            file.type === 'image/jpg' || 
            file.type === 'image/svg+xml') && file.size <= 10485760) {
          setBoxData(prev => ({ ...prev, [field]: file }));
        } else {
          // Replace with your toast or alert function
          alert('Invalid file. Please upload a PNG or JPEG image file up to 10MB.');
        }
      };
      
 
    // const handleImageChange = (event, field) => {
    //     const file = event.target.files[0];
    //     if (file && file.type.startsWith('image/') && file.type !== 'image/svg+xml' && file.size <= 10485760) {
    //         setBoxData(prev => ({ ...prev, [field]: file }));
    //     } else {
    //         // Replace with your toast or alert function
    //         alert('Invalid file. Please upload a non-SVG image file up to 10MB.');
    //     }
    // };
    

    const handleDeleteImage = (field) => {
        setBoxData(prev => ({ ...prev, [field]: null }));
    };
    

    const isFormValid = () => {
       
        return boxData?.templateGroupName &&
        boxData?.desktopTemplate && boxData?.mobileTemplate
      };
    

    const handleSave = (e) => {
        e.preventDefault();
      
      
    
  
        const formData = new FormData();
        formData.append('groupName', boxData.templateGroupName);
       
        formData.append('alt', boxData.templateGroupName);
        formData.append('desktopTemplate', boxData.desktopTemplate);
        formData.append('mobileTemplate', boxData.mobileTemplate);
      
addBoxTemplate(formData,
setLoading,
showToast,
setShowSuccessModal)
      
    };

    const handleBack = ()=> {
        navigate(-1)
    }


    const renderImageSection = (field, label) => {
        const isMobile = label.toLowerCase().includes('mobile');
        const widthHeight = isMobile ? { width: '200px', height: '200px' } : { width: '350px', height: '300px' };
        return(
        <Col xs={6} className='pe-3 mb-4'>
            <p style={{color:'#333333', fontWeight:'500'}} className=''>{label} <sup className='text-danger'></sup></p>
            <div className='my-3'>
                {boxData[field] ? (
                    <>
                        <Card style={{border:"1px solid #E7E7E7", width:'400px', height:'350px'}} className='mb-3 p-3 align-items-center justify-content-center'>
                            <img
                                src={URL.createObjectURL(boxData[field])}
                                onLoad={() => URL.revokeObjectURL(boxData[field])}
                                alt="Product" style={{...widthHeight}}
                                
                            />
                        </Card>
                        <div className="d-flex align-items-center gap-3">
                            <Button  variant="outline-secondary" className='mt-1'  style={{ borderStyle:'dashed', borderRadius:'4px', color:'#353542' , fontWeight:"500"}} >Selected Template</Button>
                            <img src={deleteIcon} onClick={() => handleDeleteImage(field)} style={{ width: '24px', height: '24px', cursor: 'pointer',}} />
                        </div>
                    </>
                ) : (
                    <>
                        <Card style={{border:"1px solid #E7E7E7", width:'400px', height:'250px'}} className='mb-3'></Card>
                        <Form.Control
                            type="file"
                            onChange={(e) => handleImageChange(e, field)}
                            accept="image/*"
                            style={{ display: 'none' }}
                            id={`${field}Input`}
                        />
                        <Button variant="outline-secondary" onClick={() => document.getElementById(`${field}Input`).click()} style={{ borderStyle:'dashed', borderRadius:'4px', color:'#353542' , fontWeight:"500"}} className='px-4'>
                            Select template<sup className='text-danger'>*</sup>
                        </Button>
                    </>
                )}
            </div>
        </Col>
    )
}
 
  return (
    <div className=''>
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'>Add Template</h3>
        </div>

        <div className=' pe-5 ps-4 '>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={12} md={7} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Template Group Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='templateGroupName' value={boxData.templateGroupName} placeholder = 'Enter template name or leave blank for system to auto-generate' onChange={handleChange} required/>
                </Col>
              
            
              
                {renderImageSection('desktopTemplate', 'Desktop Template')}
                {renderImageSection('mobileTemplate', 'Mobile Template')}


            </Row> 

          

                        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>

        </Form>}

     
  
    
      </div>
      <SuccessModal modalText='New Templated Added'/>
    </div>
  )
}

export default AddBoxTemplates
