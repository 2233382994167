
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '../../assets/arrow-left.svg'
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/delete.svg'
import calendarGreen from '../../assets/calendar-green.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import { debounce } from 'lodash';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from "react-icons/fa";
import { IoCardOutline } from "react-icons/io5";
import { IoCalendarClearOutline } from "react-icons/io5"

import { HiOutlineSpeakerphone } from 'react-icons/hi';
import adminRoute from '../../services/adminRoute';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';
import productRoute from '../../services/productRoute';




const VoucherBank = () => {
  const { showToast,showDeleteModal, setShowDeleteModal  } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchTemplate, setSearchTemplate] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [voucherTemplates, setVoucherTemplates] = useState([]);
  const [displayVoucherTemplates, setDisplayVoucherTemplates] = useState([]);
const {getAllVoucherTemplates,getGlobalVoucherTemplates}= productRoute();
  const [loading, setLoading] = useState(false);


  const [searchPerformed, setSearchPerformed] = useState(false);
  const [globalVoucherTemplates, setGlobalVoucherTemplates] = useState([]);

  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalTemplates: 0,
  });

  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  

  useEffect(() => {

    getGlobalVoucherTemplates(
      setLoading, showToast, setGlobalVoucherTemplates
     
    )
      
    }, []);

    useEffect(() => {

        getAllVoucherTemplates(
            setLoading, showToast, setVoucherTemplates, setDisplayVoucherTemplates, pagination, setPagination
         
        )
          
        }, [pagination?.currentPage]);




      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen2(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          // if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
          //   setIsStatusOpen(false);
          // }
          // if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
          //   setIsDateOpen(false);
          // }
          // if (dropdownRef6.current && !dropdownRef6.current.contains(event.target)) {
          //   setIsDiscountTypeOpen(false);
          // }
        }
    
        function handleScroll() {
          setIsExportOpen(false);
          // setIsOpen(false);
          // setIsOpen2(false);
          // setIsStatusOpen(false);
          // setIsvoucherTemplateOpen(false);
         
          // setIsDateOpen(false)
          // setIsDiscountTypeOpen(false);
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

    
      
    
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);


const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};







const capitalizeFirstLetter = (string) => {
  return string?.replace(/\b(\w)/g, s => s?.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };

    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

const determineStatus = (voucherTemplate) => {
  let statusText = 'Status Unknown';
  let statusStyle = { backgroundColor: 'grey', color: 'white' };

  if (voucherTemplate?.status) {
 
    statusText = capitalizeFirstLetter(voucherTemplate.status);
  } else{
    statusText = 'Unknown'
  }
 

  statusStyle = getStatusStyle(statusText?.toLowerCase());
  return <span style={statusStyle}>{statusText}</span>;
};






const handleCSVExport = () => {
  const csvData = prepareCSVData();
  if (csvData) {
    return (
      <CSVLink data={csvData.data} headers={csvData.headers} filename="voucherTemplate-list.csv" className='csv-link'>
        As CSV
      </CSVLink>
    );
  } else {
    console.error("CSV data is not available.");
  }
};

const prepareCSVData = () => {
  if (globalVoucherTemplates?.length === 0) {
    console.warn("No templates available for CSV export.");
    return null;
  }
  
  const headers = [
  
    { label: 'Template Name', key: 'templateName' },
    { label: 'Date Uploaded', key: 'dateUploaded' },
   
    { label: 'Status', key: 'status' },
    
  ];
  
  const data = globalVoucherTemplates?.map(voucherTemplate => ({
    
    voucherTemplateName: voucherTemplate?.groupName,
    dateUploaded: voucherTemplate?.createdAt ? new Date(voucherTemplate?.createdAt).toLocaleDateString('en-CA') : 'N/A',
  
    status: voucherTemplate?.status || '-', 
  }));
  
  return { headers, data };
};



const handlePDFExport = () => {
const input = document.getElementById('voucherTemplate-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('voucherTemplate.pdf');
});
};

// const handleStatusFilter = (status) => {
//   let statusFilter;
//   if(status === 'Inactive'){
//     statusFilter='inactive'
//   }else if(status === 'Active'){
//     statusFilter='active'
  
//   }
//   filterByStatus(statusFilter, setLoading, setDisplayVoucherTemplates, showToast,setPagination);
  
// };



const getglobalTemplates = ()=> {
  getAllVoucherTemplates(
    setLoading,
    showToast,
    setVoucherTemplates,
  setDisplayVoucherTemplates,
    pagination,
    setPagination,
    
)
}

return (
  <section className=''>

<div className='d-flex justify-content-between align-items-center header-admin py-5 pe-5 ps-4'>
    <div className='d-flex align-items-center gap-3'>
    <img src={arrowLeft} onClick={()=> navigate(-1)} alt='arrow'/>
      <h3 style={{color:'#344054'}} className='mt-2'>Voucher Bank</h3>
      </div>
   <div className='d-flex gap-3'>
   

    <Button className='d-flex align-items-center gap-3 p-2' onClick={()=> navigate('/admin/vouchers/templates/add')}>
    <FontAwesomeIcon icon={faPlus} />
    Upload New Template 
    </Button>

    
   </div>
  </div>

  <div className=' pe-5 ps-4'>

 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):displayVoucherTemplates?.length > 0 ? <>

  <div className='d-flex align-items-center justify-content-between py-4'>
    <div className='d-flex align-items-center gap-4'>
     
     
 <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>Total Number:{pagination?.totalTemplates !== 0 ? pagination?.totalTemplates : displayVoucherTemplates?.length} </p> 


      {/* <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchTemplate === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchTemplate !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchTemplate('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder="Search by Template's name"
      className='w-100'
      value={searchTemplate}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchTemplate(e.target.value)}
      />
    </div> */}
    </div>
     
      <div className='d-flex gap-2'>
      {/* <Dropdown show={isOpen2}  ref={dropdownRef2}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item onClick={()=> {getglobalTemplates();toggleDropdown2()}} className='d-flex justify-content-between align-items-center'>All</Dropdown.Item>
    <DropdownDivider/>
     <Dropdown.Item className='d-flex justify-content-between align-items-center'> Active</Dropdown.Item>
          
 
     <DropdownDivider/>
     <Dropdown.Item  className='d-flex justify-content-between align-items-center'> Inactive </Dropdown.Item>
   
    </Dropdown.Menu>
   </Dropdown> */}
  
<Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 px-3 py-2'>
      <img src={exportIcon} />Export
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item>
      <DownloadTableExcel
                    filename="voucherTemplate"
                    sheet="voucherTemplates"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>
      </div>
  </div>

  <Table hover className='my-3' ref={tableRef} id="voucherTemplate-table">
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
         
      
          <th style={{fontWeight:'600'}} className='pb-3'> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Template Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'>Date Uploaded</th>
            
              
             
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {displayVoucherTemplates?.map((voucherTemplate) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={voucherTemplate?.id} >
             

             
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}> <img src={voucherTemplate?.desktopVoucherCover} style={{width:'40px', height:'40px',objectFit:'cover'}}/>&nbsp; &nbsp; &nbsp;{voucherTemplate?.groupName}</td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{voucherTemplate?.createdAt ? new Date(voucherTemplate.createdAt).toLocaleDateString('en-CA') : 'N/A'}</td> 
        
           
           

             <td style={{paddingTop: '15px', paddingBottom: '15px', cursor:'pointer' }}>
          <img src={edit} onClick={() => navigate(`/admin/vouchers/templates/${voucherTemplate?.id}`, {
                      state: { isEditing: true },
                    })
                }/> &nbsp; &nbsp; <img src={deleteIcon} onClick={() => { setCurrentTemplate(voucherTemplate);
    setShowDeleteModal(true);
}}/></td> <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/vouchers/templates/${voucherTemplate?.id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> 

  { displayVoucherTemplates.length > 0 && pagination.totalPages > 1 &&  (

<div className="d-flex justify-content-between align-items-center my-5 pt-5" style={{ height: '50px' }}>
<p style={{ fontWeight: '500', color: '#919EAB' }}>
  Showing {displayVoucherTemplates.length} of {pagination.totalTemplates} Templates
</p>
{pagination.totalPages > 1 && (
  <Pagination className="custom-pagination d-flex gap-2">
    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === 1}
      onClick={() => handlePageChange(pagination.currentPage - 1)}
      style={{
        border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-left"></i>
      </h6>
    </button>

    {/* First Page */}
    <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
      1
    </Pagination.Item>

    {/* Ellipsis and Page Number Logic */}
    {pagination.totalPages > 1 && (
      <>
        {pagination.currentPage > 3 && <Pagination.Ellipsis disabled />}
        
        {/* Previous Page */}
        {pagination.currentPage > 2 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage - 1)}>
            {pagination.currentPage - 1}
          </Pagination.Item>
        )}

        {/* Current Page */}
        {pagination.currentPage !== 1 && pagination.currentPage !== pagination.totalPages && (
          <Pagination.Item active>{pagination.currentPage}</Pagination.Item>
        )}

        {/* Next Page */}
        {pagination.currentPage < pagination.totalPages - 1 && (
          <Pagination.Item onClick={() => handlePageChange(pagination.currentPage + 1)}>
            {pagination.currentPage + 1}
          </Pagination.Item>
        )}

        {pagination.currentPage < pagination.totalPages - 2 && <Pagination.Ellipsis disabled />}

        {/* Last Page */}
        <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
          {pagination.totalPages}
        </Pagination.Item>
      </>
    )}

    <button
      className="btn btn-outline-secondary pb-0 px-2"
      disabled={pagination.currentPage === pagination.totalPages}
      onClick={() => handlePageChange(pagination.currentPage + 1)}
      style={{
        border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
        color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
      }}
    >
      <h6>
        <i className="bi bi-chevron-right"></i>
      </h6>
    </button>
  </Pagination>
)}
</div>
)}
</>
 :
 <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no templates</h4></div>
}
</div>
<DeleteProductModal  item={currentTemplate}
      itemType="voucherTemplate" actionType="deactivate"
    />

  </section>

)
}

export default VoucherBank
