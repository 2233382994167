import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { Modal, Nav, Form, Button, Image, Row, Col, Pagination, Table } from 'react-bootstrap';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import deleteIcon from '../../assets/delete.svg'
import edit from '../../assets/edit.svg'
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import Select from 'react-select';


const EditCategory = () => {
 

    const { showToast } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation(); 
    const [category, setCategory] = useState(location?.state?.category);
    const [initialCategory, setInitialCategory] = useState(location?.state?.category);
    const { editCategory } = productRoute();
    const [loading, setLoading] = useState(false);
  
 



   const isFormValid = () => {
       
        return category?.categoryName && 
        category?.categoryImage &&  (category.categoryName !== initialCategory.categoryName || category.categoryImage !== initialCategory.categoryImage )
      };

      const handleBack = () => {
        // const previousPath = location.state?.previousPath;
        // const currentPage = location.state?.currentPage;
        
         // Add this line for debugging

        if (location?.state && location?.state.previousPath === '/admin/products') {
          const previousPath = location?.state.previousPath;
          const currentPage = location?.state.currentPage;
            navigate('/admin/products', { state: { currentPage } });
        } else {
            navigate(-1);
        }
    };

    
  
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategory((prev) => ({ ...prev, [name]: value }));
      };
      
  
  
   
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/') && file.size <= 10485760 && file.type !== 'image/svg+xml') {
        const imageUrl = URL.createObjectURL(file);
        setCategory(prev => ({ ...prev, categoryImage: file }));
        // setEditorOpen(true)

    } else {
        showToast({toastText: 'Invalid file. Please upload a non-SVG image file up to 10MB.', isSuccess: false});
    }
};
  

  const handleDeleteImage = () => {
      setCategory(prev => ({ ...prev, categoryImage: null }));
  };

  const handleSave = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (category?.categoryImage !== initialCategory?.categoryImage){
      formData.append('categoryImage', category?.categoryImage)
    }

    if (category?.categoryName !== initialCategory?.categoryName){
      formData.append('categoryName', category?.categoryName)
    }



    editCategory(category?._id, formData, setLoading, showToast);
};

  

    

  return (
    <div className='pe-3'>
        
        <div className='d-flex gap-3 align-items-center header-admin  py-5 pe-5 ps-4'>
            <img src={arrowLeft} onClick={()=> navigate(-1)} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-3'> Edit Category Details</h3>
      
        </div>
        <div className=' pe-5 ps-4'>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Category Name:</p>
                <Form.Control type='text' name='categoryName' value={category.categoryName} onChange={handleChange} required/>
                </Col>
              
              

            </Row> 

            <div className='my-4'>{category.categoryImage ? (
            <>
            <Button className='mb-4'>Attached Image</Button>
                            <div className="d-flex gap-2 align-items-center">
                            <img
    src={typeof category.categoryImage === 'string' ? category.categoryImage : URL.createObjectURL(category.categoryImage)}
    alt="Product"
    style={{ width: '130px', height: '130px' }}
/>

<img src={deleteIcon} onClick={handleDeleteImage} style={{ width: '24px', height: '24px', cursor: 'pointer',marginLeft:'15px'}} /></div>

                                
                            
                          
                            </>
                        ) : (<>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="fileInput"
                            />
                            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()} style={{ backgroundColor: '#CCCDCF', border:'none', borderRadius:'4px',  }} className='px-4'>
                            Upload Image
                        </Button></>
                        )}
                        </div>

                    
        <div className='pt-3 my-3 d-flex justify-content-center'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      

        </Form>
        
        }
 

    </div>
    </div>
  )
}

export default EditCategory