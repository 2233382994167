import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import './styles.css';



import {ToastContainer} from 'react-toastify'
import GuestNavbar, { getParsedLocalStorageItem } from './components/Navbar/Navbar';
import { useAppContext } from './AppContext';
import LogoutModal from './components/Modal/Logout';
import AdminLayout from './pages/Admin/AdminLayout'
import AdminCustomers from './pages/Admin/AdminCustomers'
import AdminOrders from './pages/Admin/AdminOrders'
import AdminProducts from './pages/Admin/AdminProducts'
import AdminPromotions from './pages/Admin/AdminPromotions'
import AdminReports from './pages/Admin/AdminReports'
import AdminProfile from './pages/Admin/AdminProfile'
import AdminBroadcast from './pages/Admin/AdminBroadcast'
import AdminListing from './pages/Admin/AdminListing'
import AdminHelp from './pages/Admin/AdminHelp'
import AdminNotifications from './pages/Admin/AdminNotifications'
import AddProduct from './pages/Admin/AddProduct'
import ProductDetails from './pages/Admin/ProductDetails'
import BulkPriceUpdate from './pages/Admin/BulkPriceUpdate';
import BulkStockUpdate from './pages/Admin/BulkStockUpdate';
import CreateOrder from './pages/Admin/CreateOrder';
import AdminOrderDetails from './pages/Admin/AdminOrderDetails';
import CustomerDetails from './pages/Admin/CustomerDetails';
import AdminLogin from './pages/Admin/AdminLogin';
import DeleteProductModal from './components/Modal/DeleteProductModal';
import CreatePromo from './pages/Admin/CreatePromo';
import AdminForgotPassword from './pages/Admin/AdminForgotPassword';
import AccountSettings from './pages/Admin/AccountSettings';
import AcceptAdminInvite from './pages/Admin/AcceptAdminInvite';
import PromotionDetails from './pages/Admin/PromotionDetails';
import ProductCategories from './pages/Admin/ProductCategories';
import AddCategory from './pages/Admin/AddCategory';
import ViewProductCategories from './pages/Admin/ViewProductCategories';
import EditCategory from './pages/Admin/EditCategory';
import UserDelights from './pages/Admin/UserDelights';
import AddVoucher from './pages/Admin/AddVoucher';
import AddBoxes from './pages/Admin/AddBoxes';
import BoxTemplates from './pages/Admin/BoxTemplates';
import VoucherBank from './pages/Admin/VoucherBank';
import AddBoxTemplates from './pages/Admin/AddBoxTemplates';
import AddVoucherTemplate from './pages/Admin/AddVoucherTemplate';

import OrderModification from './pages/Admin/OrderModification';
import SubscriptionBoxDetails from './pages/Admin/SubscriptionBoxDetails';
import VoucherDetails from './pages/Admin/VoucherDetails';
import EditBoxTemplate from './pages/Admin/EditBoxTemplates';
import EditVoucherTemplate from './pages/Admin/EditVoucherTemplate';

const ENV_PREFIX = process.env.REACT_APP_ENV_PREFIX || 'production';

export const localStorageSetItem = (key, value) => {
  localStorage.setItem(`${ENV_PREFIX}_${key}`, value);
};

export const localStorageGetItem = (key) => {
  return localStorage.getItem(`${ENV_PREFIX}_${key}`);
};

export const localStorageRemoveItem = (key) => {
  localStorage.removeItem(`${ENV_PREFIX}_${key}`);
};


export const transformPath = (path) => {
  // Check for static paths first
  switch (path) {
    case '/':
      return 'Home Page';
    case '/terms':
      return 'Terms of Use';
      case '/privacy-policy':
        return 'Privacy policy';
        case '/shipping-policy':
        return 'Shipping policy';
        case '/refund-policy':
        return 'Refund policy';
    default:
      
      if (path.startsWith('/category/')) {
        return 'Category Listing Page';
      } else {
        return path; 
      }
  }
};



const AdminProtectedRoute = () => {
  const navigate = useNavigate();
  const token = localStorageGetItem('userToken');
  const userType = localStorageGetItem('userType');

  const isLoggedIn = !!token;
  const isAdmin = userType === 'admin';

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate("/");
    }
  }, [isLoggedIn, isAdmin]);


  return isLoggedIn && isAdmin ? <Outlet /> : null;
};





const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



const PreventLoginAccessRoute = () => {
  const navigate = useNavigate();
  const token = localStorageGetItem('userToken');
  const isLoggedIn = !!token;

 

  useEffect(() => {

    if (isLoggedIn) {
     
      navigate("/admin/orders");
    }
  }, [isLoggedIn]);

  return !isLoggedIn ? <Outlet /> : null;
};






function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

 

  useEffect(() => {
 
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
 
  return (
   <BrowserRouter>
  <ToastContainer className='mx-2 mx-md-0 mt-3 mt-md-0' position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover style={{zIndex:'11000'}}/>
  
   <LogoutModal/>

  
   <ScrollToTop/>
   <Routes>
  

   <Route element={<PreventLoginAccessRoute />}>
          <Route path="/" element={<AdminLogin />} />
          <Route path="/admin/forgot-password" element={<AdminForgotPassword />}/>
          <Route path="/admin/accept-invite" element={<AcceptAdminInvite />}/>
        </Route>
{/* Admin */}
<Route element={<AdminProtectedRoute />}>
   <Route element={<AdminLayout />}>
    <Route path="/admin/orders" element={<AdminOrders />} />
    <Route path="/admin/orders/create" element={<CreateOrder />} />
   
    <Route path="/admin/orders/:orderId" element={<AdminOrderDetails/>} />
    <Route path="/admin/orders/modification/:orderId" element={<OrderModification/>} />
    <Route path="/admin/products" element={<AdminProducts />} />
    <Route path="/admin/categories" element={<ProductCategories />} />
    <Route path="/admin/categories/add" element={<AddCategory />} />
    <Route path="/admin/categories/edit" element={<EditCategory />} />
    <Route path="/admin/categories/:categoryName" element={<ViewProductCategories/>} />
    <Route path="/admin/products/add" element={<AddProduct />} />
    <Route path="/admin/products/:productId" element={<ProductDetails />} />
    <Route path="/admin/products/bulk/price" element={<BulkPriceUpdate />} />
<Route path="/admin/products/bulk/stock" element={<BulkStockUpdate />} />

    <Route path="/admin/customers" element={<AdminCustomers />} />
    <Route path="/admin/customers/:customerId" element={<CustomerDetails />} />
    <Route path="/admin/customers/settings/:customerId" element={<AccountSettings />} />
    <Route path="/admin/user-delights" element={<UserDelights />} />
    <Route path="/admin/promotions/create" element={<CreatePromo />} />
    <Route path="/admin/promotions" element={<AdminPromotions />} />
    <Route path="/admin/promotions/:promoId" element={<PromotionDetails />} />
 
    <Route path="/admin/vouchers/add" element={<AddVoucher />} />
 
    <Route path="/admin/subscriptions/add" element={<AddBoxes />} />
    <Route path="/admin/subscriptions/:subscriptionId" element={<SubscriptionBoxDetails />} />
    <Route path="/admin/vouchers/:voucherId" element={<VoucherDetails />} />
    <Route path="/admin/subscriptions/templates" element={<BoxTemplates />} />
    <Route path="/admin/subscriptions/templates/:templateId" element={<EditBoxTemplate />} />
    <Route path="/admin/subscriptions/templates/add" element={<AddBoxTemplates />} />
    <Route path="/admin/vouchers/templates" element={<VoucherBank />} />
    <Route path="/admin/vouchers/templates/:templateId" element={<EditVoucherTemplate />} />
    <Route path="/admin/vouchers/templates/add" element={<AddVoucherTemplate />} />
    <Route path="/admin/help" element={<AdminHelp />} />
    <Route path="/admin/notifications" element={<AdminNotifications />} />
    <Route path="/admin/broadcast" element={<AdminBroadcast />} />
    <Route path="/admin/profile" element={<AdminProfile />} />
    <Route path="/admin/management" element={<AdminListing />} />
    <Route path="/admin/reports" element={<AdminReports />} />
  </Route>
  </Route>


    
     
   <Route path="*" element={<Navigate to="/" />} />
    </Routes>
 
    </BrowserRouter>
  );
}

export default App;