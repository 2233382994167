import {useState, useEffect} from 'react'
import { Nav,Navbar, Button, Image, Row, Col, Container } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { GoPerson } from "react-icons/go";
import { FaBasketShopping } from "react-icons/fa6";
import { BsHandbag } from "react-icons/bs";
import { BsGear } from "react-icons/bs";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { MdOutlineDashboard, MdOutlineLiveHelp } from "react-icons/md";
import logo2 from '../../assets/footer-logo.png'
import { GoBell } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import { BsBroadcastPin } from "react-icons/bs";
import { GrUserManager } from "react-icons/gr";
import GuestNavbar, { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { BsEmojiSmile } from "react-icons/bs";
import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../../App'

const AdminSidebar = () => {

  const token = localStorageGetItem('userToken');
  const userType = localStorageGetItem('userType');
 
  const userData = getParsedLocalStorageItem('userData');
  const isLoggedIn = !!token;
  return (
    <Nav className='admin-sidebar pb-3' style={{boxShadow: '0px 4px 4px 0px #00000040', backgroundColor:'#5A7F48'}}>

      <div className='d-flex ps-5 pe-2 gap-5 py-3 align-items-center'  style={{ height:'76px', boxShadow: '0px 4px 4px 0px #00000040'}}> <img className="" src={logo2} alt="" style={{width:'50px'}}/>
      <NavLink to='/admin/notifications' className={({ isActive }) => isActive ? ' nav-link active' : ' nav-link'} ><GoBell style={{fontSize:'1.2rem'}}/></NavLink>
     
      </div>
     
      <div className='d-flex flex-column px-4 pt-2 mb-auto' style={{gap:'8px'}}>
       <NavLink to='/admin/reports' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active pt-3' : 'd-flex gap-3 align-items-center nav-link pt-3'} ><MdOutlineDashboard style={{fontSize:'1.2rem'}}/>Reports</NavLink>
       <NavLink to='/admin/orders' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><FaBasketShopping style={{fontSize:'1.2rem'}}/>Orders</NavLink>
       <NavLink to='/admin/customers' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><GoPerson style={{fontSize:'1.2rem'}}/>Customers</NavLink>
       <NavLink to='/admin/products' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><BsHandbag style={{fontSize:'1.2rem'}}/>Products</NavLink>
       <NavLink to='/admin/management' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><GrUserManager style={{fontSize:'1.2rem'}}/>Admins</NavLink>
       <NavLink to='/admin/user-delights' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><BsEmojiSmile style={{fontSize:'1.2rem'}}/>User Delights</NavLink>
       <NavLink to='/admin/broadcast' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><BsBroadcastPin style={{fontSize:'1.2rem'}}/>Broadcast</NavLink>
         
      </div>
      <div className='d-flex flex-column px-4 ' style={{gap:'8px'}}>
       <NavLink to='/admin/help' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active pt-3' : 'd-flex gap-3 align-items-center nav-link pt-3'} ><MdOutlineLiveHelp style={{fontSize:'1.2rem'}}/>Help Center</NavLink>
       <NavLink to='/admin/profile' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><CgProfile style={{fontSize:'1.2rem'}}/>{userData?.firstName} {userData?.lastName}</NavLink>
   
      
         
      </div>


    </Nav>
  )
}

export default AdminSidebar
