import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams,useLocation} from 'react-router-dom'
import { Modal, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Card} from 'react-bootstrap';
import { debounce } from 'lodash'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select from 'react-select';
import TemplateModal from '../../components/Modal/TemplateModal';
import { toSvg } from 'html-to-image';

import SuccessModal from '../../components/Modal/SuccessModal';

const VoucherDetails = () => {
    const { voucherId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { showToast } = useAppContext();
    const { getGlobalVoucherTemplates,getSingleVoucher,editVoucher} = productRoute();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [voucherData, setVoucherData] = useState({
        voucherName:'',
        voucherDescription:'',
        validityPeriod:'',
        voucherPrice:'',
        priceType:'',
        desktopVoucherCover:null,
desktopVoucherPreview:null,
desktopVoucherSpecialNote:null,
mobileVoucherCover:null,
mobileVoucherPreview:null,
mobileVoucherSpecialNote:null
    })
    const [captureLoading, setCaptureLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(state?.isEditing || false);
    const [initialVoucherData, setInitialVoucherData] = useState({
        voucherName:'',
        voucherDescription:'',
        validityPeriod:'',
        voucherPrice:'',
        priceType:'',
        desktopVoucherCover:null,
desktopVoucherPreview:null,
desktopVoucherSpecialNote:null,
mobileVoucherCover:null,
mobileVoucherPreview:null,
mobileVoucherSpecialNote:null
    })
    const [globalVoucherTemplates, setGlobalVoucherTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState (null)
    const desktopCoverRef = useRef(null);
    // const mobileCoverRef = useRef(null);
    const desktopPreviewRef = useRef(null);
    // const mobilePreviewRef = useRef(null);
    const [capturedDesktopImage, setCapturedDesktopImage] = useState(null);
    // const [capturedMobilePreviewImage, setCapturedMobilePreviewImage] = useState(null);
    const [capturedDesktopPreviewImage, setCapturedDesktopPreviewImage] = useState(null);
    // const [capturedMobileImage, setCapturedMobileImage] = useState(null);
    const [showTemplateModal, setShowTemplateModal]= useState(false);


    useEffect(() => {
        getSingleVoucher(
            voucherId,
            setLoading,
            showToast,
            setInitialVoucherData,
            setVoucherData,
        );
      
    }, []);
    useEffect(() => {

        getGlobalVoucherTemplates(
          setLoading, showToast, setGlobalVoucherTemplates
         
        )
          
        }, []);

        useEffect(() => {

          if(!!capturedDesktopImage &&!!capturedDesktopPreviewImage){
           setCaptureLoading(false)
          }
           }, [capturedDesktopImage,capturedDesktopPreviewImage]);
          
    

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
    
            [{'list': 'ordered'}, {'list': 'bullet'}], // lists
            [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}], // indent
    
          
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
            [{'color': []}, {'background': []}], // dropdown with defaults
            [{'font': []}],
            [{'align': []}],
    
            ['clean'], // remove formatting
            ['link', 'image', 'video'] // link and image, video
        ]
    };
 
    const handleBack = ()=> {
        navigate(-1)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVoucherData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    
    const handleDescriptionChange = (value) => {
    
        setVoucherData(prev => ({ ...prev, voucherDescription: value }));
    };
   
  
    const handleEdit = () => {
        setIsEditing(true);
      };
      const isFormValid = () => {
        // Basic validation checks for required fields
        const hasValidName = !!voucherData?.voucherName;
        const hasValidValidityPeriod = !!voucherData?.validityPeriod;
        const hasValidPriceType = !!voucherData?.priceType;
        const hasSelectedTemplate = !!selectedTemplate;
        const hasCapturedDesktopImage = !!capturedDesktopImage;
        // const hasCapturedMobileImage = !!capturedMobileImage;
        const hasCapturedDesktopPreviewImage = !!capturedDesktopPreviewImage;
        // const hasCapturedMobilePreviewImage = !!capturedMobilePreviewImage;
        // Additional check for voucherPrice if priceType is 'admin'
        const hasValidVoucherPrice = voucherData?.priceType !== 'admin' || !!voucherData?.voucherPrice;
      
        // Combine all validation checks
        return hasValidName &&
               hasValidValidityPeriod &&
               hasValidPriceType &&
               hasValidVoucherPrice &&
               (voucherData?.desktopVoucherCover ||
               hasCapturedDesktopImage) &&
              //  hasCapturedMobileImage && 
              (voucherData?.desktopVoucherPreview || hasCapturedDesktopPreviewImage) 
              //  && hasCapturedMobilePreviewImage;
      };
   

    // const isFormValid = () => {
       
    //     return voucherData?.voucherName &&
    //     voucherData?.validityPeriod && voucherData?.priceType && (!selectedTemplate || (capturedDesktopImage && capturedMobileImage && capturedDesktopPreviewImage && capturedMobilePreviewImage)) && (voucherData?.priceType === 'admin' && voucherData?.voucherPrice)
    //   };
      const stripHtml = (html) => {
        try {
            const temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;
            return temporalDivElement.textContent || temporalDivElement.innerText || "";
        } catch (error) {
            console.error('Error stripping HTML:', error);
            return "";  // Return empty string on error
        }
    };
    

    const handleUploadTemplate = () => {
        if (!voucherData.voucherName) {
            showToast({ toastText: 'Please enter the voucher name before selecting a template.', isSuccess: false });
            return;
        }
        setShowTemplateModal(true);
    };
  
    // Close modal
    const handleCloseModal = () => {
        setShowTemplateModal(false);
        setSelectedTemplate(null)
    };
  
    // Handle template selection
    const handleTemplateClick = (template) => {
     
        setSelectedTemplate(template);
       
  
    };
    const captureImage = (ref, setCapturedImage) => {
      const node = ref.current;
    
      if (!node) {
        console.error('Element to capture not found');
        return;
      }
    
      toSvg(node, {
        quality: 0.8, // Reduced quality for faster processing
        width: node.offsetWidth,
        height: node.offsetHeight,
      })
        .then((dataUrl) => {
       
          if (dataUrl === 'data:,') {
            console.error('Captured image is empty.');
            return;
          }
          setCapturedImage(dataUrl);
         
        })
        .catch((error) => {
          console.error('Failed to capture image:', error);
        });
    };
    
    const handleSelectTemplate = () => {
      setShowTemplateModal(false);
    
      const imagesLoaded = new Promise((resolve, reject) => {
        const desktopImage = new window.Image();
        // const mobileImage = new window.Image();
        const desktopPreviewImage = new window.Image();
        // const mobilePreviewImage = new window.Image();
    
        let loadedCount = 0;
        const onLoad = () => {
          loadedCount++;
          console.log(`Image ${loadedCount} loaded`);
          if (loadedCount === 2) { 
            resolve();
          }
        };
    
        const onError = (error) => {
          console.error('Image failed to load:', error);
          reject(error);
        };
    
        desktopImage.src = selectedTemplate.desktopVoucherCover;
        // mobileImage.src = selectedTemplate.mobileVoucherCover;
        desktopPreviewImage.src = selectedTemplate.desktopVoucherPreview;
        // mobilePreviewImage.src = selectedTemplate.mobileVoucherPreview;
    
        desktopImage.onload = onLoad;
        // mobileImage.onload = onLoad;
        desktopPreviewImage.onload = onLoad;
        // mobilePreviewImage.onload = onLoad;
    
        desktopImage.onerror = onError;
        // mobileImage.onerror = onError;
        desktopPreviewImage.onerror = onError;
        // mobilePreviewImage.onerror = onError;
      });
    
      imagesLoaded
        .then(() => {
          console.log('All images loaded');
          setCaptureLoading(true);
          setTimeout(() => {
            captureImage(desktopCoverRef, setCapturedDesktopImage);
            // captureImage(mobileCoverRef, setCapturedMobileImage);
            captureImage(desktopPreviewRef, setCapturedDesktopPreviewImage);
            // captureImage(mobilePreviewRef, setCapturedMobilePreviewImage);
          }, 1000); 
        })
        .catch((error) => {
          console.error('Failed to load images:', error);
        });
    };
    
    // const captureImage = (ref, setCapturedImage) => {
    //   const node = ref.current;
    //   console.log('Node to capture:', node);
    
    //   if (!node) {
    //     console.error('Element to capture not found');
    //     return;
    //   }
    
    //   toSvg(node, {
    //     quality: 1,
    //     width: node.offsetWidth,
    //     height: node.offsetHeight,
    //   })
    //     .then((dataUrl) => {
    //       console.log('Data URL:', dataUrl);
    //       if (dataUrl === 'data:,') {
    //         console.error('Captured image is empty.');
    //         return;
    //       }
    //       setCapturedImage(dataUrl);
    //       console.log('Captured Image:', dataUrl);
    //     })
    //     .catch((error) => {
    //       console.error('Failed to capture image:', error);
    //     });
    // };
    
  
    // const captureImage = (ref, setCapturedImage) => {
    //     const node = ref.current;
    //     console.log('Node to capture:', node); // Debug log
      
    //     if (!node) {
    //       console.error('Element to capture not found');
    //       return;
    //     }
      
    //     DomToImage.toPng(node, {
    //     // useCORS: true,
    //       cacheBust: true,
    //       quality: 1,
    //       width: node.offsetWidth,
    //       height: node.offsetHeight,
    //     })
    //       .then((dataUrl) => {
    //         console.log('Data URL:', dataUrl); // Debug log
    //         if (dataUrl === 'data:,') {
    //           console.error('Captured image is empty.');
    //           return;
    //         }
    //         setCapturedImage(dataUrl);
    //         console.log('Captured Image:', dataUrl);
    //       })
    //       .catch((error) => {
    //         console.error('Failed to capture image:', error);
    //       });
    //   };
      
    
  
  
    // const handleSelectTemplate = () => {
    //   setShowTemplateModal(false);
    
    //   const imagesLoaded = new Promise((resolve, reject) => {
    //     const desktopImage = new window.Image();
    //     const mobileImage = new window.Image();
    //     const desktopPreviewImage = new window.Image();
    //     const mobilePreviewImage = new window.Image();

    //     let loadedCount = 0;
    //     const onLoad = () => {
    //       loadedCount++;
    //       console.log(`Image ${loadedCount} loaded`);
    //       if (loadedCount === 2) {
    //         resolve();
    //       }
    //     };
    
    //     const onError = (error) => {
    //       console.error('Image failed to load:', error);
    //       reject(error);
    //     };
    
    //     desktopImage.src = selectedTemplate.desktopVoucherCover;
    //     mobileImage.src = selectedTemplate.mobileVoucherCover;
    //     desktopPreviewImage.src = selectedTemplate.desktopVoucherPreview;
    //     mobilePreviewImage.src = selectedTemplate.mobileVoucherPreview;
    
    //     desktopImage.onload = onLoad;
    //     mobileImage.onload = onLoad;
    //     desktopPreviewImage.onload = onLoad;
    //     mobilePreviewImage.onload = onLoad;

    
    //     desktopImage.onerror = onError;
    //     mobileImage.onerror = onError;
    //     desktopPreviewImage.onerror = onError;
    //     mobilePreviewImage.onerror = onError;
    //   });
    
    //   imagesLoaded
    //     .then(() => {
    //       console.log('All images loaded');
    //       setCaptureLoading(true)
    //       setTimeout(() => {
    //         captureImage(desktopCoverRef, setCapturedDesktopImage);
    //         captureImage(mobileCoverRef, setCapturedMobileImage);
    //         captureImage(desktopPreviewRef, setCapturedDesktopPreviewImage);
    //         captureImage(mobilePreviewRef, setCapturedMobilePreviewImage);
    //       }, 2000);
    //     })
    //     .catch((error) => {
    //       console.error('Failed to load images:', error);
    //     });
    // };
      
  
  
  
  const dataURLToBlob = (dataUrl) => {
    const [header, data] = dataUrl.split(',');
  
    // Check if the data URL is for SVG
    if (header.includes('image/svg+xml')) {
      const svgBlob = new Blob([decodeURIComponent(data)], { type: 'image/svg+xml' });
      return svgBlob;
    }
  
    // For other data URL formats
    const mime = header.match(/:(.*?);/)[1];
    const bstr = atob(data);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new Blob([u8arr], { type: mime });
  };
  
  const handleSave = (e) => {
    e.preventDefault();
    const plainTextDescription = stripHtml(voucherData.voucherDescription);
  if (voucherData?.voucherDescription !== '' && (plainTextDescription.length < 8 || plainTextDescription.length > 400)) {
        showToast({ toastText: "Product description must be between 8 and 400 characters.", isSuccess: false });
        return;
    }


    let validityPeriodNumber = null;
    let validityPeriodType = null;
  
    const validityPeriodMatch = voucherData.validityPeriod.match(
      /(\d+)\s*(day|month|year)/i
    );
  
    if (validityPeriodMatch) {
      validityPeriodNumber = parseInt(validityPeriodMatch[1], 10); // Extract the number part
      validityPeriodType = validityPeriodMatch[2].toLowerCase(); // Extract and convert the type part to lowercase
    }
  
  
    const formData = new FormData();
    const timestamp = Date.now();
   
    formData.append('name', voucherData.voucherName);
   if (voucherData.priceType === 'admin'){
    formData.append('amount', voucherData.voucherPrice);}
    
    formData.append('priceActor', voucherData.priceType);
    if (validityPeriodNumber !== null && validityPeriodType !== null) {
      formData.append("validityPeriod", validityPeriodNumber);
      formData.append("validityPeriodType", validityPeriodType);
    }
   
    if (capturedDesktopImage) {
    formData.append('desktopVoucherSpecialNote', selectedTemplate.desktopVoucherSpecialNote);}
    else{
      formData.append('desktopVoucherSpecialNote', voucherData.desktopVoucherSpecialNote);}
   

    // if (capturedMobileImage) {
    //   formData.append('mobileVoucherSpecialNote', selectedTemplate.mobileVoucherSpecialNote);
    // }else{
    //   formData.append('mobileVoucherSpecialNote', voucherData.mobileVoucherSpecialNote);}

    if (capturedDesktopImage) {
      const desktopBlob = dataURLToBlob(capturedDesktopImage);
    
      formData.append('desktopVoucherCover', desktopBlob, `desktop-voucher-cover-${timestamp}.svg`);
    }

    // if (capturedMobileImage) {
    //   const mobileBlob = dataURLToBlob(capturedMobileImage);
    //   console.log(mobileBlob,'mobile image')
    //   formData.append('mobileVoucherCover', mobileBlob, `mobile-voucher-cover-${timestamp}.svg`);
    // }
    

    if (capturedDesktopPreviewImage) {
      const desktopPreviewBlob = dataURLToBlob(capturedDesktopPreviewImage);
    
      formData.append('desktopVoucherPreview', desktopPreviewBlob, `desktop-voucher-preview-${timestamp}.svg`);
    }

    // if (capturedMobilePreviewImage) {
    //   const mobilePreviewBlob = dataURLToBlob(capturedMobilePreviewImage);
    //   console.log(mobilePreviewBlob,'mobile preview image')
    //   formData.append('mobileVoucherPreview', mobilePreviewBlob, `mobile-voucher-preview-${timestamp}.svg`);
    // }

    if (voucherData.voucherDescription !== null && voucherData.voucherDescription !== '' && voucherData.voucherDescription !== undefined) {
        formData.append('description', plainTextDescription);
    }

 

    editVoucher (
      voucherId,
      formData,
      setLoading,
      showToast
     
      )

  
};

 
 
  return (
    <div className=''>
          <div className='d-flex gap-3 align-items-center header-admin  py-5 pe-5 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-3'> Voucher Details</h3>
        {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-3'>Edit</p>
      )}
        </div>

        <div className=' pe-5 ps-4 '>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Voucher Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='voucherName' value={voucherData.voucherName} onChange={handleChange} required disabled={!isEditing}/>
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Select Price Type <sup className='text-danger'>*</sup></p>
                <Form.Select type='text' name='priceType' value={voucherData.priceType} onChange={handleChange} disabled={!isEditing} >
                <option value="" disabled>Select Price Type</option>
    
              
                <option value='customer'>Set by Customer</option>
                <option value='admin'>Set by Admin</option>
  
                </Form.Select>
                
                </Col>
{
voucherData?.priceType === 'admin' &&

                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Price <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='voucherPrice' value={voucherData.voucherPrice} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setVoucherData({ ...voucherData, voucherPrice: numericValue })
                }}  disabled={!isEditing}/>
                
                </Col>}
              
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Validity Period:</p>
              
                <Form.Select type='text' name='validityPeriod' value={voucherData.validityPeriod} onChange={handleChange} disabled={!isEditing}>
                <option value="" disabled>Select validity period</option>
    
    <option value='6 months after purchase'>6 months after purchase</option>
    <option value='12 months after purchase'>12 months after purchase</option>
  
                </Form.Select>
       
                
                </Col>
                <Col xs={12} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Voucher Description:</p>
                {isEditing ?   <ReactQuill value={voucherData.voucherDescription}  onChange={handleDescriptionChange} modules={modules} /> : <div className='p-3' style={{border:'1px solid #E7E7E7',height:'200px'}}>{voucherData.voucherDescription} </div> }
               
                
                </Col>

            </Row> 

          
            <div className='my-4 d-flex flex-column gap-4'>
            {voucherData?.desktopVoucherCover || voucherData?.desktopVoucherPreview  ?
            <>
            <div className='d-flex gap-2 align-items-center '>
            <Button className='' variant='outline-secondary' style={{borderStyle:'dashed',color:'#353542', fontWeight:'500'}}>Selected Voucher Template</Button>
                              {isEditing &&  <img src={deleteIcon}  onClick={()=>
                            setVoucherData({...voucherData,desktopVoucherCover:null,
                                desktopVoucherPreview:null,
                                desktopVoucherSpecialNote:null})} style={{ width: '24px', height: '24px', cursor: 'pointer',}} />}
            </div>
                               
                        
            <div className="d-flex mt-3  gap-4 align-items-center">
                            
               { voucherData?.desktopVoucherCover &&            
            <div>
        <img
            src={voucherData?.desktopVoucherCover}
            alt="Selected Template"
            className="img-fluid"
            style={{ width: '100%', height: '250px' }}
        />
        </div>}
        { voucherData?.desktopVoucherPreview &&            
                         <div>
                     <img
                         src={voucherData?.desktopVoucherPreview}
                         alt="Selected Template"
                         className="img-fluid"
                         style={{ width: '100%', height: '200px' }}
                     />
                     </div>}
{/*         
        { voucherData?.mobileVoucherCover &&            
            <div>
        <img
            src={voucherData?.mobileVoucherCover}
            alt="Selected Template"
            className="img-fluid"
            style={{ width: '200px', height: '105px' }}
        />
        </div>} */}
</div>       
{/* <div className="d-flex mt-3 gap-4 align-items-center">
                            
                            { voucherData?.desktopVoucherPreview &&            
                         <div>
                     <img
                         src={voucherData?.desktopVoucherPreview}
                         alt="Selected Template"
                         className="img-fluid"
                         style={{ width: '100%', height: '200px' }}
                     />
                     </div>}
                     
                     
                     { voucherData?.mobileVoucherPreview &&            
                         <div>
                     <img
                         src={voucherData?.mobileVoucherPreview}
                         alt="Selected Template"
                         className="img-fluid"
                         style={{ width: '200px', height: '135px' }}
                     />
                     </div>}
             </div>      */}
                               
                               </>
           :
                selectedTemplate ? (
            <>
             <div className='d-flex gap-2 align-items-center '>
             <Button className='' variant='outline-secondary' style={{borderStyle:'dashed',color:'#353542', fontWeight:'500'}}>Selected Voucher Template</Button>
                               {isEditing && <img src={deleteIcon} onClick={()=> {setSelectedTemplate(null); setCapturedDesktopImage(null);setCapturedDesktopPreviewImage(null)}} style={{ width: '24px', height: '24px', cursor: 'pointer',}} />}</div>
                               { !capturedDesktopImage && !capturedDesktopPreviewImage ?
                            
                            <div>
                            <div className="d-flex  gap-4 my-4 align-items-center">
                                
                            
                           
                            <div className="template-preview mb-4" style={{ position: 'relative'}} ref={desktopCoverRef}>
                        <img
                            src={selectedTemplate.desktopVoucherCover}
                            alt="Selected Template"
                            className="img-fluid"
                            style={{ width: '100%', height: '250px' }}
                        />
                          
                          <Card.ImgOverlay  className='d-flex justify-content-center align-items-center mx-auto 'style={{width:'125px', height:'90px', marginTop:'50px'}}>
                            <div className='justify-content-center align-items-center'>
                      
                          <p style={{ color: '#000000', fontSize: '18px', fontWeight:'700', textTransform:'uppercase', letterSpacing:'0.5%',lineHeight:'18px',fontFamily:'Montserrat', fontWeight:'700',textTransform:'uppercase',}}  className='text-center text-align-center align-items-center justify-content-center  '>{voucherData.voucherName}</p> 
                          </div>
                          
                        
                        </Card.ImgOverlay>
                        
                  
                </div>
                <div className="template-preview" style={{ position: 'relative'}} ref={desktopPreviewRef}>
                            <img
                                src={selectedTemplate.desktopVoucherPreview}
                                alt="Selected Template"
                                className="img-fluid"
                                style={{ width: '100%', height: '200px' }}
                            />
                            
                            <Card.ImgOverlay  className='d-flex justify-content-center mt-3'>
                              <p style={{ color: '#1D2939', fontSize: '20px', fontWeight:'500',  lineHeight:'20px'  }}  className='voucher-preview-text align-items-center justify-content-center'>{voucherData.voucherName}</p>  
                            </Card.ImgOverlay>
                            
                      
                    </div>
                {/* <div className="template-preview mb-4" style={{ position: 'relative'}} ref={mobileCoverRef}>
                        <img
                            src={selectedTemplate?.mobileVoucherCover}
                            alt="Selected Template"
                            className="img-fluid"
                            style={{ width: '200px', height: '105px' }}
                        />
                      <Card.ImgOverlay  className='d-flex justify-content-center align-items-center mx-auto 'style={{width:'50px', height:'50px', marginTop:'25px'}}>
                            <div className='justify-content-center align-items-center'>
                      
                      <p style={{ color: '#000000', fontSize: '9px', fontWeight:'700', textTransform:'uppercase', letterSpacing:'0.5%',lineHeight:'8px',fontFamily:'Montserrat', fontWeight:'700',textTransform:'uppercase'}}  className='text-center text-align-center align-items-center justify-content-center'>{voucherData.voucherName}</p>  
                      </div>
                    
                    </Card.ImgOverlay>
                        
                        </div> */}
            </div>
            {/* <div className="d-flex  gap-4 mt-3 align-items-center">
                                
                            
                           
                                <div className="template-preview" style={{ position: 'relative'}} ref={desktopPreviewRef}>
                            <img
                                src={selectedTemplate.desktopVoucherPreview}
                                alt="Selected Template"
                                className="img-fluid"
                                style={{ width: '100%', height: '200px' }}
                            />
                            
                            <Card.ImgOverlay  className='d-flex justify-content-center mt-3'>
                              <p style={{ color: '#1D2939', fontSize: '20px', fontWeight:'500',  lineHeight:'20px'  }}  className='voucher-preview-text align-items-center justify-content-center'>{voucherData.voucherName}</p>  
                            </Card.ImgOverlay>
                            
                      
                    </div>
                    <div className="template-preview" style={{ position: 'relative'}} ref={mobilePreviewRef}>
                            <img
                                src={selectedTemplate?.mobileVoucherPreview}
                                alt="Selected Template"
                                className="img-fluid"
                                style={{ width: '200px', height: '135px' }}
                            />
                            
                            <Card.ImgOverlay  className='d-flex justify-content-center mt-2'>
                              <p style={{ color: '#1D2939', fontSize: '15px',  lineHeight:'20px', fontWeight:'500'  }}  className='voucher-preview-text align-items-center justify-content-center'>{voucherData.voucherName}</p>  
                            </Card.ImgOverlay>
                            </div>
                </div> */}
            
            </div> : 
            <div className='d-flex flex-column gap-4'>
                            <div className="d-flex mt-3 gap-4 align-items-center">
          
            {capturedDesktopImage && (
                <div>
                  
                    <img src={capturedDesktopImage} alt="Captured Desktop" />
                </div>
            )}
             {capturedDesktopPreviewImage && (
                <div>
                  
                    <img src={capturedDesktopPreviewImage} alt="Captured Desktop" />
                </div>
            )}
{/* 
            {capturedMobileImage && (
                <div>
                  
                    <img src={capturedMobileImage} alt="Captured Mobile" />
                </div>
            )} */}
            </div>
            {/* <div className="d-flex  gap-4 align-items-center">
          
            {capturedDesktopPreviewImage && (
                <div>
                  
                    <img src={capturedDesktopPreviewImage} alt="Captured Desktop" />
                </div>
            )}

            {capturedMobilePreviewImage && (
                <div>
                  
                    <img src={capturedMobilePreviewImage} alt="Captured Mobile" />
                </div>
            )}
            </div> */}
            </div>}
            {captureLoading && 
        <div className='d-flex gap-1 justify-content-center align-items-center text-center' style={{ padding: '10px 0px' }}>
        <div className="spinner-border text-success" role="status">
         
        </div>
        <p className="my-auto">Capturing Voucher...</p>
      </div>
}
            <div>


           

                            
                               </div>
                            </>
                        ) : (<div>
                          
                            <Button variant="outline-secondary" disabled={!isEditing} onClick={handleUploadTemplate} style={{ 
                                color:'333333',borderStyle:'dashed',borderRadius:'4px',  }} className=''>
                            Upload Voucher Template<sup className='text-danger'>*</sup>
                        </Button></div>
                        )}
                        </div>
                        {isEditing && 
                        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', voucherShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>}

        </Form>}

     
  
    
      </div>
      <Modal show={showTemplateModal} onHide={handleCloseModal} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
    
          <Modal.Body className='d-flex flex-column justify-content-center text-center gap-3 p-5' >
         
        
         <Row className='justify-content-center p-3' style={{border:'3px solid #808080'}}>
         {loading ? (
                                       <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                                         <div className="spinner-border text-success" role="status">
                                           <span className="visually-hidden">Loading...</span>
                                         </div>
                                       </div>
                                     ) : (<> {globalVoucherTemplates?.length > 0 ?<>
         {globalVoucherTemplates?.map((template,index)=> (
              
<Col xs={6} key={index} className='d-flex  align-items-center p-2 justify-content-center p-2'  onClick={() => handleTemplateClick(template)}
style={{
    border: selectedTemplate?.id === template.id ? '3px solid #469B12' : 'none'
}}>
    <img src={template?.desktopVoucherCover} alt={template?.groupName} style={{width : '250px', height:'160px'}}
   className={`template-item ${selectedTemplate?.id === template.id ? 'selected' : ''}`}
               
    />
</Col>
         
        
        ))}</>:  <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no templates</h4></div>}</>)}
         </Row>
         <div className='d-flex gap-3 text-center justify-content-center'>
            <Button variant='danger' onClick={handleCloseModal}>Cancel</Button>
            <Button variant='primary' disabled={!selectedTemplate}  onClick={handleSelectTemplate} style={{backgroundColor: selectedTemplate ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6' }}>Select Template</Button>
            </div>
          
           
          </Modal.Body>
          
            </Modal>
           
    </div>
  )
}

export default VoucherDetails
