

import Error from './error';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../mixpanel';
import { transformPath } from '../App';
import createAxios from './httpCommon';


import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../App';

export default () => {
  const { error } = Error();
  const navigate = useNavigate();

  

const login = (
  body,
  setLoading,
  showToast,

  
) => {
 
  createAxios()
    .then((axios) => {
      axios
      .post('/admin/sign-in', body)
        .then(async (response) => {
          setLoading(false);
        
          const gotResponse = response?.data?.data;
          if (gotResponse) {
           
            setTimeout(() => {
             
             if(gotResponse.role === 'admin'){
              localStorageSetItem('userToken', gotResponse?.accessToken);
              localStorageSetItem('userType', gotResponse?.role);
              localStorageSetItem("userData", JSON.stringify(gotResponse?.user));
            
             
           
              
              showToast({
                toastText: 'Welcome back!',
                isSuccess: true
              });
              navigate('/admin/orders')
             }else{
              
              showToast({
                toastText: 'You are not an Admin',
                isSuccess: false
              });
              navigate('/');
             }
          
            }, 3000); 
          } else {
            showToast({
              toastText: 'Failed to login!',
              isSuccess: false
            });
           
          }
        })
        .catch((e) => {
        
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        });
    });
};





  return {
    
    login
    };
};