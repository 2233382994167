import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { Modal, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select from 'react-select';
import SuccessModal from '../../components/Modal/SuccessModal';
import { init } from 'mixpanel-browser';

const EditVoucherTemplate = () => {
    const { templateId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { showToast, showSuccessModal, setShowSuccessModal}= useAppContext()
    const { getSingleVoucherTemplate,editVoucherTemplate } = productRoute();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [voucherData, setVoucherData] = useState({
        templateGroupName:'',
        desktopCover:null,
        desktopPreview:null,
        desktopNote: null
    })

    const [initialVoucherData, setInitialVoucherData] = useState({
        templateGroupName:'',
        desktopCover:null,
        desktopPreview:null,
        desktopNote: null
    })
   
    const [isEditing, setIsEditing] = useState(state?.isEditing || false);
    
    useEffect(() => {
        getSingleVoucherTemplate(
            templateId,
            setLoading,
            showToast,
            setInitialVoucherData,
            setVoucherData,
        );
      
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVoucherData(prev => ({
            ...prev,
            [name]: value
        }));
    };
    
    const handleImageChange = (event, field) => {
        const file = event.target.files[0];
      
        // Check if the file is of type PNG or JPEG and ensure it is not an SVG
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 10485760) {
          setVoucherData(prev => ({ ...prev, [field]: file }));
        } else {
          // Replace with your toast or alert function
          alert('Invalid file. Please upload a PNG or JPEG image file up to 10MB.');
        }
      };
      
 
      const handleEdit = () => {
        setIsEditing(true);
      };

    

    const handleDeleteImage = (field) => {
        setVoucherData(prev => ({ ...prev, [field]: null }));
    };
    

    const isFormValid = () => {

      return voucherData?.templateGroupName && voucherData?.desktopCover && voucherData?.desktopPreview && voucherData?.desktopNote &&(voucherData?.templateGroupName !==initialVoucherData?.templateGroupName || voucherData?.desktopCover !== initialVoucherData?.desktopCover  || voucherData?.desktopPreview !== initialVoucherData?.desktopPreview || voucherData?.desktopNote !== initialVoucherData?.desktopNote  )
   
      };
    
const handleSave = (e) => {
  e.preventDefault();

  if (voucherData === initialVoucherData) {
    return;
  }

    const formData = new FormData();
    if (voucherData.templateGroupName !== initialVoucherData?.templateGroupName) {
      formData.append('groupName', voucherData.templateGroupName);
      formData.append('alt', voucherData.templateGroupName);
    }
    if (voucherData.desktopCover !== initialVoucherData?.desktopCover) {
      formData.append('desktopVoucherCover', voucherData.desktopCover);
    }
  
    if (voucherData.desktopPreview !== initialVoucherData?.desktopPreview) {
      formData.append('desktopVoucherPreview', voucherData.desktopPreview);
    }
   
    if (voucherData.desktopNote !== initialVoucherData?.desktopNote) {
      formData.append('desktopVoucherSpecialNote', voucherData.desktopNote);
    }
   
    editVoucherTemplate(templateId, formData, setLoading, showToast, 'multipart/form-data');
  

};


    const handleBack = ()=> {
        navigate(-1)
    }


    const renderImageSection = (field, label) => {
       const isMobile = label.toLowerCase().includes('mobile');
        let widthHeight;
        if (label.toLowerCase().includes('cover')){
         widthHeight = isMobile ? { width: '200px', height: '105px' } : { width: '100%', height: '160px' };
        }
        else if (label.toLowerCase().includes('preview')){
            widthHeight = isMobile ? { width: '200px', height: '134px' } : { width: '100%', height: '200px' };
           }
           else if (label.toLowerCase().includes('note')){
            widthHeight = isMobile ? { width: '94px', height: '134px' } : { width: '180px', height: '250px' };
           }
       
        return(
            <Col xs={4} className='pe-3 mb-4'>
            <p style={{color:'#333333', fontWeight:'500'}} className=''>{label} <sup className='text-danger'></sup></p>
           
            <div className='my-3'>
              {voucherData[field] ? (
                <>
                 <Card style={{border:"1px solid #E7E7E7", height:'270px'}} className='mb-3 p-3 align-items-center justify-content-center'>
                    <img
                      src={typeof voucherData[field] === 'string' ? voucherData[field] : URL.createObjectURL(voucherData[field])}
                      onLoad={() => URL.revokeObjectURL(voucherData[field])}
                      alt='Product'
                      style={{ ...widthHeight }}
                    />
                  </Card>
                  <div className='d-flex align-items-center gap-3'>
                    <Button
                      variant='outline-secondary'
                      className='mt-1'
                      style={{
                        borderStyle: 'dashed',
                        borderRadius: '4px',
                        color: '#353542',
                        fontWeight: '500',
                      }}
                    >
                      Selected Template
                    </Button>
                    {isEditing && (
                      <img
                        src={deleteIcon}
                        onClick={() => handleDeleteImage(field)}
                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Card
                    style={{ border: '1px solid #E7E7E7', height: '250px' }}
                    className='mb-3'
                  ></Card>
                  <Form.Control
                    type='file'
                    onChange={(e) => handleImageChange(e, field)}
                    accept='image/*'
                    style={{ display: 'none' }}
                    id={`${field}Input`}
                  />
                  <Button
                    variant='outline-secondary'
                    disabled={!isEditing}
                    onClick={() => document.getElementById(`${field}Input`).click()}
                    style={{
                      borderStyle: 'dashed',
                      borderRadius: '4px',
                      color: '#353542',
                      fontWeight: '500',
                    }}
                    className='px-4'
                  >
                    Select template<sup className='text-danger'>*</sup>
                  </Button>
                </>
              )}
            </div>
          </Col>
    )
}
 
  return (
    <div className=''>
          <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-3'> Voucher Template</h3>
        {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-3'>Edit</p>
      )}
        </div>

        <div className=' pe-5 ps-4 '>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Template Group Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' className='col-6'name='templateGroupName' value={voucherData.templateGroupName} placeholder = 'Enter template name or leave blank for system to auto-generate' onChange={handleChange} required disabled={!isEditing}/>
                </Col>
              
            </Row> 
            <Row className=''>
              
            
              
                {renderImageSection('desktopCover', 'Voucher Cover')}
                {renderImageSection('desktopPreview', 'Voucher Preview')}  
                {renderImageSection('desktopNote', 'Voucher Special Note')}
               


            </Row> 

            {isEditing && 

                        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>}

        </Form>}

     
  
    
      </div>
    
    </div>
  )
}

export default EditVoucherTemplate
