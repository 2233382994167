import { useState } from 'react'
import {Image,Form, Button,Row,Col, Card } from 'react-bootstrap'

import logo from '../../assets/vs-word-logo.png'
import { Link, useNavigate } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import apple from '../../assets/apple-icon.svg'
import facebook from '../../assets/facebook-icon.svg'
import GuestNavbar from '../../components/Navbar/Navbar';
import eye from '../../assets/eye.svg'
import authRoute from '../../services/authRoute'
import { useAppContext } from '../../AppContext'
import homeBg from '../../assets/home-bg.png'
import { getParsedLocalStorageItem } from './AdminProfile'
import user from '../../assets/profile-circled.svg'
import arrowLeft from '../../assets/arrow-left.svg'

const AcceptAdminInvite = () => {

  const {showToast}= useAppContext()
 
 const navigate = useNavigate();

    const [formData, setFormData] = useState({
    
      role: "",
      email: "",
      
      password: "",
      confirmPassword: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
 
    const [error, setError] = useState("");
 const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };

      const isFormValid = () => {
       
        return formData?.password && 
        formData?.confirmPassword
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
      };
 
  
    const checkPasswordStrength = (password) => {
      const passwordRegex = /^.{8,}$/;
      return passwordRegex.test(password);
    };
    
    const handleLogin = (e)=> {
        e.preventDefault();
        const validationErrors = {};

        if (!formData.confirmPassword) {
          validationErrors.confirmPassword = 'Please confirm password';
        } else if (formData.password !== formData.confirmPassword) {
          validationErrors.confirmPassword = 'Passwords do not match';
        }
    
        if (!checkPasswordStrength(formData.password)) {
          validationErrors.password = 'Password must have at least 8 characters';
        }
      
      
        setError(validationErrors);

        if (Object.keys(validationErrors).length === 0 ) {
          const body = {
          
            
            password: formData.password,
           
          }
        }
  
    }


 
    
   
  return (
    <> 
    
             
           <section className=''>
            <Card style={{border:"none", borderRadius:'0px'}}>
            <Card.Img src={homeBg} alt='bg' style={{ opacity: '', width:'100%', maxHeight: '750px', objectFit: 'cover' }} />

            <Card.ImgOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}} className='r'>
              <div style={{width:'45rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white', marginTop:'80px'}} className=' mx-auto p-5'>
           

<div className='text-center'><Image fluid src={logo} style={{width:'75px'}} />
</div>

              <h5 className='mt-2 mb-4' style={{color:'#000000',fontWeight:'400'}}>Thank you for accepting the invitation to collaborate. To get started, complete your invite acceptance by setting a unique password for you account.</h5>

                 
              
                   <Form onSubmit={handleLogin}>
                    <Row>
                      <Col xs={6} className='mb-4'>
                      <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={formData?.email}
                     
                       disabled
                     />
                       </Form.Group>
                      
                      </Col>
                      <Col xs={6} className='mb-4'>
                      <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Role</Form.Label>
                       <Form.Control
                       type="role"
                       name="role"
                       id="role"
                       className=""
                       placeholder="role"
                       value='Admin'
                     
                       disabled
                     />
                       </Form.Group>
                      
                      </Col>
                      <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder="Min. of 8 characters"
                    className=""
                    value={formData.password}
                    onChange={handleChange}
                    
                  />
                  {error.password && <div className="text-danger" style={{fontSize:'12px'}}>{error.password}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                    <Form.Group className='mb-2'>
                    <Form.Label>Confirm Password</Form.Label>
                    <div className='position-relative'>
                 <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
      
    >
      {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
    </button> 
    <Form.Control
                       type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="••••••••"
                    className=""
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    
                  />
                  {error.confirmPassword && <div className="text-danger" style={{fontSize:'12px'}}>{error.confirmPassword}</div>}
                  </div>
                 
                    </Form.Group>
                    </Col>
                  </Row>
                   
                
                     
                  <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
                     
                   </Form>
               
                   </div>
            </Card.ImgOverlay>
          
                
                

                  </Card> 
                  
               </section>
          
                  
     </>
    
 
       
   
  )
}

export default AcceptAdminInvite
