import React from 'react';
import AdminSidebar from "../../components/Navbar/AdminSidebar";
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="main-content">
        <Outlet />  
      </div>
    </div>
  );
};

export default AdminLayout;
