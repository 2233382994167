import React, {useState} from 'react'
import { Button, Modal,Form } from 'react-bootstrap'
import errorIcon from '../../assets/error-triangle.svg'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'
import productRoute from '../../services/productRoute'
import adminRoute from '../../services/adminRoute'

const InstructionModal = ({showInstructionModal, setShowInstructionModal}) => {
    const { showToast } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [newInstructionName, setNewInstructionName] = useState('');
  const [newInstructionAmount, setNewInstructionAmount] = useState('');
  const {addShoppingInstruction}=productRoute()
  const navigate = useNavigate();
  const handleAddInstruction = ()=> {
  
    const body = {
        name: newInstructionName,
        amount: parseFloat(newInstructionAmount), // Ensure amount is treated as a number
      };
   
  addShoppingInstruction(
        body,setShowInstructionModal, setLoading, showToast,
        
       )
  }
  



return (
  <Modal show={showInstructionModal} onHide={() => setShowInstructionModal(false)} centered>
      <Modal.Header closeButton></Modal.Header>
      {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):(<>
        <Modal.Body className='px-5 pb-5'>
        
        <Form>
            <Form.Group controlId="instructionName">
              <Form.Label>Instruction Name</Form.Label>
              <Form.Control
                type="text"
                value={newInstructionName}
                onChange={e => setNewInstructionName(e.target.value)}
                placeholder="Enter instruction name"
              />
            </Form.Group>
            <Form.Group controlId="instructionAmount" className="mt-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={newInstructionAmount}
                onChange={(event) => {
                    const numericValue = event.target.value.replace(/\D/g, "");
                    setNewInstructionAmount(numericValue )
                  }}
                placeholder="Enter amount"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInstructionModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddInstruction}>
            Add Instruction
          </Button>
        </Modal.Footer>
        </>)}
          </Modal>
)
}

export default InstructionModal
