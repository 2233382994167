import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'

const SuccessModal = ({modalText}) => {
    const {showSuccessModal, setShowSuccessModal}= useAppContext()
    const navigate = useNavigate();
 
 

  const onHide = ()=>{
    setShowSuccessModal(false);
    navigate(-1);
  
  }

  return (
    <Modal show={showSuccessModal} onHide={onHide} centered>
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body className='d-flex flex-column justify-content-center text-center gap-3 px-5 pb-5'>
            <div><img src={successIcon} alt='success' style={{width:'70px', height:'70px'}}/></div>
            
           
           
            <h3 style={{color:'#00000', fontWeight:'600'}}>{modalText}</h3>
          
            
            <Button onClick={()=>navigate(-1)}>Continue</Button>
           
            
           

          </Modal.Body>
          
            </Modal>
  )
}

export default SuccessModal
