import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate} from 'react-router-dom'
import { Modal, Form, Button, Card, CloseButton } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; 
import productRoute from '../../services/productRoute';
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import edit from '../../assets/edit.svg'
import Select from 'react-select';

const AddCategory = () => {
    const navigate = useNavigate();
    const { showToast } = useAppContext();
    const { addProduct,createCategories } = productRoute();
    
    const [loading, setLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([{
        categoryName:'',
        categoryImage:null,
    }])
   

    const handleAddCategory = () => {
        const newCategoryData = {
            categoryName:'',
            categoryImage:null,
        };
  
        setCategoryData([...categoryData, newCategoryData]);
        
      };
    
      
    const handleRemoveCategory = (index) => {
        const updateCategoryData = [...categoryData];
        updateCategoryData.splice(index, 1);
        setCategoryData(updateCategoryData);
       
      };
  
      const handleChange = (index, event) => {
        const { name, type, checked, value } = event.target;
        const isCheckbox = type === 'checkbox';
      
        const updateCategoryData = categoryData.map((item, idx) =>
          idx === index ? { ...item, [name]: isCheckbox ? checked : value } : item
        );
      
        setCategoryData(updateCategoryData);
      };
  
    const handleBack = ()=> {
        navigate(-1)
    }

   
    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 10485760) {
          const updatedCategoryData = categoryData.map((item, idx) =>
            idx === index ? { ...item, categoryImage: file } : item
          );
          setCategoryData(updatedCategoryData);
        } else {
          showToast({
            toastText: 'Invalid file. Please upload a non-SVG image file up to 10MB.',
            isSuccess: false,
          });
        }
      };
    
      const handleDeleteImage = (index) => {
        const updatedCategoryData = categoryData.map((item, idx) =>
          idx === index ? { ...item, categoryImage: null } : item
        );
        setCategoryData(updatedCategoryData);
      };
    
      const isFormValid = () => {
        for (let category of categoryData) {
          if (!category.categoryName || !category.categoryImage) {
            return false;
          }
        }
        return true;
      };
    
      const handleSave = (e) => {
        e.preventDefault();
      
        
        const formData = new FormData();
      
      
        const categories = categoryData.map((category) => ({
          categoryName: category.categoryName,
        }));
     
        formData.append('categories', JSON.stringify(categories));
      
        categoryData.forEach((category) => {
          if (category.categoryImage) {
            formData.append('categoryImages', category.categoryImage);
          }
        });
     
      
      
        createCategories(formData, setLoading, showToast);
      };
      
    
 
 
  return (
    <div className=''>
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'>Add Category</h3>
        </div>

        <div className=' pe-5 ps-4 '>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        <Form onSubmit={handleSave}>
       
       {categoryData.map((category, index) => (
   <Card className='border-0 px-4 pt-4 mb-4' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px', maxWidth:'40rem'}} key={index}>
   {categoryData.length > 1 && <div className='d-flex justify-content-end mb-4'><CloseButton onClick={() => handleRemoveCategory(index)}/></div>}
   <Form.Group>
   <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Category Name <sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='categoryName' value={category.categoryName} onChange={(e) => handleChange(index, e)}  required/>
   </Form.Group>
              
                
              
               

   <div className="my-4">
                  {category.categoryImage ? (
                    <>
                      <Button className="mb-4">Attached Image</Button>
                      <div className="d-flex gap-3">
                        <img
                          src={URL.createObjectURL(category.categoryImage)}
                          onLoad={() => URL.revokeObjectURL(category.categoryImage)}
                          alt="Category"
                          style={{ width: '100px', height: '100px' }}
                        />
                        <div className="d-flex gap-2 align-items-end">
                          {/* <img src={edit} onClick={openImageEditor} style={{ width: '24px', height: '24px', cursor: 'pointer',}} /> */}
                          <img
                            src={deleteIcon}
                            onClick={() => handleDeleteImage(index)}
                            style={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer',
                            }}
                            alt="Delete Icon"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Form.Control
                        type="file"
                        onChange={(e) => handleImageChange(index, e)}
                        accept="image/*"
                        style={{ display: 'none' }}
                        id={`fileInput-${index}`} // Unique ID for each input
                      />
                      <Button
                        variant="secondary"
                        onClick={() =>
                          document.getElementById(`fileInput-${index}`).click()
                        }
                        style={{
                          backgroundColor: '#CCCDCF',
                          border:'1px solid #333333',
                          borderStyle: 'dashed',
                          borderRadius: '4px',
                        }}
                        className="px-4"
                      >
                        Upload Category Image<sup className="text-danger">*</sup>
                      </Button>
                    </>
                  )}
                </div>
                       
        </Card>
       
    ))}

<div className='d-flex gap-3 mb-3' onClick={handleAddCategory}><i className='bi bi-plus' style={{color:'#469B12'}}></i><p style={{color:'#469B12', fontWeight:'500', textDecoration:"underline"}}>Add another category</p></div>
              <div className=' d-flex justify-content-end'>
<Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
</div>

        </Form>
        }

       
      
    
      </div>
    </div>
  )
}

export default AddCategory
