import React, {useState, useEffect} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { Modal, Nav, Form, Button, Image, Row, Col, Pagination, Table } from 'react-bootstrap';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import deleteIcon from '../../assets/delete.svg'
import edit from '../../assets/edit.svg'
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import Select, { components }  from 'react-select';
import InstructionModal from '../../components/Modal/InstructionModal';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initial } from 'lodash';

const ProductDetails = () => {
    const { productId } = useParams();
    const { showToast } = useAppContext();
    const navigate = useNavigate();
    // const location = useLocation(); 
    const { state } = useLocation();
    const [initialProductData, setInitialProductData] = useState({
      productName:'',
      productDescription:'',
      productQuantity:'',
      seasonStatus:'',
      productPrice:'',
      productCategory:[],
      shoppingInstructions:[],
      productStockLevel:'',
      stockLevelAlert:'',
      productImage:null,
      dateAdded:'',
      quantitySold:'',
  })
    const [productData, setProductData] = useState({
        productName:'',
        productDescription:'',
        productQuantity:'',
        seasonStatus:'',
        productPrice:'',
        productCategory:[],
        shoppingInstructions:[],
        productStockLevel:'',
        stockLevelAlert:'',
        productImage:null,
        dateAdded:'',
        quantitySold:'',
    })
    const [showInstructionModal, setShowInstructionModal] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [instructions, setInstructions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isEditing, setIsEditing] = useState(state?.isEditing || false);
    const { getSingleProduct,editProduct, getProductCategory,getShoppingInstructions } = productRoute();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getSingleProduct(
            productId,
            setLoading,
            showToast,
            setInitialProductData,
            setProductData,
        );
      
    }, []);
  

    useEffect(() => {
     getProductCategory(
        setLoading, showToast, setCategories
      )
    }, []); 

    useEffect(() => {
    

      getShoppingInstructions(
        setLoading, showToast, setInstructions
       
      )
  
      
    }, []); 

   const isFormValid = () => {
       
        return productData?.productName && 
        productData?.productQuantity &&
        productData?.productPrice &&
        productData?.productCategory.length > 0 && 
        productData?.productImage 
        // && ( productData?.productName !== initialProductData?.productName || productData?.productQuantity !== initialProductData?.productQuantity || productData?.productPrice !== initialProductData?.productPrice || productData?.productImage !== initialProductData?.productImage || productData?.productCategory !== initialProductData?.productCategory)
      };

      const handleBack = () => {
        // const previousPath = location.state?.previousPath;
        // const currentPage = location.state?.currentPage;
        
         // Add this line for debugging

        if (state && state.previousPath === '/admin/products') {
          const previousPath = state.previousPath;
          const currentPage = state.currentPage;
            navigate('/admin/products', { state: { currentPage } });
        } else {
            navigate(-1);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
      };
     
      const modules =  {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
    
            [{'list': 'ordered'}, {'list': 'bullet'}], // lists
            [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}], // indent
    
          
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
            [{'color': []}, {'background': []}], // dropdown with defaults
            [{'font': []}],
            [{'align': []}],
    
            ['clean'], // remove formatting
            ['link', 'image', 'video'] // link and image, video
        ]
    } 
 
    const stripHtml = (html) => {
        // Create a new div element
        const temporalDivElement = document.createElement("div");
        // Set the HTML content with the provided
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (i.e., the text content)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData((prev) => ({ ...prev, [name]: value }));
      };
      
    const handleDescriptionChange = (value) => {

      setProductData(prev => ({ ...prev, productDescription: value }));
  };
 
  
   
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/') && file.size <= 10485760 && file.type !== 'image/svg+xml') {
        const imageUrl = URL.createObjectURL(file);
        setProductData(prev => ({ ...prev, productImage: file }));
        // setEditorOpen(true)

    } else {
        showToast({toastText: 'Invalid file. Please upload a non-SVG image file up to 10MB.', isSuccess: false});
    }
};
  
// const openImageEditor = () => {
//   setEditorOpen(true);
// };

// const closeImageEditor = () => {
//   setEditorOpen(false);
// };

// const onImageSave = (editResult) => {
//   // Check if the editResult object has the property imageBase64
//   if (editResult && editResult.imageBase64) {
//       // Convert the data URL to a file object
//       const imageFile = dataURLtoFile(editResult.imageBase64, `${editResult.name}.${editResult.extension}`);
//       setProductData(prev => ({ ...prev, productImage: imageFile }));
//       closeImageEditor();
//   } else {
//       console.error('Edit result does not have imageBase64 property:', editResult);
//   }
// };



// function dataURLtoFile(dataurl, filename) {
//   let arr = dataurl.split(','),
//       mime = arr[0].match(/:(.*?);/)[1],
//       bstr = atob(arr[1]),
//       n = bstr.length,
//       u8arr = new Uint8Array(n);

//   while (n--) {
//       u8arr[n] = bstr.charCodeAt(n);
//   }

//   let blob = new Blob([u8arr], {type: mime});
//   return new File([blob], filename, {type: mime});
// }
  const handleDeleteImage = () => {
      setProductData(prev => ({ ...prev, productImage: null }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsEditing(false);

    if (productData === initialProductData){
      return;
    }
    const plainTextDescription = stripHtml(productData.productDescription);
    if(productData?.productDescription !== '' && (plainTextDescription.length < 8 || plainTextDescription.length > 400)) {
        showToast({ toastText: "Product description must be between 8 and 400 characters.", isSuccess: false });
        return;
    }
    setProductData(prevProductData => ({
        ...prevProductData,
        productDescription: plainTextDescription
    }));


    const categoryIds = categories
    .filter(cat => productData.productCategory.includes(cat.categoryName))
    .map(cat => cat._id);

    const shoppingInstructionsIds = instructions
    .filter(instruction => productData.shoppingInstructions.includes(instruction.name))
    .map(instruction => instruction.id);

  
    const formData = new FormData();
    if (productData.productName !== initialProductData.productName) {
        formData.append('productName', productData.productName);
    }
    if (productData.productQuantity !== initialProductData.productQuantity) {
        formData.append('quantityAvailable', productData.productQuantity);
    }
    if (productData.productQuantity !== 0) {
      formData.append('stockLevelAlert', productData.stockLevelAlert);}
    else if (productData.stockLevelAlert !== initialProductData.stockLevelAlert) {
      formData.append('stockLevelAlert', productData.stockLevelAlert);
  }
    if (productData.productPrice !== initialProductData.productPrice) {
        formData.append('price', productData.productPrice);
    }
    // if (categoryId && productData.productCategory !== initialProductData.productCategory) {
    //     formData.append('category', categoryId);
    // }

    if (categoryIds.length && productData.productCategory !== initialProductData.productCategory) {
      // formData.append('categories', JSON.stringify(categoryIds));
      categoryIds.forEach((categoryId) => {
        formData.append('categories', categoryId);
      });
    }
  
    if (shoppingInstructionsIds.length > 0) {
     
      shoppingInstructionsIds.forEach((shoppingInstructionsId,index) => {
        formData.append(`shoppingInstructions[${index}]`, shoppingInstructionsId);
      });
    }
    if (productData.productImage !== initialProductData.productImage) {
        formData.append('productImage', productData.productImage);
    }
    if (productData.seasonStatus !== initialProductData.seasonStatus) {
        const inSeason = productData.seasonStatus === 'In Season';
        formData.append('inSeason', inSeason);
    }
    if (productData.productDescription !== initialProductData.productDescription) {
        formData.append('description', plainTextDescription);
    }

    const contentType = productData.productImage !== initialProductData.productImage ? "multipart/form-data" : "application/x-www-form-urlencoded";
    editProduct(productId, formData, setLoading, showToast, contentType, state);
};
  
const options = instructions.map(instruction => ({
  value: instruction?.name,
  label: instruction?.name,
}));

// Custom MenuList to add "Add Shopping Instruction"
const CustomMenuList = props => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <div
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          borderTop: '1px solid #ddd',
          color: '#469B12',
        }}
        onClick={() => setShowInstructionModal(true)}
      >
      <FontAwesomeIcon icon={faPlus} />   Add Shopping Instruction
      </div>
    </components.MenuList>
  );
};

  return (
    <div className='pe-3'>
        
        <div className='d-flex gap-3 align-items-center header-admin  py-5 pe-5 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-3'> Product Details</h3>
        {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-3'>Edit</p>
      )}
        </div>
        <div className=' pe-5 ps-4'>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        
        <Form onSubmit={handleSave}>
            <Row className=''>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Product Name:</p>
                <Form.Control type='text' name='productName' value={productData.productName} onChange={handleChange} required disabled={!isEditing}/>
                </Col>
              
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Category:</p>
                {/* <Form.Select type='text' name='productCategory' value={productData.productCategory} onChange={handleChange}  disabled={!isEditing}>
                <option value="" disabled>Select category</option>
    {categories.map((category,index) => (
        <option value={category?.categoryName} key={index}>{category?.categoryName}</option>
    ))}
    

                </Form.Select> */}
                <Select
        isMulti
        value={categories.filter(category => productData.productCategory.includes(category.categoryName)).map(category => ({ label: category.categoryName, value: category.categoryName }))}
        onChange={(selectedOptions) => {
            setProductData(prev => ({
                ...prev,
                productCategory: selectedOptions.map(option => option.value)
            }));
        }}
        options={categories
          .filter(category => category.status === 'active') // Filter only active categories
          .map((category) => ({
              value: category.categoryName,
              label: category.categoryName,
          }))}
        isDisabled={!isEditing}
    />
                
                </Col>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Price:</p>
                <Form.Control type='text' name='productPrice' value={productData.productPrice} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, productPrice: numericValue })
                }} required disabled={!isEditing}/>
                </Col>
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Quantity:</p>
                <Form.Control type='text' name='productQuantity' value={productData.productQuantity} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, productQuantity: numericValue })}} required disabled={!isEditing}/>
                
                </Col>
                {!isEditing && 
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Stock Level:</p>
                <Form.Control type='text' name='productStockLevel' value={productData.productStockLevel} disabled/>
                
                </Col>
}
                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Stock Level Alert:</p>
                <Form.Control type='text' name='stockLevelAlert' value={productData.stockLevelAlert} onChange={(event) => {
                  const numericValue = event.target.value.replace(/\D/g, "");
                  setProductData({ ...productData, stockLevelAlert: numericValue })}}  disabled={!isEditing}/>
                
                </Col>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Season Status:</p>
                <Form.Select type='text' name='seasonStatus' value={productData.seasonStatus} onChange={handleChange} disabled={!isEditing}>
                <option value="" >Select status</option>
    
                <option value='In Season'>In Season</option>
    <option value='Off Season'>Off Season</option>
  
                </Form.Select>
                
                </Col>

                <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Shopping Instructions:</p>
               
                <Select
        isMulti
        value={instructions.filter(instruction => productData.shoppingInstructions.includes(instruction.name)).map(instruction => ({ label: instruction.name, value: instruction.name }))}
        options={options}
        onChange={selectedOptions => {
          const selectedValues = selectedOptions.map(option => option.value);
          setProductData(prev => ({
            ...prev,
            shoppingInstructions: selectedValues,
          }));
        }}
        noOptionsMessage={() => "No instructions available"}
        styles={{
          menu: provided => ({
            ...provided,
            zIndex: 2000, // Ensure the dropdown has a high z-index
          }),
          menuList: provided => ({
            ...provided,
            zIndex: 2000, // Ensure the dropdown options have a high z-index
          }),
        }}
        components={{
          MenuList: CustomMenuList,
        }}
        isDisabled={!isEditing}
    />
                
                </Col>
                {!isEditing && <>
                  <Col xs={6} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Date Added</p>
                <Form.Control type='text'  value={productData?.dateAdded?.split('T')[0]} disabled/>
                </Col>
                <Col xs={6} className='pe-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Quantity Sold:</p>
                <Form.Control type='text' value={productData?.quantitySold}  disabled/>
                </Col></>}


               
                <Col xs={12} className='ps-3 mb-4'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Product Description:</p>
                {isEditing ?   <ReactQuill value={productData.productDescription}  onChange={handleDescriptionChange} modules={modules} /> : <div className='p-3' style={{border:'1px solid #E7E7E7',height:'200px'}}>{productData.productDescription} </div> }
               
                
                </Col>

            </Row> 

            <div className='my-4'>{productData.productImage ? (
            <>
            <Button className='mb-4'>Attached Image</Button>
                            <div className="d-flex gap-2">
                            <img
    src={typeof productData.productImage === 'string' ? productData.productImage : URL.createObjectURL(productData.productImage)}
    alt="Product"
    style={{ width: '100px', height: '100px' }}
/>
{isEditing && <div className='d-flex align-items-end'>
  {/* <img src={edit} onClick={openImageEditor} style={{ width: '24px', height: '24px', cursor: 'pointer',}} />  */}
<img src={deleteIcon} onClick={handleDeleteImage} style={{ width: '24px', height: '24px', cursor: 'pointer',marginLeft:'15px'}} /></div>}

                                
                            
                            </div>
                            </>
                        ) : (<>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="fileInput"
                            />
                            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()} style={{ backgroundColor: '#CCCDCF', border:'none', borderRadius:'4px',  }} className='px-4'>
                            Upload Image
                        </Button></>
                        )}
                        </div>

                        {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}

        </Form>
        
        }
 {/* {editorOpen && productData.productImage && (

     
        <Modal show={editorOpen} onHide={closeImageEditor} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilerobotImageEditor
                        source={URL.createObjectURL(productData.productImage)}
                        onSave={onImageSave}
                        onClose={closeImageEditor}
                    />
                </Modal.Body>
            </Modal>
        )} */}

    </div>
    <InstructionModal showInstructionModal={showInstructionModal} setShowInstructionModal={setShowInstructionModal}/>
    </div>
  )
}

export default ProductDetails