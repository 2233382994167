import React, {useState} from 'react'
import { Button, Form, Modal, Card } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/delete.svg'

const InviteAdminModal = ({showInviteModal, setShowInviteModal}) => {
    const {showToast}= useAppContext();
    const [loading, setLoading] = useState(false);
    const [adminDetails, setAdminDetails] = useState([{
      email: '',
      role: '',
      permissions: '',
    
     
    }]);
    const navigate = useNavigate();


    const handleAddMore = () => {
      const newAdminDetail = {
        email: '',
        role: '',
        permissions: '',
      };

      setAdminDetails([...adminDetails, newAdminDetail]);
    };

    const handleRemoveAdmin = (index) => {
      const updatedAdminDetails = [...adminDetails];
      updatedAdminDetails.splice(index, 1);
      setAdminDetails(updatedAdminDetails);
    };

    const handleChange = (index, event) => {
      const { name, type, checked, value } = event.target;
      const isCheckbox = type === 'checkbox';
    
      const updatedAdminDetails = adminDetails.map((item, idx) =>
        idx === index ? { ...item, [name]: isCheckbox ? checked : value } : item
      );
    
      setAdminDetails(updatedAdminDetails);
    };

    const isFormValid = () => {
      for (let admin of adminDetails) {
      
        if (!admin.email || !admin.role
           ) {
          return false; 
        }
      }
      return true; 
    };
    
  const handleProceed = (e) => {
    e.preventDefault();

    
    setShowInviteModal(false);
 
   
  };

  const onHide = ()=>{
    setShowInviteModal(false)
  
  }

  return (
    <Modal show={showInviteModal} onHide={onHide} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body className=' px-5 pb-5'>
           
            
            <div className='mb-5'>
           
            <h3 style={{color:'#00000', fontWeight:'700'}}>Invite a New Admin User</h3>
            <h6 style={{color:'#626262', fontWeight:'500'}} className='mt-3'>Invite new admin users to collaborate on your dashboard. Select their user groups and also set their user permissions.</h6>
            </div>
            <Form onSubmit={handleProceed}>
            {adminDetails.map((adminDetail, index) => (
  <Card className='border-0 mb-3 p-3' style={{ boxShadow: '0px 1px 2px 0px #0000001A', borderRadius: '8px' }} key={index}>
    <div className='d-flex gap-3'>
      <Form.Group>
        <p style={{ color: '#333333', fontWeight: '500' }} className='pb-0 mb-1'>Email Address</p>
        <Form.Control
          type='email'
          name='email'
          value={adminDetail.email}
          onChange={(e) => handleChange(index, e)}
          placeholder='Enter Staff Email'
          required
        />
      </Form.Group>
      <Form.Group>
        <p style={{ color: '#333333', fontWeight: '500' }} className='pb-0 mb-1'>Role</p>
        <Form.Select
          className="custom-select"
          onChange={(e) => handleChange(index, e)}
          value={adminDetail.role}
          name="role"
        >
          <option value="" disabled>Select a role</option>
       
          <option value='Admin'>Admin</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <p style={{ color: '#333333', fontWeight: '500' }} className='pb-0 mb-1'>Extra Permissions</p>
        <Form.Select
          className="custom-select"
          onChange={(e) => handleChange(index, e)}
          value={adminDetail.permissions}
          name="permissions"
          disabled
        >
          <option value="" disabled>Select additional permissions</option>
        </Form.Select>
      </Form.Group>
      {adminDetails.length > 1 && (
        <div className='d-flex align-items-end'>
          <img
            src={deleteIcon}
            onClick={() => handleRemoveAdmin(index)}
            style={{ width: '20px' }}
            alt='Delete Icon'
          />
        </div>
      )}
    </div>
  </Card>
))}

              <div className='d-flex gap-3 mb-3' onClick={handleAddMore}><i className='bi bi-plus' style={{color:'#469B12'}}></i><p style={{color:'#469B12', fontWeight:'500', textDecoration:"underline"}}>Add More</p></div>
              <div className=' d-flex justify-content-end'>
<Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Proceed</Button>
</div>
            </Form>
          
          
           

          </Modal.Body>
          
            </Modal>
  )
}

export default InviteAdminModal
