import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, CloseButton } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import deleteIcon from '../../assets/delete.svg'
import { FaTrash } from 'react-icons/fa'; // For delete icon
import Select from 'react-select';
import Waitlist from '../../components/Modal/Waitlist';
import { FaRegCalendarAlt } from 'react-icons/fa'
import productRoute from '../../services/productRoute';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import search from '../../assets/search.svg'

export  const supportedLGAs = ["Ifako-Ijaiye", "Eti-Osa", "Lagos Mainland", "Lagos Island", "Agege", "Somolu", "Ikeja", "Surulere", "Mushin", "Kosofe", "Magboro"];

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const initialLgaOptions = []; 

const CreateOrder = () => {
    const navigate = useNavigate();
    const { showToast } = useAppContext();
    const [deliveryStates, setDeliveryStates] = useState([]);
    const [orderDetails, setOrderDetails] = useState([{
      customerName: '',
      phoneNumber: '',
      email: '',
      items: [],
      deliveryType: '',
      deliveryDate: '',
      streetAddress: '',
      state: '',
      LGA: '',
      paymentType: '',
      lgaOptions:[],
      originalLgaOptions:[],
    }]);

    const [selectedItems, setSelectedItems] = useState([]);
    const popoverRefs = useRef([]);

    const [selectOptions, setSelectOptions] = useState([]);
    const [error, setError] = useState("");
  
    const [searchProduct, setSearchProduct] = useState('');
const [showWaitlist, setShowWaitlist] = useState(false);
const [globalProducts, setGlobalProducts] = useState([]);
const [searchPerformed, setSearchPerformed] = useState(false);
const [loading, setLoading] = useState(false);
const [isFocused, setIsFocused] = useState(false);
const {getGlobalProducts}= productRoute();
const {createOrder, getOrderDeliveryLGAs,getDeliveryStates}= orderRoute();
const stateDependencies = orderDetails.map(detail => detail?.state);
const [popoverStates, setPopoverStates] = useState(orderDetails.map(() => ({ isVisible: false, searchProduct: '', loading: false })));
const searchProducts = popoverStates.map(ps => ps.searchProduct);
const debouncedGetProducts = useRef([]);
const previousSearchProducts = usePrevious(searchProducts) || [];

    const formatDate = (date) => {
      return date.toLocaleDateString('en-CA');
    };
    

    const readableDate = (date) => {
      return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
    };
    
    const calculateTuesdays = (year, month) => {
      let tuesdays = [];
      let firstDay = new Date(year, month, 1);
      // Find the first Tuesday
      let dayOfWeek = firstDay.getDay();
      let daysUntilFirstTuesday = (dayOfWeek <= 2) ? (2 - dayOfWeek) : (9 - dayOfWeek);
      let firstTuesday = new Date(year, month, 1 + daysUntilFirstTuesday);
    
      // Add up to the first 4 or 5 Tuesdays of the month
      for (let i = 0; i < 5; i++) {
        let nextTuesday = new Date(firstTuesday);
        nextTuesday.setDate(firstTuesday.getDate() + (i * 7));
        if (nextTuesday.getMonth() === month) {
          tuesdays.push(nextTuesday);
        }
      }
    
      return tuesdays;
    };
  

   
    useEffect(() => {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth(); // January is 0, December is 11
      let tuesdays = calculateTuesdays(currentYear, currentMonth);
    
      const fiveHoursLater = new Date(today.getTime() + 5 * 3600 * 1000);
    
      // Filter out past Tuesdays if within 5 hours
      tuesdays = tuesdays.filter(tuesday => tuesday >= fiveHoursLater);
    
      if (tuesdays.length < 4) {
        // Add the first Tuesday of the next month
        const nextMonth = (currentMonth + 1) % 12;
        const nextYear = (currentMonth + 1 > 11) ? currentYear + 1 : currentYear;
        const nextMonthTuesdays = calculateTuesdays(nextYear, nextMonth);
        tuesdays = tuesdays.concat(nextMonthTuesdays.slice(0, 4 - tuesdays.length));
      }
    
      // Prepare options for UI
      const options = tuesdays.slice(0, 4).map(date => ({
        value: formatDate(date),
        label: readableDate(date),
      }));
    
      setSelectOptions(options);
    }, []);
    useEffect(()=>{
      getDeliveryStates(
        showToast, setDeliveryStates 
      )
    },[])

    useEffect(() => {
      // Align popoverStates with orderDetails length
      if (popoverStates.length !== orderDetails.length) {
        setPopoverStates(orderDetails.map(() => ({ isVisible: false, searchProduct: '', loading: false })));
      }
    }, [orderDetails, popoverStates.length]);
  
    useEffect(() => {
      // Adjust the length of the refs array to match the number of orderDetails
      popoverRefs.current = orderDetails.map((_, index) => 
        popoverRefs.current[index] || React.createRef()
      );
    }, [orderDetails.length]);

//   useEffect(() => {
   
//     getGlobalProducts(setLoading, showToast, setGlobalProducts);
// }, []);  

  
  // useEffect(() => {
  //   const updatedOrderDetails = orderDetails.map((order) => {
  //     let updatedLgas = [];
  //     if (order.state === "Ogun") {
  //       updatedLgas = ["Magboro"];
  //     } else if (order.state === "Lagos") {
  //       updatedLgas = supportedLGAs.filter(lga => lga !== "Magboro");
  //     }
  
  //     const newLgaOptions = updatedLgas.map(lga => ({
  //       value: lga.replace(/\s+/g, '-').toLowerCase(),
  //       label: lga
  //     }));
  
  //     // Return the updated detail object with new LGA options
  //     return { ...order, lgaOptions: newLgaOptions };
  //   });
  
  //   // Now, update the state with all changes at once.
  //   setOrderDetails(updatedOrderDetails);
  // }, [JSON.stringify(stateDependencies)]); 
  
 
  useEffect(() => {
    // Function to fetch LGAs for each order's state
    const fetchLGAsForOrders = () => {
      orderDetails.forEach((order, index) => {
        const selectedState = deliveryStates.find(state => state?.name === order?.state);
        if (selectedState) {
          getOrderDeliveryLGAs(selectedState.id, showToast, orderDetails, setOrderDetails, index);
        }
      });
    };
    fetchLGAsForOrders();
  }, [deliveryStates, JSON.stringify(stateDependencies)]);
  

  
  
 
  
  useEffect(() => {
    // Re-initialize the debounced functions if the number of popovers changes
    debouncedGetProducts.current = popoverStates.map((_, index) => debounce((searchProduct) => {
    
        getGlobalProducts(setLoading, showToast, setGlobalProducts, searchProduct);
    }, 500));

    return () => {
        // Cleanup by canceling all debounced calls
        debouncedGetProducts.current.forEach(fn => fn.cancel());
    };
}, [popoverStates.length]);



useEffect(() => {
  // Only call API when there's an actual change in the search term
  searchProducts.forEach((searchProduct, index) => {
    if (searchProduct !== previousSearchProducts[index]) {
      if (searchProduct !== '') {
          debouncedGetProducts.current[index](searchProduct);
      }
      else{
        getGlobalProducts(setLoading, showToast, setGlobalProducts);
      }
    }
  });
}, [searchProducts]);

const handleAddOrder = () => {
  const newOrderDetail = {
    customerName: '',
    phoneNumber: '',
    email: '',
    items: [],
    deliveryType: '',
    deliveryDate: '',
    streetAddress: '',
    state: '',
    LGA: '',
    paymentType: '',
    lgaOptions: [],
    originalLgaOptions:[],
  };

  setOrderDetails(prevOrderDetails => {
    const updatedOrderDetails = [...prevOrderDetails, newOrderDetail];
    const selectedState = deliveryStates.find(state => state.name === newOrderDetail.state);
    if (selectedState) {
      getOrderDeliveryLGAs(selectedState.id, showToast, setOrderDetails);
    }
    return updatedOrderDetails;
  });

  setPopoverStates(states => [...states, { isVisible: false, searchProduct: '', loading: false }]);
};


  
  // const handleAddOrder = () => {
  //   const newOrderDetail = {
  //     customerName: '',
  //     phoneNumber: '',
  //     email: '',
  //     items: [],
  //     deliveryType: '',
  //     deliveryDate: '',
  //     streetAddress: '',
  //     state: '',
  //     LGA: '',
  //     paymentType: '',
  //     lgaOptions: []
  //   };
    
  //   let initialLgas = [];
  //   if (newOrderDetail.state === "Ogun") {
  //     initialLgas = ["Magboro"];
  //   } else if (newOrderDetail.state === "Lagos") {
  //     initialLgas = supportedLGAs.filter(lga => lga !== "Magboro");
  //   }
  
  //   newOrderDetail.lgaOptions = initialLgas.map(lga => ({
  //     value: lga.replace(/\s+/g, '-').toLowerCase(),
  //     label: lga
  //   }));
  
  //   setOrderDetails([...orderDetails, newOrderDetail]);
  //   setPopoverStates(states => [...states, { isVisible: false, searchProduct: '', loading: false }]);
  // };
  

  const handleSearchChange = (searchProduct, index) => {
    // Update the search product in the corresponding popover state
    setPopoverStates(current =>
      current.map((item, idx) => idx === index ? { ...item, searchProduct } : item)
    );
  };

  const handleRemoveOrder = (index) => {
    const updatedOrderDetails = [...orderDetails];
    updatedOrderDetails.splice(index, 1);
    setOrderDetails(updatedOrderDetails);
  };


  const handleChange = (index, event) => {
    const { name, type, checked, value } = event.target;
    const isCheckbox = type === 'checkbox';

    // Update the specific order detail
    const updatedOrderDetails = orderDetails.map((item, idx) =>
        idx === index ? { ...item, [name]: isCheckbox ? checked : value } : item
    );

    // Set the updated order details back to state
    setOrderDetails(updatedOrderDetails);

    // If the state field is changed, update the corresponding LGA options
    if (name === "state") {
      const orderDetailsWithClearedStreet = updatedOrderDetails.map((item, idx) =>
      idx === index ? { ...item, streetAddress: '' } : item
  );

  setOrderDetails(orderDetailsWithClearedStreet);
        const selectedState = deliveryStates.find((state) => state.name === value);
        if (selectedState) {
            getOrderDeliveryLGAs(selectedState.id, showToast, updatedOrderDetails, setOrderDetails, index);
        }
    }
};


  
  const handleInputChange = (index, inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const currentOrderDetail = orderDetails[index];
      const filteredLgas = currentOrderDetail.originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
      
      let newLgaOptions;
      if (filteredLgas.length === 0 && inputValue.trim() !== "") {
        newLgaOptions = [{
          value: "comingSoon",
          label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.`
        }];
      } else {
        newLgaOptions = filteredLgas;
      }
      
      const updatedOrderDetails = orderDetails.map((detail, idx) =>
        idx === index ? { ...detail, lgaOptions: newLgaOptions } : detail
      );
      
      setOrderDetails(updatedOrderDetails);
    }
  };

const handleSelectChange = (index, selectedOption) => {
  setOrderDetails(prevOrderDetails =>
    prevOrderDetails.map((detail, idx) => {
      if (idx === index) {
        let newDetail = {
          ...detail,
          LGA: selectedOption ? (selectedOption.value !== "comingSoon" ? selectedOption.value : '') : ''
        };

        if (selectedOption?.value === "comingSoon") {
          // Show the modal for "coming soon"
          setShowWaitlist(true);

          // Update LGA options dynamically
          const selectedState = deliveryStates.find(state => state.name === newDetail.state);
          if (selectedState) {
            getOrderDeliveryLGAs(selectedState.id, showToast, prevOrderDetails, setOrderDetails,index);
          } else {
            console.error(`State '${newDetail.state}' not found in deliveryStates.`);
          }
        }

        return newDetail;
      }
      return detail;
    })
  );
};

  const formatOptionLabel = ({ label, value }) => {
    if (value === "comingSoon") {
      // Split the label to insert styling on a specific part
      const firstPart = "We are coming to ";
      const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      const thirdPart = "soon! ";
      const actionPart = "Join our waitlist and be the first to know when we do.";
     
  
      return (
        <div>
          {firstPart}
          <span>{secondPart}</span>
          {thirdPart}
          <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
        
        </div>
      );
    }
    // Return the label as is for other options
    return label;
  };
  

  const isNigerianPhoneNumber = (phoneNumber) => {
    // This regex matches phone numbers starting with '070', '080', '090', '081', and '091'
    return /^0[7-9][0-1]\d{8}$/.test(phoneNumber) || /^091\d{8}$/.test(phoneNumber);
  };

  const handleTogglePopover = (index) => {
    const newStates = [...popoverStates];
    newStates[index].isVisible = !newStates[index].isVisible;
    setPopoverStates(newStates);
  };
  
 
  
    const handleBack = ()=> {
        navigate(-1)
    }

   
    const handleToggleItem = (productId, index) => {
      const product = globalProducts.find(p => p.id === productId);
      if (!product) {
        showToast({ toastText: 'Product not found.', isSuccess: false });
        return;
      }
    
      if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
        showToast({ toastText: 'This product is out of stock.', isSuccess: false });
        return;
      }
    
      const orderDetail = orderDetails[index];
      const itemIndex = orderDetail.items.findIndex(item => item.productId === productId);
    
      if (itemIndex !== -1) {
        // Item exists, remove it
        const newItems = [...orderDetail.items];
        newItems.splice(itemIndex, 1);
        const newOrderDetails = [...orderDetails];
        newOrderDetails[index] = { ...orderDetail, items: newItems };
        setOrderDetails(newOrderDetails);
      } else {
        // Item does not exist, add it
        const newItem = {
          productId: productId,
          name: product.productName,
          quantity: 1,
          price: product.price // Include price here
        };
        const newOrderDetails = [...orderDetails];
        newOrderDetails[index] = { ...orderDetail, items: [...orderDetail.items, newItem] };
        setOrderDetails(newOrderDetails);
      
      }
    };
    
    const handleChangeQuantity = (productId, delta, index) => {
      const product = globalProducts.find(p => p.id === productId);
      if (!product) {
        showToast({ toastText: 'Product not found.', isSuccess: false });
        return;
      }
    
      const orderDetail = orderDetails[index];
      const itemIndex = orderDetail.items.findIndex(item => item.productId === productId);
    
      if (itemIndex === -1) {
        showToast({ toastText: 'Item not found in order.', isSuccess: false });
        return;
      }
    
      const item = orderDetail.items[itemIndex];
      const newQuantity = item.quantity + delta;
    
      if (newQuantity <= 0) {
        // If quantity falls to zero or less, remove item
        handleToggleItem(productId, index);
        return;
      }
    
      if (newQuantity > product.quantityAvailable) {
        showToast({ toastText: `Only ${product.quantityAvailable} items in stock.`, isSuccess: false });
        return;
      }
    
      // Update quantity
      const newItems = [...orderDetail.items];
      newItems[itemIndex] = { ...item, quantity: newQuantity };
      const newOrderDetails = [...orderDetails];
      newOrderDetails[index] = { ...orderDetail, items: newItems };
      setOrderDetails(newOrderDetails);
    };
    const isFormValid = () => {
      for (let order of orderDetails) {
        // Check if any of these fields are empty; add more checks as needed
        if (!order?.customerName || !order?.phoneNumber || !order?.email ||
            order?.items.length === 0 || !order?.deliveryType ||
            !order?.deliveryDate || !order?.paymentType ||
            (order?.deliveryType === 'delivery' && (!order?.streetAddress || !order?.state || !order?.LGA))) {
          return false; // Form is not valid
        }
      }
      return true; // All checks passed, form is valid
    };
    
    const handleSave = (e) => {
      e.preventDefault();
    
      const minimumOrderAmount = 12000;
  let isAnyOrderBelowMinimum = false;

  orderDetails.forEach((detail, index) => {
    let totalPrice = detail.items.reduce((acc, item) => acc + (item.quantity * item.price), 0);

    if (totalPrice < minimumOrderAmount) {
      const amountLeftToAdd = minimumOrderAmount - totalPrice;
      showToast({
        toastText: `Add items worth at least ₦${amountLeftToAdd} to reach the ₦12,000 minimum basket amount for Order ${index + 1}.`,
        isSuccess: false
      });
      isAnyOrderBelowMinimum = true;
    }
  });

  if (isAnyOrderBelowMinimum) {
    return; // Prevent further processing if any order is below minimum
  }
    
    
      // Validation of details
      const validationErrors = orderDetails.map((detail, index) => {
        const errors = {};
    
        if (!detail.phoneNumber) {
          errors.phoneNumber = "Please enter your Phone Number";
        } else if (detail.phoneNumber.length !== 11) {
          errors.phoneNumber = "Please enter a valid 11-digit Nigerian phone number";
        }
    
        if (!detail.email) {
          errors.email = "Email is required";
        }
    
    
        return errors;
      });
    
      setError(validationErrors);
    
      if (validationErrors.some(errors => Object.keys(errors).length > 0)) {
        console.log('Validation errors found, please review.');
        return; // Stop if there are validation errors
      }
    
      // If validation passes and minimum order amount is met
      const newOrders = orderDetails.map(detail => {
        const selectedState = deliveryStates.find(state => state.name === detail.state);
    const stateId = selectedState ? selectedState.id : null;

    const selectedLGA = detail.lgaOptions.find(option => option.label === detail.LGA);
    const lgaId = selectedLGA ? selectedLGA.id : null;
    const orderBody = {
      deliveryDate: new Date(detail.deliveryDate).toISOString(),
      carts: detail.items.map(item => ({
        productId: item.productId,
        quantity: item.quantity
      })),
      phoneNumber: detail.phoneNumber,
      deliveryType: detail.deliveryType,
      deliverySchedule: "scheduled",
      paymentMethod: detail.paymentType
    };
    
    if (detail.deliveryType !== 'pickup') {
      orderBody.address = {
        streetAddress: detail?.streetAddress,
        city: lgaId,
        state: stateId
      };
    }


    return {
      ...orderBody,
      userName: detail.customerName,
      email: detail.email
    };
    
        
      });
    
   
      createOrder({ newOrders }, setLoading, showToast);
    };
    
    
    
   
  return (
    <div>
      <div className='d-flex gap-3 align-items-center  header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'>Create Order</h3>
        </div>
        <div className=' pe-5 ps-4 ' >
       
        <Form onSubmit={handleSave}>
        { orderDetails.map((orderDetail, index) => (
        <Card className='border-0 px-4 pt-4 mb-' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px'}} key={index}>
          {orderDetails.length > 1 && <div className='d-flex justify-content-end mb-4'><CloseButton onClick={() => handleRemoveOrder(index)}/></div>}
      
            <Row className=''>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Customer's Name<sup className='text-danger'>*</sup></p>
                <Form.Control type='text' name='customerName' value={orderDetail?.customerName}  onChange={(e) => handleChange(index, e)} placeholder='Enter name'  required/>
                </Col>
               
  <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Phone Number<sup className='text-danger'>*</sup></p>
    <Form.Control
      name='phoneNumber'
      type='text'
      placeholder='Enter phone number'
      value={orderDetail?.phoneNumber}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        if (numericValue.length <= 11) {
          const updatedOrderDetails = orderDetails?.map((item, idx) => 
            idx === index ? { ...item, phoneNumber: numericValue } : item
          );
          setOrderDetails(updatedOrderDetails);
        }

        // Error handling (could be handled better in context with actual data)
        if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
          setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
        } else {
          setError({...error, phoneNumber: ''});
        }
      }}
    />
    {error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}
  </Col>

                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Email address<sup className='text-danger'>*</sup></p>
                <Form.Control type='email' placeholder='Enter email' name='email' value={orderDetail?.email}  onChange={(e) => handleChange(index, e)} required/>
                {error.email && <div className="text-danger" style={{fontSize:'12px'}}>{error.email}</div>}
                </Col>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Items <sup className='text-danger'>*</sup></p>
                <div onClick={() => handleTogglePopover(index)} ref={popoverRefs.current[index]} style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px' }} className='d-flex align-items-center'>
                {orderDetail?.items?.length > 0 && (
    <i className="bi bi-plus-lg"></i>
  )}  <div className='item-container'>
        {orderDetail?.items?.length > 0 ? (
          orderDetail?.items?.map((item) => (
            <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500',fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item.productId} onClick={(e) => {
              e.stopPropagation(); // Prevent popover from toggling
              handleToggleItem(item.productId, index);
            }}>
              {item.name} {item.quantity} &nbsp; X
            </Button>
          ))
        ) : (
          <span className="text-muted">Add items</span>
        )}
        </div>
      </div>

      <Overlay
  show={popoverStates[index] && popoverStates[index].isVisible}
  target={popoverRefs.current[index]}
  placement="bottom"
  rootClose={true}
  onHide={() => handleTogglePopover(index)}
>
  {popoverStates[index] && 


    <Popover id={`popover-basic-${index}`} className='custom-popover' style={{width:'430px'}}>
      <Popover.Body>
        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
        {popoverStates[index].searchProduct !== '' && (
          <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => handleSearchChange('', index)}></i>
        )}
       <Form.Control
  style={{border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius:'3px', fontWeight:'500'}}
  placeholder='Search by product name'
  value={popoverStates[index]?.searchProduct}
 onChange={(e) => handleSearchChange(e.target.value, index)}
  autoFocus
/>

        </div>
        {loading ? (
          <div className='justify-content-center align-items-center text-center' style={{padding:'150px'}}>
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          globalProducts?.length > 0 ? (
            <>
              <div style={{borderTop:'1px solid #E1E6EF'}} className='pb-2 pt-3'>
                <h5 className='' style={{fontWeight:'500'}}>Items </h5>
              </div>
              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border:'none' }} className='no-border-listgroup'>
                {globalProducts.map(product => {
                  const selectedItem = orderDetails[index]?.items?.find(item => item?.productId === product?.id);
                  return (
                    <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                      <Form.Check 
                        type="checkbox"
                        label={`${product.productName} - ₦${product?.price.toLocaleString()}`}
                        checked={!!selectedItem}
                        onChange={() => handleToggleItem(product.id, index)}
                      />
                      {selectedItem && (
                        <div className="btn-group btn-group-sm">
                          <Button onClick={() => handleChangeQuantity(product.id, -1, index)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>-</Button>
                          <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>{selectedItem.quantity}</Button>
                          <Button onClick={() => handleChangeQuantity(product.id, 1, index)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>+</Button>
                        </div>
                      )}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </>
          ) : (
            searchPerformed && globalProducts.length === 0 ? (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4 className='mb-2'>No products found</h4>
                <Button onClick={() => setSearchProduct('')}>View all products</Button>
              </div>
            ) : (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4>You have no products</h4>
              </div>
            )
          )
        )}
      </Popover.Body>
    </Popover>
}

</Overlay>
                
                </Col>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery Type <sup className='text-danger'>*</sup></p>
                <Form.Select
        className="custom-select"
         onChange={(e) => handleChange(index, e)}
        value={orderDetail?.deliveryType}
        name="deliveryType"
        required
      > <option value="" disabled>Select delivery type</option>
    
        <option value='pickup'>In-store pickup</option>
        <option value='delivery'>Home delivery</option>

      </Form.Select>
                
                </Col>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Delivery/Pickup Date<sup className='text-danger'>*</sup></p>
                <div className="custom-select-wrapper">
      <Form.Select
        className="custom-select"
         onChange={(e) => handleChange(index, e)}
        value={orderDetail?.deliveryDate}
        name="deliveryDate"
        required 
      >   <option value="" disabled >Select a date</option>
        {selectOptions.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}

      </Form.Select>
      <FaRegCalendarAlt className="select-icon" /> {/* Calendar icon */}
    </div>
                
                </Col>

                {orderDetail?.deliveryType === 'delivery' && <>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Street Address <sup className='text-danger'>*</sup></p>
                <div className="input-group">
      <Form.Control
        type="text"
        placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
        onChange={(e) => handleChange(index, e)}
        name="streetAddress"
        value={orderDetail?.streetAddress}
      />
      {orderDetail?.streetAddress && (
        <i className='bi bi-x' onClick={() => {
          const updatedOrderDetails = orderDetails.map((item, idx) => 
            idx === index ? { ...item, streetAddress: '' } : item
          );
          setOrderDetails(updatedOrderDetails);
        }}
        style={{ cursor: 'pointer', position: 'absolute', top: '10%', right: '10px', zIndex: 100, fontSize: '1.5rem' }}></i>
      )}
    </div>   
    {error.streetAddress && <div className="text-danger" style={{fontSize:'12px'}}>{error.streetAddress}</div>}
                </Col>
               
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>State <sup className='text-danger'>*</sup></p>
                <Form.Select
        className="custom-select"
         onChange={(e) => handleChange(index, e)}
        value={orderDetail?.state}
        name="state"
        
      > <option value="" disabled>Select a state</option>
    {/* <option value="Lagos" >Lagos</option>
    <option value="Ogun" >Ogun</option> */}
    {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}

      </Form.Select>
                
                </Col>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>LGA<sup className='text-danger'>*</sup> (Please select a state first)</p>
                <Select
  formatOptionLabel={formatOptionLabel}
  options={orderDetail?.lgaOptions}  // Refer to lgaOptions within the current orderDetail
  onInputChange={(inputValue, actionMeta) => handleInputChange(index, inputValue, actionMeta)}
  onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
  value={orderDetail?.lgaOptions?.find(option => option?.value === orderDetail?.LGA) || null}
  name="LGA"
  isClearable={true}
/>
                </Col></>}
               
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Payment Type <sup className='text-danger'>*</sup></p>
                <Form.Select
        className="custom-select"
         onChange={(e) => handleChange(index, e)}
        value={orderDetail?.paymentType}
        name="paymentType"
        required
      > <option value="" disabled>Select payment type</option>
    
        
        <option value='Direct Transfer'>Direct Transfer</option>

      </Form.Select>
                
                </Col>

            </Row> 

           
        </Card>
        ))}
        <div className='d-flex gap-3 mt-3' onClick={handleAddOrder}><i className='bi bi-plus' style={{color:'#469B12'}}></i><p style={{color:'#469B12', fontWeight:'500', textDecoration:"underline"}}>Add More</p></div>

      

<div className='pt-3 my-5 d-flex justify-content-end'>
<Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
</div>

</Form>
</div>
        <Waitlist showWaitlist={showWaitlist} setShowWaitlist={setShowWaitlist}/>
    </div>
  )
}

export default CreateOrder