import React from 'react'

const AdminReports = () => {
  return (
    <div>
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
           
        <h3 style={{color:'#344054'}} className='my-auto'> Reports</h3>
       
        </div>
    </div>
  )
}

export default AdminReports
