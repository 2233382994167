import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import arrowLeft from '../../assets/arrow-left.svg'
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table } from 'react-bootstrap';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import search from '../../assets/search.svg';
import { debounce } from 'lodash';

const BulkStockUpdate = () => {
  const { showToast } = useAppContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const { getProducts,getProductCategory, filterByCategory, getGlobalProducts, filterBySeason,filterByStockLevel, bulkStockUpdate } = productRoute();
    const [globalProducts, setGlobalProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isStockOpen, setIsStockOpen] = useState(false);
    const [isSeasonOpen, setIsSeasonOpen] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: state?.currentPage || 1,
        itemsPerPage: 20,
        totalPages: 0,
        totalProducts: 0,
    });
    const [updatedStockLevel, setUpdatedStockLevel] = useState({});
    const [updatedStockAlert, setUpdatedStockAlert] = useState({});
    const [searchPerformed, setSearchPerformed] = useState(false);
    const dropdownRef2 = useRef(null);


    const debouncedFilterProducts = debounce((searchProduct) => {
      const filteredProducts = state?.productsToAction?.products.filter(product =>
          product?.productName?.toLowerCase().includes(searchProduct?.toLowerCase())
      );
      setProducts(filteredProducts);
      setLoading(false);
  }, 500); 

    useEffect(() => {
      if (state?.productsToAction?.products) {
        if (searchProduct !== '') {
          setLoading(true);
          debouncedFilterProducts(searchProduct);
      } else {
          setProducts(state?.productsToAction?.products);
      }
      } else if (state?.productsToAction?.fetchAll) {
        const debouncedGetProducts = debounce((searchProduct) => {
          getProducts(
              setLoading,
              showToast,
              setProducts,
              pagination,
              setPagination,
              searchProduct,
          );
      }, 500);
  
      // Call the debounced getProducts function when the search product changes and it's not empty
      if (searchProduct !== '') {
        setSearchPerformed(true)
          debouncedGetProducts(searchProduct);
      } else {
          // If the search product is empty, call getProducts immediately
          getProducts(
              setLoading,
              showToast,
              setProducts,
              pagination,
              setPagination,
              searchProduct,
          );
      }
  
      // Cleanup function to cancel the debounced function
      return () => {
          debouncedGetProducts.cancel();
      };
    }
    }, [state, pagination.currentPage, searchProduct]); 
    useEffect(() => {
    

      getProductCategory(
        setLoading, showToast, setCategories
       
      )
  
      
    }, []); 
    useEffect(() => {
      if (state?.productsToAction?.products) 
      {setGlobalProducts(state?.productsToAction?.products)} 
      else if (state?.productsToAction?.fetchAll) {
         getGlobalProducts(
           setLoading, showToast, setGlobalProducts, 
          
         )}
           
         }, [state]);

         useEffect(() => {
          function handleClickOutside(event) {
           
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
              setIsOpen2(false);
            }
          
          }
      
          function handleScroll() {
       
            setIsOpen2(false);
         
          }
      
          // Add event listeners
          document.addEventListener("mousedown", handleClickOutside);
          window.addEventListener("scroll", handleScroll, true);
      
          return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
          };
        }, []);

    const toggleDropdown2 = () => setIsOpen2(!isOpen2);
    const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);
    const toggleSeasonDropdown = () => setIsSeasonOpen(!isSeasonOpen);
    const toggleStockDropdown = () => setIsStockOpen(!isStockOpen);
    const handlePageChange = (page) => {
      setPagination(prevState => ({
        ...prevState,
        currentPage: page
      }));
    };

    const handleFocus = () => {
      setIsFocused(true)
    };
  
    const handleBlur = () => {
      setIsFocused(true);
    };
    const handleBack = () => {
      // const previousPath = location.state?.previousPath;
      // const currentPage = location.state?.currentPage;
      
       // Add this line for debugging

      if (state && state?.previousPath === '/admin/products') {
        const previousPath = state?.previousPath;
        const currentPage = state.currentPage;
          navigate('/admin/products', { state: { currentPage } });
      } else {
          navigate(-1);
      }
  };
  

const handleCategoryClick = (categoryId) => {
  if (state?.productsToAction?.fetchAll) {
    filterByCategory(categoryId, setLoading, setProducts, showToast,setPagination);
  } else {
   
  
    let filteredProducts = globalProducts.filter(product => product?.category?.id === categoryId);
    if (filteredProducts.length === 0){
      showToast({toastText:'No product found in this category', isSuccess:false})
      filteredProducts = globalProducts;
    }else{
      setProducts(filteredProducts);
      setPagination({
        currentPage: 1,
        itemsPerPage: 20,
        totalPages: 0,
        totalProducts: 0,
    
      });
    }
   
   
  }
 
 
};

const capitalizeWords = (string) => {
  return string.replace(/_/g, '-') // Replace underscores with spaces
              .toLowerCase() // Convert all to lower case
              .replace(/\b(\w)/g, s => s.toUpperCase()); // Capitalize first letters of each word
};

const handleSeasonStatusFilter = (status) => {
  if (state?.productsToAction?.fetchAll) {
  let seasonStatus;
  if(status === 'In Season'){
    seasonStatus = true
  }else if(status === 'Off Season'){
    seasonStatus = false
  }
  filterBySeason(seasonStatus, setLoading, setProducts, showToast, setPagination);
}
else{
  let filteredProducts;  
 
   if (status === 'In Season') {
     filteredProducts = globalProducts.filter(product => product.inSeason)
   } else if (status === 'Off Season') {
     filteredProducts = globalProducts.filter(product => !product.inSeason)
   }


   if (filteredProducts.length === 0) {
     showToast({toastText: `No products are currently ${status === true ? 'in season' : 'off season'}.`, isSuccess: false});
     filteredProducts = state?.productsToAction?.fetchAll ? products : globalProducts;  
   }

   setProducts(filteredProducts);

   setIsSeasonOpen(false);
   setIsOpen2(false);

   setPagination({
     currentPage: 1,
     itemsPerPage: 20,
     totalPages: 0,
     totalProducts: 0,
   });
}
  
};


const handleSelectStockStatus = (stock) => {
  let stockLevel;
  if(stock === 'In-stock'){
    stockLevel='in_stock'
  }else if(stock === 'Low-stock'){
    stockLevel='low_stock'
  }else if(stock === 'Out-of-stock'){
    stockLevel='out_of_stock'
  }
  if (state?.productsToAction?.fetchAll) {
 
  filterByStockLevel(stockLevel, setLoading, setProducts, showToast,setPagination);
}else{
  let filtered = globalProducts.filter(product => product?.stockLevel === stockLevel)

  if (filtered.length === 0) {
    showToast({toastText:`No products are ${stockLevel.replace('_', ' ')}.`, isSuccess:false});
    filtered = state?.productsToAction?.fetchAll ? products : globalProducts;  // Fallback to showing all products
  } else {
    setProducts(filtered);
  }

  setIsStockOpen(false);
  setIsOpen2(false); // Optionally close the dropdown after s
  setPagination({
    currentPage: 1,
    itemsPerPage: 20,
    totalPages: 0,
    totalProducts: 0,
  });
}
  
};

const getAllProducts = ()=> {
  if (state?.productsToAction?.fetchAll) {
  getProducts(
    setLoading,
    showToast,
    setProducts,
    pagination,
    setPagination
)}else{
  setProducts(globalProducts)
}
}
const handleStockLevelChange = (productId, value) => {
  setUpdatedStockLevel(prev => ({ ...prev, [productId]: value.replace(/\D/g, "") }));
};

const handleStockAlertChange = (productId, value) => {
  setUpdatedStockAlert(prev => ({ ...prev, [productId]: value.replace(/\D/g, "") }));
};

const saveUpdates = () => {
  const updates = globalProducts.filter(product => {
    // Check if there are any meaningful updates (not empty)
    const stockLevel = updatedStockLevel[product?.id];
    const stockAlert = updatedStockAlert[product?.id];
    return (stockLevel || stockLevel === 0) && stockLevel !== "" ||
           (stockAlert || stockAlert === 0) && stockAlert !== "";
  }).map(product => {
    // Create the update object with only updated fields
    const update = { productId: product?.id };
    if ((updatedStockLevel[product?.id] || updatedStockLevel[product?.id] === 0) && updatedStockLevel[product?.id] !== "") {
      update.quantityAvailable = updatedStockLevel[product?.id];
    }
    if ((updatedStockAlert[product?.id] || updatedStockAlert[product?.id] === 0) && updatedStockAlert[product?.id] !== "") {
      update.stockLevelAlert = updatedStockAlert[product?.id];
    }
    return update;
  });

  if (updates.length > 0) {
   
 
    bulkStockUpdate(updates, setLoading, showToast,state);
  } else {
    showToast({ toastText: "No stock levels updates to save.", isSuccess: false });
  }
};


  return (
    <div className=''>
        
        <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 mb-4 ps-4'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'> Stock Update</h3>
       
        </div>

        <div className=' pe-5 ps-4'>
      

        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):products?.length > 0 ? <>
    <div className='d-flex align-items-center gap-5 my-3'>
     
        <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500'}}>
        {!isFocused && searchProduct === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
    
         </>)}
         {searchProduct !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchProduct('')}></i>}
        <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search by product name'
        className='w-100'
        value={searchProduct}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={(e)=>setSearchProduct(e.target.value)}
        />
      </div>
    
       
    
      <Dropdown show={isOpen2} style={{border:'1px solid #353542', borderRadius:'4px'}} ref={dropdownRef2}>
     <Button variant='outline-secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item onClick={()=> {getAllProducts(); toggleDropdown2()}} className='d-flex justify-content-between'>All</Dropdown.Item>
    <DropdownDivider/>
     <Dropdown.Item onClick={toggleCategoryDropdown} className='d-flex justify-content-between'>Categories &nbsp; &nbsp;{isCategoryOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
          {isCategoryOpen && categories.map((category, index) => (
            <Dropdown.Item key={index} onClick={() => {toggleCategoryDropdown(); toggleDropdown2(); handleCategoryClick(category.id)}}>{category?.categoryName}</Dropdown.Item>
          ))}
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleSeasonDropdown} className='d-flex justify-content-between'>Season Status &nbsp; {isSeasonOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
          {isSeasonOpen &&  [ 'In Season', 'Off Season'].map((status, index) => (
  <Dropdown.Item
    key={index}
    
    onClick={() => {handleSeasonStatusFilter(status); toggleDropdown2()}}
  > {status}
  </Dropdown.Item>
))}
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleStockDropdown} className='d-flex justify-content-between'>Stock Level {isStockOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
     {isStockOpen && [ 'In-stock', 'Low-stock', 'Out-of-stock'].map((stock, index) => (
  <Dropdown.Item
    key={index}
    
    onClick={() => {handleSelectStockStatus(stock); toggleDropdown2()}}
  > {stock}
  </Dropdown.Item>
))}
         
     <DropdownDivider/>
     <Dropdown.Item>Date</Dropdown.Item>
    </Dropdown.Menu>
   </Dropdown>
        
      
    </div>
    <Table hover className='my-3'>
       <thead>
            <tr style={{fontWeight:'600', fontSize:'13px'}} className='mb-2'>
          
                <th style={{fontWeight:'600'}} className='pb-3'>Name</th>
               
                <th style={{fontWeight:'600',  width:'20%' }} className='pb-3'>Current Stock Level</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Updated Stock Level</th>
                <th style={{fontWeight:'600',  width:'20%' }} className='pb-3'>Current Stock Alert</th>
                <th style={{fontWeight:'600'}} className='pb-3 '>Updated Stock Alert</th>
               
                
            </tr>
        </thead>
            <tbody className='py-2 w-100'>
            {products?.map((product) => (
              <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={product?.id} className='w-100'>
               
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{product?.productName}</td>
              
               <td style={{width:'20%', padding:'15px 50px'  }} >{product?.quantityAvailable}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }} >  <Form.Control type='text' name='updatedStockLevel'  
                      value={updatedStockLevel[product?.id] || ''}
                      onChange={(e) => handleStockLevelChange(product?.id, e.target.value)}/></td>
               <td style={{padding:'15px 50px' , width:'20%' }}>{product?.stockLevelAlert ? product?.stockLevelAlert: '-' }</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }} >  <Form.Control  value={updatedStockAlert[product?.id] || ''}
                      onChange={(e) => handleStockAlertChange(product?.id, e.target.value)}/>   </td>
             
              </tr>
            ))}
            </tbody>
    </Table> 
    <div className='pt-3 my-3 d-flex justify-content-end'>
    <Button className='px-5 py-2' onClick={saveUpdates}>Save</Button>
  </div>
  {state?.productsToAction?.fetchAll && pagination && pagination.totalPages > 1 &&  (

<div className='d-flex justify-content-between align-items-center my-5 pt-5' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>Showing {products?.length} of {pagination.totalProducts} products</p>
  {pagination.totalPages > 1 && 
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}
 </> : searchPerformed && products.length === 0 ?  <div className='fw-medium ' style={{padding:'50px 0'}}><h4 className='mb-2'>No products found</h4><Button onClick={() => {setSearchProduct('')}}>View all products</Button>
    </div>:
    <div className='fw-medium ' style={{padding:'50px 0'}}><h4>You have no products</h4></div>
   }



      
    </div>
    </div>
  )
}

export default BulkStockUpdate
