import React, {useState, useEffect} from 'react'
import AdminPromotions from './AdminPromotions';
import AdminSubscriptions from './AdminSubscriptions';
import AdminVouchers from './AdminVouchers';

import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { RiShoppingBasketFill } from "react-icons/ri";
import { MdCardGiftcard } from "react-icons/md";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { localStorageGetItem, localStorageSetItem } from '../../App';



const UserDelights = () => {

  const [userDelight, setUserDelight] = useState(() => {
    // Check localStorage for the initial value
    return localStorageGetItem('userDelight') || 'Promotions';
  });

  const navigate = useNavigate();

  // Update localStorage whenever userDelight changes
  useEffect(() => {
    localStorageSetItem('userDelight', userDelight);
  }, [userDelight]);
    const handleDelightClick = (delight) => {
        setUserDelight(delight);
      };
    
      // Function to render the appropriate component
      const renderComponent = () => {
        switch (userDelight) {
          case 'Promotions':
            return <AdminPromotions />;
          case 'Subscription Boxes':
            return <AdminSubscriptions />;
          case 'Gift Vouchers':
            return <AdminVouchers />;
         
          default:
            return null;
        }
      };

  return (
    <section className=''>
    <div className='d-flex justify-content-between align-items-center header-admin py-5 pe-5 ps-4'>
        <h3 style={{color:'#344054'}}>User Delights</h3>
   {userDelight === 'Promotions' &&
    <Button className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/promotions/create')} >
    <HiOutlineSpeakerphone/>
   Create New Promotion
    </Button>
   }
    {userDelight === 'Subscription Boxes' &&
    <div className='d-flex align-items-center gap-3'>
        <Button variant='secondary' className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/subscriptions/templates')} >
   Box Templates
    </Button>
    <Button className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/subscriptions/add')} >
    <RiShoppingBasketFill/>
   Create New Box
    </Button>
    </div>
   }
   {userDelight === 'Gift Vouchers' &&
    <div className='d-flex align-items-center gap-3'>
        <Button variant='secondary' className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/vouchers/templates')} >
  Voucher Bank
    </Button>
    <Button className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> navigate('/admin/vouchers/add')} >
    <MdCardGiftcard/>
   Create New Voucher
    </Button>
    </div>
   }
  
    </div>
    <div className='d-flex gap-5 pt-2 my-3 ps-4' style={{borderBottom:'1px solid #EDEDED'}}>
    {['Promotions', 'Subscription Boxes', 'Gift Vouchers'].map((delight, index) => (
          <p
            key={index}
            className={`order-item pb-2 mb-0 me-4 ${userDelight === delight ? 'active' : ''}`}
            onClick={() => handleDelightClick(delight)}
            style={{ cursor: 'pointer', marginBottom: '5px' }}
          >
            {delight}
          </p>
        ))}
      </div>
      <div className='content-section'>
        {renderComponent()}
      </div>
  </section>
  )
}

export default UserDelights
