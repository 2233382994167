
import {Button} from 'react-bootstrap'

import logo2 from '../../assets/footer-logo.png'
import logout from '../../assets/logout.svg'
import setting from '../../assets/setting.svg'
import profile from '../../assets/profile.svg'
import profileCircle from '../../assets/profile-circled.svg'
import bell from '../../assets/bell.svg'
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { useEffect, useRef, useState } from 'react';
import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../../App';

export function getParsedLocalStorageItem(key) {
  const item = localStorageGetItem(key);
  if (item !== null) {
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error(`Parsing error for key "${key}":`, e);
      // Optionally handle the error, e.g., by clearing the invalid item
      localStorageRemoveItem(key);
    }
  }
  return null; // or a suitable default value for when the key is missing or parsing fails
}

const AdminProfile =  () => {

  const navigate = useNavigate();
  const token = localStorageGetItem('userToken');
  const userType = localStorageGetItem('userType');
  const {setShowLogoutModal} = useAppContext();
  
  
  const userData = getParsedLocalStorageItem('userData');
  const isLoggedIn = !!token;


  const handleLogout = () => {
 
 

        setShowLogoutModal(true);
      };


      
 
    return (
      <div>
   <div className='d-flex gap-3 align-items-center header-admin  py-4 pe-5 ps-4'>
           
           <h3 style={{color:'#344054'}} className='my-auto'> Profile</h3>
          
           </div>
           <div className='pe-5 ps-4'>
       <h4 style={{ fontWeight: '500', color: '#2E2C34' }} className='my-5'>{userData?.firstName} {userData?.lastName}</h4>
       
       <Button variant='outline-danger' className="d-flex gap-1 align-items-center" onClick={handleLogout} >
            <img src={logout}/>
              Log out
            </Button>
       </div>
       </div>

    
 
   
  
    );
  
 
}

export default AdminProfile


