import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Button, Form, Modal, Card, Row, Col, CloseButton,Popover, Overlay, ListGroup, Dropdown, InputGroup} from 'react-bootstrap';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import deleteIcon from '../../assets/delete.svg'
import edit from '../../assets/edit.svg'
import FilerobotImageEditor, { TABS, TOOLS} from 'react-filerobot-image-editor';
import adminRoute from '../../services/adminRoute';
import { debounce } from 'lodash'
import DeleteProductModal from '../../components/Modal/DeleteProductModal';


const PromotionDetails = () => {
    const { promoId } = useParams();
    const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
    const navigate = useNavigate();
    const [actionType, setActionType] = useState('');
    const [promotionDetail, setPromotionDetail] = useState({
        id:'',
        promoCode:'',
        promoName:'',
        percentage:'',
        promoType:'',
        discountType:'',
        amount:'',
        products:[],
        usageLimit:'',
        minOrderAmount:'',
        categories:[],
        startDate:'',
        endDate:'',
        // customData:{
        //   customRepeatNumber: '1',
        //   customRepeatFrequency: 'week',
        //   customEndDate: '',
        //   customEndOccurrence: '',
        //   customRepeatDay: [],
        //   customEndTime:''
        // }
    })
    // const [showCustomModal, setShowCustomModal] = useState(false);
    // const [customData, setCustomData] = useState({
    //   customRepeatNumber: '1',
    //   customRepeatFrequency: 'week',
    //   customEndDate: '',
    //   customEndOccurrence: '',
    //   customRepeatDay: [],
    //   customEndTime:''

    // });
    const dropdownRef = useRef(null);
    const popoverRef = useRef(null);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [showDropdowns, setShowDropdowns] = useState(false);
    const [categories, setCategories] = useState([]);
    const [globalProducts, setGlobalProducts] = useState([]);
    const [searchProduct, setSearchProduct] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const { getGlobalProducts, getProductCategory } = productRoute();
    const { getSinglePromotion,editPromotion } = adminRoute();
    const [loading, setLoading] = useState(false);
    const [popoverState, setPopoverState] = useState({ isVisible: false, loading: false });
    useEffect(() => {
        getSinglePromotion(
            promoId,
            setLoading,
            showToast,
            setPromotionDetail,
        );
      
    }, []);
 
  useEffect(() => {
  
    getProductCategory(
      setLoading, showToast, setCategories
     
    )

    
  }, []); 

  useEffect(() => {
    // Define a debounced version of the getProducts function
    const debouncedGetProducts = debounce((searchProduct) => {
        getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
            searchProduct,
        );
    }, 500);

    // Call the debounced getProducts function when the search product changes and it's not empty
    if (searchProduct !== '') {
      setSearchPerformed(true)
        debouncedGetProducts(searchProduct);
    } else {
        // If the search product is empty, call getProducts immediately
        getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
           
        );
    }

    // Cleanup function to cancel the debounced function
    return () => {
        debouncedGetProducts.cancel();
    };
}, [searchProduct]);

const isFormValid = () => {
  const { promoName, promoType, discountType, startDate } = promotionDetail;
  
  
  if (!(promoName && promoType && startDate, discountType)) {
      return false;
  }

  if (discountType === 'amount') {
    
      return promotionDetail.amount !== '';
  } else if (discountType === 'percentage') {
   
      return promotionDetail.percentage !== '';
  }

  return false;
};

      const handleBack = () => {
   
        navigate(-1);
      }
    const handleEdit = () => {
        setIsEditing(true);
      };
     
      const modules =  {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
    
            [{'list': 'ordered'}, {'list': 'bullet'}], // lists
            [{'script': 'sub'}, {'script': 'super'}], // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}], // indent
    
          
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
            [{'color': []}, {'background': []}], // dropdown with defaults
            [{'font': []}],
            [{'align': []}],
    
            ['clean'], // remove formatting
            ['link', 'image', 'video'] // link and image, video
        ]
    } 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPromotionDetail((prev) => ({ ...prev, [name]: value }));
      };
      

      const handleShowModal = (type) => {
        setActionType(type);
        setShowDeleteModal(true);
      };
   

    const handleSave = (e) => {
      e.preventDefault()
      setIsEditing(false);
    
      const body = {
        promotionId:promoId,
        promotionType: promotionDetail?.promoType,
        discountType: promotionDetail?.discountType,
        promotionFrequency: promotionDetail?.promoFrequency,
        startDate: new Date(promotionDetail.startDate).toISOString()
    };
    

    if (promotionDetail.discountType === 'amount' && promotionDetail.amount !== null && promotionDetail.amount !== '' && promotionDetail.amount !== undefined) {
        body.amount = parseFloat(promotionDetail.amount); // Ensure amount is treated as a number
    }
    if (promotionDetail.discountType === 'percentage' && promotionDetail.percentage !== null && promotionDetail.percentage !== '' && promotionDetail.percentage !== undefined) {
        body.percentage = parseFloat(promotionDetail.percentage); // Ensure percentage is treated as a number
    }
    if (promotionDetail.promoName !== null && promotionDetail.promoName !== '' && promotionDetail.promoName !== undefined) {
        body.description = promotionDetail.promoName;
    }
    if (promotionDetail.promoCode !== null && promotionDetail.promoCode !== '' && promotionDetail.promoCode !== undefined) {
      body.couponCode = promotionDetail.promoCode;
  }
    if (promotionDetail.endDate !== null && promotionDetail.endDate !== '' && promotionDetail.endDate !== undefined) {
        body.endDate = new Date(promotionDetail.endDate).toISOString();
    }
    if (promotionDetail.minValue !== null && promotionDetail.minValue !== '' && promotionDetail.minValue !== undefined) {
        body.minOrderAmount = parseFloat(promotionDetail.minValue); // Ensure minOrderAmount is treated as a number
    }
    if (promotionDetail.products && promotionDetail.products.length > 0) {
        // Extract only the productId values from the products array
        body.products = promotionDetail.products.map(product => product.id);
    }
    if (promotionDetail.categories && promotionDetail.categories.length > 0) {
        body.category = promotionDetail.categories;
    }

editPromotion(body,setLoading,
  showToast,)

  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Get year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns 0-based index
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleClearField = (fieldName) => {
    setPromotionDetail((prev) => ({
      ...prev,
      [fieldName]: fieldName === 'categories' || fieldName === 'products' ? [] : ''
    }));
  };
    

  const handleSelectAll = () => {
    const selectedCategories = promotionDetail.categories.length === categories.length ? [] : categories.map((category) => category.id);
    setPromotionDetail((prev) => ({
      ...prev,
      categories: selectedCategories,
    }));
  };
  
  const handleCheckboxChange = (categoryId) => {
    setPromotionDetail((prev) => ({
      ...prev,
      categories: prev.categories.includes(categoryId)
        ? prev.categories.filter((id) => id !== categoryId)
        : [...prev.categories, categoryId],
    }));
  };
  
  const handleTogglePopover = () => {
    setPopoverState((prev) => ({
      ...prev,
      isVisible: !prev.isVisible,
    }));
  };
  
 
  
  const handleSelectAllProducts = () => {
    const selectedProducts =
      promotionDetail.products.length === globalProducts.length
        ? []
        : globalProducts.map((product) => ({
            productId: product.id,
            name: product.productName,
          }));
    setPromotionDetail((prev) => ({
      ...prev,
      products: selectedProducts,
    }));
  };
  
 
  const handleToggleItem = (id) => {
    setPromotionDetail((prev) => ({
      ...prev,
      products: prev.products.some((product) => product.id === id)
        ? prev.products.filter((product) => product.id !== id)
        : [...prev.products, { id, productName: globalProducts.find((p) => p.id === id)?.productName || 'Product Name' }],
    }));
  };
  

  return (
    <div className='pe-3'>
        
        <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
          <div className='d-flex gap-3 align-items-center'>   <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'> Promotion Details</h3>
        {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEdit} className='my-auto'>Edit</p>
      )}</div>
      <div className='d-flex gap-3 align-items-center'>
      <Button variant='outline-danger' onClick={() => handleShowModal('deactivate')}>De-activate</Button>
<Button variant='danger' onClick={() => handleShowModal('delete')} className='px-4'>Delete</Button>

      </div>
         
        </div>
        <div className=' pe-5 ps-4'>
        {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):
        
        <Form onSubmit={handleSave}>
             <Row className=''>
     <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Code</p>
         <InputGroup>
         <Form.Control type='text' placeholder='Enter code' name='promoCode' value={promotionDetail.promoCode} maxLength={6}  onChange={handleChange} disabled={!isEditing}/>
         {isEditing && promotionDetail.promoCode &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('promoCode')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Name <sup className='text-danger'>*</sup></p>
         <InputGroup>
                
            
         <Form.Control type='text' name='promoName' value={promotionDetail.promoName}  onChange={handleChange} placeholder='Enter name'  required disabled={!isEditing}/>
         {isEditing && promotionDetail.promoName &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('promoName')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
        
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Type <sup className='text-danger'>*</sup></p>
         <InputGroup>
         <Form.Select
 className="custom-select"
  onChange={handleChange}
 value={promotionDetail.promoType}
 name="promoType" disabled={!isEditing}
 required
> <option value="" disabled>Select promo type</option>

<option value='free delivery'>Free Delivery</option>
 <option value='holidays'>Holidays</option>
 <option value='percentage off product'>% Off Products</option>
 <option value='product discount'>Product Discount</option>
 <option value='welcome gift'>Welcome Gift</option>

</Form.Select>
{isEditing && promotionDetail.promoType &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('promoType')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Min Order value</p>
    <InputGroup>
    <Form.Control
      name='minOrderAmount'
      type='text'
      placeholder='Enter value'
      value={promotionDetail.minOrderAmount}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        setPromotionDetail({ ...promotionDetail, minOrderAmount: numericValue })
      }}
      disabled={!isEditing}
    />
      {isEditing && promotionDetail.minValue &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('minValue')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Categories:</p>
       
         <Dropdown
                   className='rounded category-add-dropdown'
                   show={isEditing ? showDropdowns : false}
                   onToggle={(isOpen) => {
                       if (isEditing) { 
                           setShowDropdowns(isOpen);
                       }
                   }}
                   drop='down-centered'
                   style={{ width: '100%' }}
                   autoClose='outside'
                  >
                    <Dropdown.Toggle
                      className='rounded w-100 d-flex justify-content-between align-items-center'
                      style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #CED4DA',
                        color: '#000000',
                      }}
                    >
                      
                      <span className='my-auto text-muted' style={{color:'212529BF', fontWeight:'400'}}>  {promotionDetail.categories.length > 0 ? (
                          <>
                            {promotionDetail?.categories.length} categories selected
                           
                          </>
                        ) : (
                          'Select Categories'
                        )}</span>
                     
                    </Dropdown.Toggle>
                  
                    <Dropdown.Menu
                      className='w-100 mt-2 rounded-2'
                      style={{ maxHeight: '200px', overflowX: 'hidden' }}
                      ref={dropdownRef}
                    > 
                   
                    <ListGroup className='no-border-listgroup'>
    <ListGroup.Item as='div' className='ms-1 py-2'>
      <Form.Check
        type='checkbox'
        id='checkbox-select-all'
        label='Select All'
        checked={promotionDetail.categories.length === categories.length}
        onChange={handleSelectAll}
        className='d-flex align-items-center gap-2'
      />
    </ListGroup.Item>

                      {categories.map((category) => (
                        <ListGroup.Item key={category.id} as='div' className='ms-1 py-2'>
                          <Form.Check
                            type='checkbox'
                            id={`checkbox-${category.id}`}
                            label={category.categoryName}
                            checked={promotionDetail.categories.includes(category.id)}
                            onChange={() => handleCheckboxChange(category.id)}
                            className='d-flex align-items-center gap-2'
                          />
                        </ListGroup.Item>
                      ))}
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>
        
                
         </Col>

       
        
         <Col xs={4} className='px-2 mb-5'>
  <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Products</p>
  <div
    onClick={isEditing ? handleTogglePopover : undefined}
    ref={popoverRef}
    style={{ cursor: isEditing ? 'pointer' : 'default', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px' }}
    className='d-flex align-items-center'
  >
    {promotionDetail?.products?.length > 0 && (
      <i className="bi bi-plus-lg"></i>
    )}
    <div className='item-container'>
      {promotionDetail?.products?.length > 0 ? (
        promotionDetail?.products?.length === globalProducts.length ? (
          <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500', fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}}>
            All Products selected
          </Button>
        ) : (
          promotionDetail?.products?.map((item) => (
            <Button
              variant='outline-secondary'
              style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500', fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}}
              key={item?.id}
              onClick={isEditing ? (e) => {
                e.stopPropagation(); // Prevent popover from toggling
                handleToggleItem(item.id);
              } : undefined}
            >
              {globalProducts.find(product => product.id === item.id)?.productName || item?.productName} &nbsp;{isEditing && 'X'} 
            </Button>
          ))
        )
      ) : (
        <span className="text-muted">Add products</span>
      )}
    </div>
  </div>

  <Overlay
    show={popoverState.isVisible}
    target={popoverRef.current}
    placement="bottom"
    rootClose={true}
    onHide={handleTogglePopover}
  >
    <Popover id='popover-basic' className='custom-popover' style={{width:'430px'}}>
      <Popover.Body>
        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
          {searchProduct !== '' && (
            <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchProduct('')}></i>
          )}
          <Form.Control
            style={{border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius:'3px', fontWeight:'500'}}
            placeholder='Search by product name'
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
            autoFocus
          />
        </div>
        {loading ? (
          <div className='justify-content-center align-items-center text-center' style={{padding:'150px'}}>
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          globalProducts?.length > 0 ? (
            <>
              <div style={{borderTop:'1px solid #E1E6EF'}} className='pb-2 pt-3'>
                <h6 className='' style={{fontWeight:'500'}}>Products </h6>
              </div>
              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border:'none' }} className='no-border-listgroup'>
                <ListGroup.Item action className='d-flex justify-content-between'>
                  <Form.Check 
                    type="checkbox"
                    label="Select All"
                    checked={promotionDetail?.products?.length === globalProducts.length}
                    onChange={handleSelectAllProducts}
                  />
                </ListGroup.Item>
                {globalProducts.map(product => {
                  const selectedItem = Array.isArray(promotionDetail?.products)
                    ? promotionDetail.products.find(item => item.id === product.id)
                    : null;

                  return (
                    <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                      <Form.Check 
                        type="checkbox"
                        label={`${product.productName}`}
                        checked={!!selectedItem}
                        onChange={() => handleToggleItem(product.id)}
                      />
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </>
          ) : (
            searchPerformed && globalProducts.length === 0 ? (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4 className='mb-2'>No products found</h4>
                <Button onClick={() => setSearchProduct('')}>View all products</Button>
              </div>
            ) : (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4>You have no products</h4>
              </div>
            )
          )
        )}
      </Popover.Body>
    </Popover>
  </Overlay>
</Col>

                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Discount Type <sup className='text-danger'>*</sup></p>
                <InputGroup>
                <Form.Select
        className="custom-select"
         onChange={handleChange}
        value={promotionDetail?.discountType}
        name="discountType" disabled={!isEditing}
        required
      > <option value="" disabled>Select discount type</option>
    
        <option value="percentage">Percentage </option>
        <option value="amount">Amount</option>

      </Form.Select>
      {isEditing && promotionDetail.discountType &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('discountType')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
                </Col>
                {promotionDetail?.discountType === 'percentage' && <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Percentage Off <sup className='text-danger'>*</sup></p>
    <InputGroup>
    <Form.Control
      name='percentage'
      type='text'
      placeholder='Enter %'
      value={promotionDetail.percentage}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        setPromotionDetail({ ...promotionDetail, percentage: numericValue })
      }} disabled={!isEditing}
    />
     {isEditing && promotionDetail.percentage &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('percentageOff')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>}
  {promotionDetail?.discountType === 'amount' &&
   <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Amount Off <sup className='text-danger'>*</sup></p>
    <InputGroup>
    <Form.Control
      name='amount'
      type='text'
      placeholder='Enter amount'
      disabled={!isEditing}
      value={promotionDetail.amount}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        setPromotionDetail({ ...promotionDetail, amount: numericValue })
      }}
    />
     {isEditing && promotionDetail.amount &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('amount')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>}
  <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promotion Frequency  <sup className='text-danger'>*</sup></p>
                <InputGroup>
                <Form.Select
        className="custom-select"
        onChange={(e) => {
          handleChange(e);
          // if (e.target.value === 'custom') {
          //   setShowCustomModal(true);}
          }
        }
        value={promotionDetail?.promoFrequency}
        name="promoFrequency"
        required disabled
      > <option value="" disabled>Select frequency</option>
    
        <option value='no repeat'>Does not repeat </option>
        <option value='every day'>Every Day</option>
        <option value='every week'>Every week</option>
        <option value='every month'>Every month</option>
        <option value='every year'>Every year</option>
        {/* <option value='custom'>Custom</option> */}

      </Form.Select>
      {/* {isEditing && promotionDetail.promoFrequency &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('promoFrequency')}
              className=''
            >
              X
            </Button>} */}
            </InputGroup>
      {/* {promotionDetail?.promoFrequency === 'custom' && <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} className='text-center mt-1 mb-0 pb-0' onClick={() => 
     
      setShowCustomModal(true)
    }>View custom recurrence</p>} */}
                
                </Col>
         
  <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Start Date <sup className='text-danger'>*</sup></p>
         <InputGroup>
         <Form.Control type='date' placeholder='Enter start date' name='startDate' value={formatDate(promotionDetail.startDate)}  onChange={handleChange} required disabled={!isEditing}/>
         {isEditing && promotionDetail.startDate &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('startDate')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>End Date</p>
         <InputGroup>
         <Form.Control type='date' placeholder='Enter end date' name='endDate' value={formatDate(promotionDetail.endDate)}  onChange={handleChange} disabled={!isEditing} />
         {isEditing && promotionDetail.endDate &&
         <Button
              variant='secondary'
              onClick={() => handleClearField('endDate')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>

     </Row> 

           
                        {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}

        </Form>
        
        }


    </div>
  {/* {
  showCustomModal && (
    <Modal
    show={showCustomModal}
    onHide={() => setShowCustomModal(false)}
    centered
  >
    <Modal.Header closeButton style={{ fontWeight: '700' }}>
      <h4>Custom Recurrence</h4>
    </Modal.Header>
    <Modal.Body className='d-flex flex-column gap-3 pb-5'>
      <div className='px-2 py-3' style={{ borderBottom: '1px solid #F8F8F9' }}>
        <h6 style={{ fontWeight: '500' }}>Repeats every</h6>
        <Form.Group className='d-flex gap-2'>
          <Form.Control
            type='text'
            name='customRepeatNumber'
            value={promotionDetail?.customData?.customRepeatNumber}
            onChange={(event) =>
              setPromotionDetail((prevState) => ({
                ...prevState,
                customData: {
                  ...prevState.customData,
                  customRepeatNumber: event.target.value.replace(/\D/g, ''),
                },
              }))
            }
            style={{ width: '40px' }}
          />
  
          <Form.Select
            className="custom-select"
            onChange={(event) =>
              setPromotionDetail((prevState) => ({
                ...prevState,
                customData: {
                  ...prevState.customData,
                  customRepeatFrequency: event.target.value,
                },
              }))
            }
            value={promotionDetail?.customData?.customRepeatFrequency}
            name="customRepeatFrequency"
            style={{ width: '100px' }}
          >
            <option value='week'>week</option>
            <option value='month'>month</option>
            <option value='year'>year</option>
          </Form.Select>
        </Form.Group>
      </div>
  
      <div className='px-2 py-3' style={{ borderBottom: '1px solid #F8F8F9' }}>
        <h6 style={{ fontWeight: '500' }}>Repeats on</h6>
        <div className='d-flex gap-2'>
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, dayIndex) => (
            <Button
              key={dayIndex}
              variant={
                promotionDetail?.customData?.customRepeatDay?.includes(day)
                  ? 'primary'
                  : 'outline-primary'
              }
              style={{ borderRadius: '50%' }}
              onClick={() => {
                const currentDays = promotionDetail.customData.customRepeatDay || [];
                const daySelected = currentDays.includes(day);
                const updatedDays = daySelected
                  ? currentDays.filter(d => d !== day)
                  : [...currentDays, day];
                setPromotionDetail((prevState) => ({
                  ...prevState,
                  customData: {
                    ...prevState.customData,
                    customRepeatDay: updatedDays,
                  },
                }));
              }}
            >
              {day}
            </Button>
          ))}
        </div>
      </div>
      <div className='px-2 py-3 d-flex flex-column gap-3'>
    <h6 style={{ fontWeight: '500' }}>Ends</h6>
    <div className='d-flex gap-3 align-items-center'>
      <Form.Check
        type='radio'
        checked={promotionDetail?.customData?.customEndTime === 'never'}
        onChange={() =>
          setPromotionDetail((prevState) => ({
            ...prevState,
            customData: { ...prevState.customData, customEndTime: 'never' },
          }))
        }
      />
      <h6 className='my-auto'>Never</h6>
    </div>
    <div className='d-flex gap-3 align-items-center'>
      <Form.Check
        type='radio'
        checked={promotionDetail?.customData?.customEndTime === 'on'}
        onChange={() =>
          setPromotionDetail((prevState) => ({
            ...prevState,
            customData: { ...prevState.customData, customEndTime: 'on' },
          }))
        }
      />
      <Form.Group className='d-flex gap-2 align-items-center'>
        <h6 className='my-auto'>On</h6>
        <Form.Control
          type='date'
          name='customEndDate'
          value={promotionDetail?.customData?.customEndDate}
          onChange={(event) =>
            setPromotionDetail((prevState) => ({
              ...prevState,
              customData: { ...prevState.customData, customEndDate: event.target.value },
            }))
          }
        />
      </Form.Group>
    </div>
    <div className='d-flex gap-3 align-items-center'>
      <Form.Check
        type='radio'
        checked={promotionDetail?.customData?.customEndTime === 'after'}
        onChange={() =>
          setPromotionDetail((prevState) => ({
            ...prevState,
            customData: { ...prevState.customData, customEndTime: 'after' },
          }))
        }
      />
      <Form.Group className='d-flex gap-2 align-items-center'>
        <h6 className='my-auto'>After</h6>
        <Form.Control
          type='text'
          style={{ width: '40px' }}
          name='customEndOccurrence'
          value={promotionDetail?.customData?.customEndOccurrence}
          onChange={(event) =>
            setPromotionDetail((prevState) => ({
              ...prevState,
              customData: {
                ...prevState.customData,
                customEndOccurrence: event.target.value.replace(/\D/g, ''),
              },
            }))
          }
        />
        <h6 className='my-auto'>Occurrence</h6>
      </Form.Group>
    </div>
  </div>
    </Modal.Body>
  </Modal>
  
)} */}

<DeleteProductModal
  item={promotionDetail}
  itemType="promo"
  actionType={actionType}
  showModal={showDeleteModal}
/>


    </div>
  )
}

export default PromotionDetails
