
import { createContext, useContext, useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { getParsedLocalStorageItem } from './components/Navbar/Navbar';
import axios from 'axios'
import { localStorageGetItem } from './App';




export const AppContext = createContext();


export const AppContextProvider = (props) => {

  const showToast = ({ toastText, subText, isSuccess, closeToast }) => {
    const content = (
      <div>
        <h5 style={{ fontWeight: '700' }} className='my-auto'>{toastText}</h5>
        {isSuccess && subText && <h6>{subText}</h6>}
      </div>
    );

    const toastOptions = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      onClose: closeToast,
    };

    if (isSuccess) {
      toast.success(content, toastOptions);
    } else {
      toast.error(content, toastOptions);
    }
  };


    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal]= useState(false);
 
    const [productState, setProductState] = useState(() => {
        
          return localStorageGetItem('productState') || 'Products';
        });
  const contextValue = { showToast, setShowLogoutModal, showDeleteModal, setShowDeleteModal, showLogoutModal,showSuccessModal, setShowSuccessModal,productState,setProductState
  };

  return (
    <AppContext.Provider value={contextValue}>{props.children}
   
    </AppContext.Provider>
  );
}


export const useAppContext = () => {
  return useContext(AppContext);
};




