
import { Navbar,Nav,Popover, Row,Col, OverlayTrigger,Overlay, CloseButton} from 'react-bootstrap'

import logo2 from '../../assets/footer-logo.png'
import logout from '../../assets/logout.svg'
import setting from '../../assets/setting.svg'
import profile from '../../assets/profile.svg'
import profileCircle from '../../assets/profile-circled.svg'
import bell from '../../assets/bell.svg'
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { useEffect, useRef, useState } from 'react';
import { localStorageGetItem,localStorageRemoveItem,localStorageSetItem } from '../../App';

export function getParsedLocalStorageItem(key) {
  const item = localStorageGetItem(key);
  if (item !== null) {
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error(`Parsing error for key "${key}":`, e);
      // Optionally handle the error, e.g., by clearing the invalid item
      localStorageRemoveItem(key);
    }
  }
  return null; // or a suitable default value for when the key is missing or parsing fails
}

const GuestNavbar =  () => {

  const navigate = useNavigate();
  const token = localStorageGetItem('userToken');
  const userType = localStorageGetItem('userType');
  const {setShowLogoutModal} = useAppContext();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);


 
    return (
      <Navbar fixed='top'  variant="light" bg="white" className='d-flex align-items-center justify-content-between px-4 px-sm-3 px-xl-5 ' style={{borderBottom:'1px solid #dfdcdc', height:'76px'}}>

      <Navbar.Brand className='' >  <img className="" src={logo2} alt="" style={{width:'50px'}}/></Navbar.Brand>


     

   
      </Navbar>
    );
  
 
}

export default GuestNavbar


