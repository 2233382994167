import { useState } from 'react'
import {Image,Form, Button,Modal, Card } from 'react-bootstrap'

import logo from '../../assets/vs-word-logo.png'
import { Link } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import apple from '../../assets/apple-icon.svg'
import facebook from '../../assets/facebook-icon.svg'
import GuestNavbar from '../../components/Navbar/Navbar';
import eye from '../../assets/eye.svg'
import authRoute from '../../services/authRoute'
import { useAppContext } from '../../AppContext'
import homeBg from '../../assets/home-bg.png'

const AdminForgotPassword = () => {

  const {showToast}= useAppContext()
 
 
  const {login}= authRoute();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
 const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };
      const handleSubmit = (e)=> {
        e.preventDefault();
    
        const body = { 
         
          email: email,
        }
        // sendPasswordResetLink(body, showToast, setShowPasswordModal)
  
    }


 
    
   
  return (
    <> 
   
             
     <Card style={{border:"none", borderRadius:'0px'}}>
            <Card.Img src={homeBg} alt='bg' style={{ opacity: '', width:'100%', maxHeight: '750px', objectFit: 'cover' }} />

            <Card.ImgOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}} className='r'>
            <div style={{width:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white', marginTop:'80px'}} className=' mx-auto p-5'>
                 <div className='text-center'><Image fluid src={logo} style={{width:'100px'}}/>
                 <h2 className='mt-1 mb-3'>Forgot Password</h2>
                 <p style={{color:'#5D5D5D', fontSize:'12px'}}>Enter the email address associated with your account and we will send you a link to reset your password.</p></div>
              
                   <Form onSubmit={handleSubmit}>
                       <Form.Group className='mb-4'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                     
                       <div><Button type='submit' className='btn-primary w-100'>Continue</Button></div>
                    
                   </Form>
          
                   </div>
            </Card.ImgOverlay>
          
                
                

                  </Card> 
                  
             
          
                  
     </>
    
 
       
   
  )
}

export default AdminForgotPassword
