import React, {useState, useEffect, useRef} from 'react'
import { Button, Form, Modal, Card, Row, Col, CloseButton,Popover, Overlay, ListGroup, Dropdown, InputGroup } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'
import { debounce } from 'lodash'
import { useAppContext } from '../../AppContext'
import sad from '../../assets/sad-face.png'
import { trackEvent } from '../../mixpanel'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/delete.svg'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import productRoute from '../../services/productRoute'
import { usePrevious } from './CreateOrder'
import arrowLeft from '../../assets/arrow-left.svg'
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaChevronDown } from 'react-icons/fa'
import adminRoute from '../../services/adminRoute'
const CreatePromo = () => {
    const {showToast}= useAppContext();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [promoDetails, setPromoDetails] = useState([{
      promoCode: '',
      promoName: '',
      promoType: '',
      minValue: '',
      categories: [],
      products: [],
      discountType:'',
      amountOff:'',
      percentageOff: '',
      promoFrequency:'',
      startDate: '',
      endDate: '',
    
     
    }]);
    // const [showCustomModal, setShowCustomModal] = useState(Array(promoDetails.length).fill(false));
    // const [customData, setCustomData] = useState(Array(promoDetails.length).fill({
    //   customRepeatNumber: '1',
    //   customRepeatFrequency: 'week',
    //   customEndDate: '',
    //   customEndOccurrence: '',
    //   customRepeatDay: [],
    //   customEndTime:''

    // }));
    const today = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();
    const {getGlobalProducts, getProductCategory}= productRoute();
    const { createPromotions}= adminRoute();
    const popoverRefs = useRef([]);
 
    const [searchProduct, setSearchProduct] = useState('');
    const [globalProducts, setGlobalProducts] = useState([]);
const [searchPerformed, setSearchPerformed] = useState(false);
    const [popoverStates, setPopoverStates] = useState(promoDetails.map(() => ({ isVisible: false, searchProduct: '', loading: false })));
    const searchProducts = popoverStates.map(ps => ps.searchProduct);
    const debouncedGetProducts = useRef([]);
    const previousSearchProducts = usePrevious(searchProducts) || [];
    const [showDropdowns, setShowDropdowns] = useState(promoDetails.map(() => false));
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdowns(promoDetails.map(() => false));
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [promoDetails]);

    useEffect(() => {
    

      getProductCategory(
        setLoading, showToast, setCategories
       
      )
  
      
    }, []); 

    useEffect(() => {
      // Align popoverStates with promoDetails length
      if (popoverStates.length !== promoDetails.length) {
        setPopoverStates(promoDetails.map(() => ({ isVisible: false, searchProduct: '', loading: false })));
      }
    }, [promoDetails, popoverStates.length]);
  
    useEffect(() => {
      // Adjust the length of the refs array to match the number of promoDetails
      popoverRefs.current = promoDetails.map((_, index) => 
        popoverRefs.current[index] || React.createRef()
      );
    }, [promoDetails.length]);
  
    

  useEffect(() => {
    // Re-initialize the debounced functions if the number of popovers changes
    debouncedGetProducts.current = popoverStates.map((_, index) => debounce((searchProduct) => {
        getGlobalProducts(setLoading, showToast, setGlobalProducts, searchProduct);
    }, 500));

    return () => {
        // Cleanup by canceling all debounced calls
        debouncedGetProducts.current.forEach(fn => fn.cancel());
    };
}, [popoverStates.length]);



useEffect(() => {
  // Only call API when there's an actual change in the search term
  searchProducts.forEach((searchProduct, index) => {
    if (searchProduct !== previousSearchProducts[index]) {
      if (searchProduct !== '') {
          debouncedGetProducts.current[index](searchProduct);
      }else{
        getGlobalProducts(setLoading, showToast, setGlobalProducts);
      }
    }
  });
}, [searchProducts]);

const handleTogglePopover = (index) => {
  const newStates = [...popoverStates];
  newStates[index].isVisible = !newStates[index].isVisible;
  setPopoverStates(newStates);
};

const handleSearchChange = (searchProduct, index) => {
  // Update the search product in the corresponding popover state
  setPopoverStates(current =>
    current.map((item, idx) => idx === index ? { ...item, searchProduct } : item)
  );
};


    const handleAddMore = () => {
      const newPromoDetail = {
        promoCode: '',
      promoName: '',
      promoType: '',
      minValue: '',
      categories: [],
      products: [],
      discountType:'',
      amountOff:'',
      percentageOff: '',
      promoFrequency:'',
      promoVisibility:'',
      startDate: '',
      endDate: '',
      };

      setPromoDetails([...promoDetails, newPromoDetail]);
      setPopoverStates(states => [...states, { isVisible: false, searchProduct: '', loading: false }]);
      setShowDropdowns((states) => [...states, false]);
    };

  
    const handleRemovePromo = (index) => {
      const updatePromoDetails = [...promoDetails];
      updatePromoDetails.splice(index, 1);
      setPromoDetails(updatePromoDetails);
      setPopoverStates((states) => states.filter((_, i) => i !== index));
    setShowDropdowns((states) => states.filter((_, i) => i !== index));
    };

    const handleChange = (index, event) => {
      const { name, type, checked, value } = event.target;
      const isCheckbox = type === 'checkbox';
    
      const updatePromoDetails = promoDetails.map((item, idx) =>
        idx === index ? { ...item, [name]: isCheckbox ? checked : value } : item
      );
    
      setPromoDetails(updatePromoDetails);
    };

    const handleSelectAll = (index) => {
      const allCategoryIds = categories.map(category => category.id);
      const selectedCategories = promoDetails[index].categories.length === categories.length ? [] : allCategoryIds;
    
      const updatedPromoDetails = promoDetails.map((item, idx) =>
        idx === index ? { ...item, categories: selectedCategories } : item
      );
    
      setPromoDetails(updatedPromoDetails);
    };
    const handleClearField = (index, fieldName) => {
      const updatedPromoDetails = promoDetails.map((promoDetail, i) => {
        if (i === index) {
          return { ...promoDetail, [fieldName]: fieldName === 'categories' || fieldName === 'products' ? [] : '' };
        }
        return promoDetail;
      });
      setPromoDetails(updatedPromoDetails);
    };
    

    const handleCheckboxChange = (categoryId, index) => {
      const selectedCategories = promoDetails[index].categories.includes(categoryId)
        ? promoDetails[index].categories.filter((id) => id !== categoryId)
        : [...promoDetails[index].categories, categoryId];
  
      const updatedPromoDetails = promoDetails.map((item, idx) =>
        idx === index ? { ...item, categories: selectedCategories } : item
      );
  
      setPromoDetails(updatedPromoDetails);
    };
  
    const handleRemove = (categoryToRemove, index) => {
      const updatedCategories = promoDetails[index].categories.filter(
        (category) => category !== categoryToRemove
      );
  
      const updatedPromoDetails = promoDetails.map((item, idx) =>
        idx === index ? { ...item, categories: updatedCategories } : item
      );
  
      setPromoDetails(updatedPromoDetails);
    };

    const isFormValid = () => {
      for (let promo of promoDetails) {
      
        if (!promo.promoName || 
          !promo.promoType || !promo.discountType ||
          (promo.discountType === 'percentage' && !promo.percentageOff)||  (promo.discountType === 'amount' && !promo.amountOff) || 
          !promo.startDate 
          
           ) {
          return false; 
        }
      }
      return true; 
    };

    const handleBack = ()=> {
      navigate(-1)
    }
    
    const handleToggleItem = (productId, index) => {
      const product = globalProducts.find(p => p.id === productId);
      if (!product) {
        showToast({ toastText: 'Product not found.', isSuccess: false });
        return;
      }
    
    
      const promoDetail = promoDetails[index];
      const itemIndex = promoDetail.products.findIndex(item => item.productId === productId);
    
      if (itemIndex !== -1) {
        // Item exists, remove it
        const newProducts = [...promoDetail.products];
        newProducts.splice(itemIndex, 1);
        const newPromoDetails = [...promoDetails];
        newPromoDetails[index] = { ...promoDetail, products: newProducts };
        setPromoDetails(newPromoDetails);
      } else {
        // Item does not exist, add it
        const newItem = {
          productId: productId,
          name: product.productName,
         
        };
        const newPromoDetails = [...promoDetails];
        newPromoDetails[index] = { ...promoDetail, products: [...promoDetail.products, newItem] };
        setPromoDetails(newPromoDetails);
      }
    };

    const handleSelectAllProducts = (index) => {
      const allProductIds = globalProducts.map(product => ({ productId: product.id }));
      const selectedProducts = promoDetails[index]?.products?.length === globalProducts.length ? [] : allProductIds;
    
      const updatedPromoDetails = promoDetails.map((item, idx) =>
        idx === index ? { ...item, products: selectedProducts } : item
      );
    
      setPromoDetails(updatedPromoDetails);
    };
    
    const handleProceed = (e) => {
      e.preventDefault();
  
      const newPromotions = promoDetails.map(detail => {
          const promotion = {
              description: detail?.promoName,
              promotionType: detail?.promoType,
              discountType: detail?.discountType,
              startDate: new Date(detail.startDate).toISOString(),
             
          };
  
          if (detail.discountType === 'amount' && detail.amountOff !== null && detail.amountOff !== '' && detail.amountOff !== undefined) {
              promotion.amount = parseFloat(detail.amountOff); // Ensure amount is treated as a number
          }
          if (detail.discountType === 'percentage' && detail.percentageOff !== null && detail.percentageOff !== '' && detail.percentageOff !== undefined) {
              promotion.percentage = parseFloat(detail.percentageOff); // Ensure percentage is treated as a number
          }
          if (detail.promoCode !== null && detail.promoCode !== '' && detail.promoCode !== undefined) {
              promotion.couponCode = detail.promoCode;
          }
          if (detail.promoFrequency !== null && detail.promoFrequency !== '' && detail.promoFrequency !== undefined) {
            promotion.promotionFrequency = detail.promoFrequency;
        }
          if (detail.endDate !== null && detail.endDate !== '' && detail.endDate !== undefined) {
              promotion.endDate = new Date(detail.endDate).toISOString();
          }
          if (detail.minValue !== null && detail.minValue !== '' && detail.minValue !== undefined) {
              promotion.minOrderAmount = parseFloat(detail.minValue); // Ensure minOrderAmount is treated as a number
          }
          if (detail.products && detail.products.length > 0) {
              // Extract only the productId values from the products array
              promotion.products = detail.products.map(product => product.productId);
          }
          if (detail.categories && detail.categories.length > 0) {
              promotion.category = detail.categories;
          }
  
          return promotion;
      });
  
      const body = {
          newPromotions
      };
  
    
      createPromotions(body, setLoading, showToast);
  };



  return (
    <div>
    <div className='d-flex gap-3 align-items-center  header-admin  py-5 pe-5 ps-4'>
          <img src={arrowLeft} onClick={handleBack} alt='arrow'/>
      <h3 style={{color:'#344054'}} className='mt-2'>Create A New Promotion</h3>
      </div>
      <div className=' pe-5 ps-4'>
     
            <Form onSubmit={handleProceed}>
            {promoDetails.map((promoDetail, index) => (
   <Card className='border-0 px-4 pt-4 mb-4' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px'}} key={index}>
   {promoDetails.length > 1 && <div className='d-flex justify-content-end mb-4'><CloseButton onClick={() => handleRemovePromo(index)}/></div>}

     <Row className=''>
     <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Code</p>
         <InputGroup>
         <Form.Control type='text' placeholder='Enter code' name='promoCode' value={promoDetail.promoCode} maxLength={6}  onChange={(e) => handleChange(index, e)} />
         {promoDetail.promoCode &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'promoCode')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Name <sup className='text-danger'>*</sup></p>
         <InputGroup>
                
            
         <Form.Control type='text' name='promoName' value={promoDetail.promoName}  onChange={(e) => handleChange(index, e)} placeholder='Enter name'  required/>
         {promoDetail.promoName &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'promoName')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
        
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promo Type <sup className='text-danger'>*</sup></p>
         <InputGroup>
         <Form.Select
 className="custom-select"
  onChange={(e) => handleChange(index, e)}
 value={promoDetail.promoType}
 name="promoType"
 required
> <option value="" disabled>Select promo type</option>

 <option value='free delivery'>Free Delivery</option>
 <option value='holidays'>Holidays</option>
 <option value='percentage off product'>% Off Products</option>
 <option value='product discount'>Product Discount</option>
 <option value='welcome gifts'>Welcome Gifts</option>

</Form.Select>
{promoDetail.promoType &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'promoType')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Min Order value</p>
    <InputGroup>
    <Form.Control
      name='minValue'
      type='text'
      placeholder='Enter value'
      value={promoDetail.minValue}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        if (numericValue) {
          const updatePromoDetails = promoDetails.map((item, idx) => 
            idx === index ? { ...item, minValue: numericValue } : item
          );
          setPromoDetails(updatePromoDetails);
        }

       
      }}
      
    />
      {promoDetail.minValue &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'minValue')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Categories:</p>
       
         <Dropdown
                    className='rounded category-add-dropdown'
                    show={showDropdowns[index]}
                    onToggle={(isOpen) => {
                      setShowDropdowns((prev) => prev.map((show, i) => (i === index ? isOpen : show)));
                    }}
                    drop='down-centered'
                    style={{ width: '100%' }}
                    autoClose='outside'
                  >
                    <Dropdown.Toggle
                      className='rounded w-100 d-flex justify-content-between align-items-center'
                      style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #CED4DA',
                        color: '#000000',
                      }}
                    >
                      
                      <span className='my-auto text-muted' style={{color:'212529BF', fontWeight:'400'}}>  {promoDetail.categories.length > 0 ? (
                          <>
                            {promoDetail.categories.length} categories selected
                           
                          </>
                        ) : (
                          'Select Categories'
                        )}</span>
                     
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className='w-100 mt-2 rounded-2'
                      style={{ maxHeight: '200px', overflowX: 'hidden' }}
                      ref={dropdownRef}
                    > 
                   
                    <ListGroup className='no-border-listgroup'>
    <ListGroup.Item as='div' className='ms-1 py-2'>
      <Form.Check
        type='checkbox'
        id={`checkbox-select-all-${index}`}
        label='Select All'
        checked={promoDetails[index].categories.length === categories.length}
        onChange={() => handleSelectAll(index)}
        className='d-flex align-items-center gap-2'
      />
    </ListGroup.Item>

                      {categories.map((category) => (
                        <ListGroup.Item key={category.id} as='div' className='ms-1 py-2'>
                          <Form.Check
                            type='checkbox'
                            id={`checkbox-${category.id}-${index}`}
                            label={category.categoryName}
                            checked={promoDetail.categories.includes(category.id)}
                            onChange={() => handleCheckboxChange(category.id, index)}
                            className='d-flex align-items-center gap-2'
                          />
                        </ListGroup.Item>
                      ))}
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>
        
                
         </Col>

       
        
         <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Products</p>
                <div onClick={() => handleTogglePopover(index)} ref={popoverRefs.current[index]} style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px' }} className='d-flex align-items-center'>
                {promoDetail?.products?.length > 0 && (
    <i className="bi bi-plus-lg"></i>
  )} <div className='item-container'>
  {promoDetail?.products?.length > 0 ? (
    promoDetail?.products?.length === globalProducts.length ? (
      <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500', fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}}>
        All Products selected
      </Button>
    ) : (
      promoDetail?.products?.map((item) => (
        <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500', fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item.productId} onClick={(e) => {
          e.stopPropagation(); // Prevent popover from toggling
          handleToggleItem(item.productId, index);
        }}>
          {item.name} &nbsp; X
        </Button>
      ))
    )
  ) : (
    <span className="text-muted">Add products</span>
  )}
</div>

        
      </div>

      <Overlay
  show={popoverStates[index] && popoverStates[index].isVisible}
  target={popoverRefs.current[index]}
  placement="bottom"
  rootClose={true}
  onHide={() => handleTogglePopover(index)}
>
  {popoverStates[index] && 


    <Popover id={`popover-basic-${index}`} className='custom-popover' style={{width:'430px'}}>
      <Popover.Body>
        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
        {popoverStates[index].searchProduct !== '' && (
          <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => handleSearchChange('', index)}></i>
        )}
       <Form.Control
  style={{border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius:'3px', fontWeight:'500'}}
  placeholder='Search by product name'
  value={popoverStates[index].searchProduct}
 onChange={(e) => handleSearchChange(e.target.value, index)}
  autoFocus
/>

        </div>
        {loading ? (
          <div className='justify-content-center align-items-center text-center' style={{padding:'150px'}}>
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          globalProducts?.length > 0 ? (
            <>
              <div style={{borderTop:'1px solid #E1E6EF'}} className='pb-2 pt-3'>
                <h6 className='' style={{fontWeight:'500'}}>Products </h6>
              </div>
              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border:'none' }} className='no-border-listgroup'>
              <ListGroup.Item action className='d-flex justify-content-between'>
    <Form.Check 
      type="checkbox"
      label="Select All"
      checked={promoDetails[index]?.products?.length === globalProducts.length}
      onChange={() => handleSelectAllProducts(index)}
    />
  </ListGroup.Item>
                {globalProducts.map(product => {
                  const selectedItem = promoDetails[index]?.products?.find(item => item.productId === product?.id);
                  return (
                    <ListGroup.Item key={product?.id} action className='d-flex justify-content-between'>
                      <Form.Check 
                        type="checkbox"
                        label={`${product?.productName}`}
                        checked={!!selectedItem}
                        onChange={() => handleToggleItem(product?.id, index)}
                      />
                    
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </>
          ) : (
            searchPerformed && globalProducts.length === 0 ? (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4 className='mb-2'>No products found</h4>
                <Button onClick={() => setSearchProduct('')}>View all products</Button>
              </div>
            ) : (
              <div className='fw-medium ' style={{padding:'50px 0'}}>
                <h4>You have no products</h4>
              </div>
            )
          )
        )}
      </Popover.Body>
    </Popover>
}

</Overlay>
                
                </Col>
                <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Discount Type <sup className='text-danger'>*</sup></p>
                <InputGroup>
                <Form.Select
        className="custom-select"
         onChange={(e) => handleChange(index, e)}
        value={promoDetail?.discountType}
        name="discountType"
        required
      > <option value="" disabled>Select discount type</option>
    
        <option value='percentage'>% Off </option>
        <option value='amount'>Amount-off</option>

      </Form.Select>
      {promoDetail.discountType &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'discountType')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
                </Col>
                {promoDetail?.discountType === 'percentage' && <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Percentage Off <sup className='text-danger'>*</sup></p>
    <InputGroup>
    <Form.Control
      name='percentageOff'
      type='text'
      placeholder='Enter %'
      value={promoDetail.percentageOff}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        if (numericValue) {
          const updatePromoDetails = promoDetails.map((item, idx) => 
            idx === index ? { ...item, percentageOff: numericValue } : item
          );
          setPromoDetails(updatePromoDetails);
        }

       
      }}
    />
     {promoDetail.percentageOff &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'percentageOff')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>}
  {promoDetail?.discountType === 'amount' &&
   <Col xs={4} className='px-2 mb-5'>
    <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Amount Off <sup className='text-danger'>*</sup></p>
    <InputGroup>
    <Form.Control
      name='amountOff'
      type='text'
      placeholder='Enter amount'
      value={promoDetail.amountOff}
      onChange={(event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        if (numericValue) {
          const updatePromoDetails = promoDetails.map((item, idx) => 
            idx === index ? { ...item, amountOff: numericValue } : item
          );
          setPromoDetails(updatePromoDetails);
        }

       
      }}
    />
     {promoDetail.amountOff &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'amountOff')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
  </Col>}
  <Col xs={4} className='px-2 mb-5'>
                <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Promotion Frequency </p>
                <InputGroup>
                <Form.Select
        className="custom-select"
        onChange={(e) => {
          handleChange(index, e);
          // if (e.target.value === 'custom') {
          //   const newShowCustomModal = [...showCustomModal];
          //   newShowCustomModal[index] = true;
          //   setShowCustomModal(newShowCustomModal);
          // }
        }}
        value={promoDetail?.promoFrequency}
        
        name="promoFrequency"
        
      > <option value="" disabled>Select frequency</option>
    
        <option value='does not repeat'>Does not repeat </option>
        <option value='every day'>Every Day</option>
        <option value='every week'>Every week</option>
        <option value='every month'>Every month</option>
        <option value='every year'>Every year</option>
        {/* <option value='custom'>Custom</option> */}

      </Form.Select>
      {promoDetail.promoFrequency &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'promoFrequency')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
      {/* {promoDetail?.promoFrequency === 'custom' && <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} className='text-center mt-1 mb-0 pb-0' onClick={() => {
      const newShowCustomModal = [...showCustomModal];
      newShowCustomModal[index] = true;
      setShowCustomModal(newShowCustomModal);
    }}>View custom recurrence</p>} */}
                
                </Col>
         
  <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>Start Date <sup className='text-danger'>*</sup></p>
         <InputGroup>
         <Form.Control type='date' placeholder='Enter start date' name='startDate'  min={today} value={promoDetail.startDate}  onChange={(e) => handleChange(index, e)} required/>
         {promoDetail.startDate &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'startDate')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>
         <Col xs={4} className='px-2 mb-5'>
         <p style={{color:'#333333', fontWeight:'500'}} className='pb-0 mb-1'>End Date</p>
         <InputGroup>
         <Form.Control type='date' placeholder='Enter end date' name='endDate'  min={today} value={promoDetail.endDate}  onChange={(e) => handleChange(index, e)} />
         {promoDetail.endDate &&
         <Button
              variant='secondary'
              onClick={() => handleClearField(index, 'endDate')}
              className=''
            >
              X
            </Button>}
            </InputGroup>
         </Col>

     </Row> 

    
 </Card>
))}

              <div className='d-flex gap-3 mb-3' onClick={handleAddMore}><i className='bi bi-plus' style={{color:'#469B12'}}></i><p style={{color:'#469B12', fontWeight:'500', textDecoration:"underline"}}>Add More</p></div>
              <div className=' d-flex justify-content-end'>
<Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Create</Button>
</div>
            </Form>
          
          
           

          </div>
        
{/* {showCustomModal.map((showModal, modalIndex) => (
  showModal && (
    <Modal
      key={modalIndex}
      show={showModal}
      onHide={() => {
        const newShowCustomModal = [...showCustomModal];
        newShowCustomModal[modalIndex] = false;
        setShowCustomModal(newShowCustomModal);
      }}
      centered
    ><Modal.Header closeButton style={{fontWeight:'700'}}><h4>Custom Recurrence</h4></Modal.Header>
    <Modal.Body className='d-flex flex-column gap-3 pb-5'>

      <div className='px-2 py-3' style={{borderBottom:'1px solid #F8F8F9' }} >
        <h6 style={{fontWeight:'500'}}>Repeats every</h6>
        <Form.Group className='d-flex gap-2'>
          <Form.Control
            type='text'
            name='customRepeatNumber'
            value={customData[modalIndex].customRepeatNumber}
            onChange={(event) => {
              const numericValue = event.target.value.replace(/\D/g, "");
              const newData = [...customData];
              newData[modalIndex] = { ...newData[modalIndex], customRepeatNumber: numericValue };
              setCustomData(newData);
            }}
            style={{width:'40px'}}
          />

          <Form.Select
            className="custom-select"
            onChange={(event) => {
              const newData = [...customData];
              newData[modalIndex] = { ...newData[modalIndex], customRepeatFrequency: event.target.value };
              setCustomData(newData);
            }}
            value={customData[modalIndex].customRepeatFrequency}
            name="customRepeatFrequency"
            style={{width:'100px'}}
          >
            <option value='week'>week</option>
            <option value='month'>month</option>
            <option value='year'>year</option>
          </Form.Select>
        </Form.Group>
      </div>

      <div className='px-2 py-3' style={{borderBottom:'1px solid #F8F8F9' }} >
        <h6 style={{fontWeight:'500'}}>Repeats on</h6>
        <div className='d-flex gap-2'>
  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, dayIndex) => (
    <Button
      key={dayIndex}
      variant={customData[modalIndex].customRepeatDays?.includes(day) ? 'primary' : 'outline-primary'}
      style={{borderRadius: '50%'}}
      onClick={() => {
        const newData = [...customData];
        const currentDays = newData[modalIndex].customRepeatDays || [];
        const daySelected = currentDays.includes(day);

        const updatedDays = daySelected
          ? currentDays.filter(d => d !== day)
          : [...currentDays, day];

        newData[modalIndex] = { ...newData[modalIndex], customRepeatDays: updatedDays };
        setCustomData(newData);
      }}
    >
      {day}
    </Button>
  ))}
</div>

      </div>

      <div className='px-2 py-3 d-flex flex-column gap-3'>
  <h6 style={{fontWeight:'500'}}>Ends</h6>
  <div className='d-flex gap-3 align-items-center'>
    <Form.Check 
      type='radio'
      checked={customData[modalIndex].customEndTime === 'never'}
      onChange={() => {
        const newData = [...customData];
        newData[modalIndex] = { ...newData[modalIndex], customEndTime: 'never' };
        setCustomData(newData);
      }}
    />
    <h6 className='my-auto'>Never</h6>
  </div>
  <div className='d-flex gap-3 align-items-center'>
    <Form.Check 
      type='radio'
      checked={customData[modalIndex].customEndTime === 'on'}
      onChange={() => {
        const newData = [...customData];
        newData[modalIndex] = { ...newData[modalIndex], customEndTime: 'on' };
        setCustomData(newData);
      }}
    />
    <Form.Group className='d-flex gap-2 align-items-center'>
      <h6 className='my-auto'>On</h6>
      <Form.Control
        type='date'
        name='customEndDate'
        min={today}
        value={customData[modalIndex].customEndDate}
        onChange={(event) => {
          const newData = [...customData];
          newData[modalIndex] = { ...newData[modalIndex], customEndDate: event.target.value };
          setCustomData(newData);
        }}
      />
    </Form.Group>
  </div>
  <div className='d-flex gap-3 align-items-center'>
    <Form.Check 
      type='radio'
      checked={customData[modalIndex].customEndTime === 'after'}
      onChange={() => {
        const newData = [...customData];
        newData[modalIndex] = { ...newData[modalIndex], customEndTime: 'after' };
        setCustomData(newData);
      }}
    />
    <Form.Group className='d-flex gap-2 align-items-center'>
      <h6 className='my-auto'>After</h6>
      <Form.Control
        type='text'
        style={{width:'40px'}}
        name='customEndOccurrence'
        value={customData[modalIndex].customEndOccurrence}
        onChange={(event) => {
          const numericValue = event.target.value.replace(/\D/g, "");
          const newData = [...customData];
          newData[modalIndex] = { ...newData[modalIndex], customEndOccurrence: numericValue };
          setCustomData(newData);
        }}
      />
      <h6 className='my-auto'>Occurrence</h6>
    </Form.Group>
  </div>
</div>



    </Modal.Body>
  </Modal>
)))} */}

          
            </div>
  )
}

export default CreatePromo
