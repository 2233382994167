

import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import productRoute from '../../services/productRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import arrowLeft from '../../assets/arrow-left.svg'
import deleteIcon from '../../assets/delete.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LogoutModal from '../../components/Modal/Logout';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';


const ViewProductCategories = () => {
    const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
    const location = useLocation()
    const { categoryName } = useParams();

  const categoryId = location.state?.id;


    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isStockOpen, setIsStockOpen] = useState(false);
    const [isSeasonOpen, setIsSeasonOpen] = useState(false);
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [globalProducts, setGlobalProducts] = useState([]);
    const { getProducts, getGlobalProducts, getProductCategory, filterByCategory, filterBySeason,filterByStockLevel, filterProductsByCategory } = productRoute();
    const [exportFormat, setExportFormat] = useState(null); 
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [categories, setCategories] = useState([]);
  
    const [currentProduct, setCurrentProduct] = useState(null);
    const tableRef = useRef(null);
    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);
    useEffect(() => {
        // Restore the current page if available in location state
        if (location.state?.currentPage) {
            setPagination(prevState => ({
                ...prevState,
                currentPage: location.state.currentPage
            }));
           
        }
    }, [location?.state]);
     
    const [pagination, setPagination] = useState({
      currentPage: location.state?.currentPage || 1,
        itemsPerPage: 20,
        totalPages: 0,
        totalProducts: 0,
    });
  
  
      useEffect(() => {
        // Define a debounced version of the getProductCategory function
        const debouncedGetCategories = debounce((searchProduct) => {
            filterProductsByCategory(categoryId, setLoading, setProducts, showToast, searchProduct, pagination,setPagination,);
        }, 500);
    
        // Call the debounced getProductCategory function when the search product changes and it's not empty
        if (searchProduct !== '') {
          setSearchPerformed(true)
            debouncedGetCategories(searchProduct);
        } else {
            // If the search product is empty, call getProductCategory immediately
            filterProductsByCategory(categoryId, setLoading, setProducts, showToast, pagination,setPagination );
        }
    
        // Cleanup function to cancel the debounced function
        return () => {
            debouncedGetCategories.cancel();
        };
    }, [pagination?.currentPage, searchProduct, location.state?.currentPage]);


// useEffect(() => {
//     if (categoryId) {
//         filterProductsByCategory(categoryId, setLoading, setProducts, showToast);
//     }
// }, [categoryId]);

  useEffect(() => {

    getGlobalProducts(
      setLoading, showToast, setGlobalProducts, 
     
    )
      
    }, []);

    useEffect(() => {
  

      getProductCategory(
        setLoading, showToast, setCategories
       
      )
      
    }, []);


        useEffect(() => {
          function handleClickOutside(event) {
            if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
              setIsOpen(false);
            }
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
              setIsOpen2(false);
            }
            if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
              setIsExportOpen(false);
            }
          }
      
          function handleScroll() {
            
            setIsExportOpen(false);
          }
      
          // Add event listeners
          document.addEventListener("mousedown", handleClickOutside);
          window.addEventListener("scroll", handleScroll, true);
      
          return () => {
            // Clean up
            document.removeEventListener("mousedown", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
          };
        }, []);

        const handleCSVExport = () => {
          const csvData = prepareCSVData();
          if (csvData) {
            return (
              <CSVLink data={csvData.data} headers={csvData.headers} filename="product-list.csv" className='csv-link'>
                As CSV
              </CSVLink>
            );
          } else {
            console.error("CSV data is not available.");
          }
        };
        
        const prepareCSVData = () => {
          if (globalProducts.length === 0) {
            console.warn("No products available for CSV export.");
            return null;
          }
          
          const headers = [
            { label: 'Name', key: 'productName' },
            { label: 'Category', key: 'categoryName' },
            { label: 'Selling Price', key: 'price' },
            // Add more headers as needed
          ];
          
          const data = globalProducts.map(product => ({
            productName: product.productName,
            categoryName: product?.category?.categoryName, // Corrected mapping
            price: product?.price,
            // Map other product properties
          }));
          
          return { headers, data };
        };
        
        

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);
  const toggleSeasonDropdown = () => setIsSeasonOpen(!isSeasonOpen);
  const toggleStockDropdown = () => setIsStockOpen(!isStockOpen);
  const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
  const handleFocus = () => {
    setIsFocused(true)
  };

  const handleProductClick = (productId) => {
 
    navigate(`/admin/products/${productId}`);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
 
  const handleToggleSelectAll = () => {
    if (selectedProductIds.length === products?.length) {
        setSelectedProductIds([]); // Deselect all
    } else {
        setSelectedProductIds(products.map(product => product.id)); // Select all
    }
  };

  const handleCheckboxChange = (productId, isChecked, event) => {
    
    
    if (isChecked) {
        setSelectedProductIds(prevIds => [...prevIds, productId]);
    } else {
        setSelectedProductIds(prevIds => prevIds.filter(id => id !== productId));
    }
};
  

const getStatusStyle = (status) => {
  // Define colors for each stock status
  const colorMap = {
      'in_stock': '#08875D',
      'out_of_stock': '#CE371C',
      'low_stock': '#F2994A'
  };

  // Get color based on status, default to out-of-stock color if not found
  const color = colorMap[status] || colorMap['out_of_stock'];

  // Return common style with specific color
  return {
      color: color,
      border: `1px solid ${color}`,
      padding: '6px 16px',
      borderRadius: '12px'
  };
};

const capitalizeWords = (string) => {
  return string.replace(/_/g, '-') // Replace underscores with spaces
              .toLowerCase() // Convert all to lower case
              .replace(/\b(\w)/g, s => s.toUpperCase()); // Capitalize first letters of each word
};

// Adjusted function to handle different product stock statuses correctly
const determineStatus = (product) => {
  let statusText = 'Level Unknown';
  let statusStyle;

  if (product && product.stockLevel) {
      statusText = capitalizeWords(product.stockLevel);
      statusStyle = getStatusStyle(product.stockLevel);
  } else {
      // Fallback styles if product or stockLevel is undefined
      statusStyle = { color: '#CE371C', border: '1px solid #CE371C', padding: '6px 16px', borderRadius: '12px' };
  }

  return <span style={statusStyle}>{statusText}</span>;
};


const handleAdd = ()=> {
  navigate('/admin/products/add')
}

const handleBulkAction = (action) => {
  const selectedProducts = products.filter(product => selectedProductIds.includes(product.id));
  
  const productsToAction = selectedProductIds.length > 0 ? { products: selectedProducts } : { products: products };

  // Navigate to the bulk action route and pass necessary product details or flag to fetch all: 
  navigate(`/admin/products/bulk/${action}`, {
    state: {productsToAction}
  });
};



const handleSelectStockStatus = (stock) => {
  let stockLevel;
  if(stock === 'In-stock'){
    stockLevel='in_stock'
  }else if(stock === 'Low-stock'){
    stockLevel='low_stock'
  }else if(stock === 'Out-of-stock'){
    stockLevel='out_of_stock'
  }
  filterByStockLevel(stockLevel, setLoading, setProducts, showToast,setPagination,categoryId);
  
};

const handleSeasonStatusFilter = (status) => {
  let seasonStatus;
  if(status === 'In Season'){
    seasonStatus = true
  }else if(status === 'Off Season'){
    seasonStatus = false
  }
  filterBySeason(seasonStatus, setLoading, setProducts, showToast, setPagination,categoryId);
  
};



const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename: 'Products-List',
  sheet: 'Products'
})



const handlePDFExport = () => {
  const input = document.getElementById('product-table');
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
    pdf.save('product-list.pdf');
  });
};

const getAllProducts = ()=> {
    filterProductsByCategory(categoryId, setLoading, setProducts, showToast);
}

  return (
    <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
       <div className='d-flex gap-3 myalifn-items-center'>  <img src={arrowLeft} onClick={()=> navigate(-1)} alt='arrow'/>
        <h3 style={{color:'#344054'}} className='my-auto'> Products By Category</h3></div>
     <div className='d-flex gap-3'>
   
     {selectedProductIds.length === 0 && (
      <Dropdown show={isOpen} ref={dropdownRef1}>
        <Button id="dropdown-basic" variant='secondary'  onClick={toggleDropdown} className='d-flex align-items-center gap-2 p-2'>
        Bulk Actions <FontAwesomeIcon icon={faChevronDown} />
        </Button>

        <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleBulkAction('price')} >Price Update</Dropdown.Item>
        <DropdownDivider/>
        <Dropdown.Item onClick={() => handleBulkAction('stock')}>Stock Update</Dropdown.Item>
        {/* <DropdownDivider/>
        <Dropdown.Item>Image Update</Dropdown.Item>
        <DropdownDivider/>
        <Dropdown.Item>Category Update</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>)}

      <Button className='d-flex align-items-center gap-3 p-2' onClick={handleAdd}>
      <FontAwesomeIcon icon={faPlus} />
       Add Product 
      </Button>

      
     </div>
    </div>
  

   {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):products?.length > 0 ? <div className='px-3'>
    <div className='d-flex align-items-center justify-content-between py-4'>
      <div className='d-flex align-items-center gap-5'>
       
       
  {selectedProductIds.length > 0 ? (
     <Form.Check
     checked={selectedProductIds.length === products?.length}
     label={`${selectedProductIds.length} selected`}
     className='selected-check'
     onClick={handleToggleSelectAll}/> 
  ): <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'> {products?.length} Products</p> }

{selectedProductIds.length > 0 && (
     <Dropdown show={isOpen}  ref={dropdownRef1}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown} className='d-flex align-items-center gap-4 p-2'  style={{fontWeight:'500', color:'#333333', fontSize:'12px'}}>
     Bulk Actions <FontAwesomeIcon icon={faChevronDown} />
     </Button>

     <Dropdown.Menu>
     <Dropdown.Item onClick={() => handleBulkAction('price')} >Price Update</Dropdown.Item>
     <DropdownDivider/>
     <Dropdown.Item onClick={() => handleBulkAction('stock')}>Stock Update</Dropdown.Item>
     {/* <DropdownDivider/>
     <Dropdown.Item>Image Update</Dropdown.Item>
     <DropdownDivider/>
     <Dropdown.Item>Category Update</Dropdown.Item> */}
     </Dropdown.Menu>
   </Dropdown>
  ) }
        <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500'}}>
        {!isFocused && searchProduct === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
    
         </>)}
         {searchProduct !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchProduct('')}></i>}
        <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search by product name'
        className='w-100'
        value={searchProduct}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={(e)=>setSearchProduct(e.target.value)}
        />
      </div>
      </div>
       
        <div className='d-flex gap-2 align-items-center'>
            {/* <Button variant='secondary' className='d-flex align-items-center gap-4' style={{fontWeight:'500', color:'#333333'}}>Filter<FontAwesomeIcon icon={faChevronDown} style={{fontSize:'0.8rem'}}/></Button> */}
            <Dropdown show={isOpen2}  ref={dropdownRef2}>
     <Button variant='secondary' id="dropdown-basic"  onClick={toggleDropdown2} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#333333'}}>
     Filter <FontAwesomeIcon icon={faChevronDown} />
     </Button>

    <Dropdown.Menu>
    <Dropdown.Item onClick={()=> {getAllProducts(); toggleDropdown2()}} className='d-flex justify-content-between'>All</Dropdown.Item>
   
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleSeasonDropdown} className='d-flex justify-content-between'>Season Status &nbsp; {isSeasonOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
          {isSeasonOpen &&  [ 'In Season', 'Off Season'].map((status, index) => (
  <Dropdown.Item
    key={index}
    
    onClick={() => {handleSeasonStatusFilter(status); toggleDropdown2()}}
  > {status}
  </Dropdown.Item>
))}
     <DropdownDivider/>
     <Dropdown.Item onClick={toggleStockDropdown} className='d-flex justify-content-between'>Stock Level {isStockOpen? <FontAwesomeIcon icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}</Dropdown.Item>
     {isStockOpen && [ 'In-stock', 'Low-stock', 'Out-of-stock'].map((stock, index) => (
  <Dropdown.Item
    key={index}
    
    onClick={() => {handleSelectStockStatus(stock); toggleDropdown2()}}
  > {stock}
  </Dropdown.Item>
))}
         
     {/* <DropdownDivider/>
     <Dropdown.Item>Date</Dropdown.Item> */}
    </Dropdown.Menu>
   </Dropdown>

   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
        <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 '>
        Export<img src={exportIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onDownload}>As Excel</Dropdown.Item>
          <DropdownDivider />
          <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
          <DropdownDivider />
       
          {handleCSVExport()}
      
      
         
        </Dropdown.Menu>
      </Dropdown>
            
        </div>
    </div>
    <Table hover className='my-3' id="product-table" ref={tableRef} >
       <thead>
            <tr style={{fontWeight:'600'}} className='mb-2'>
            <th style={{fontWeight:'600'}} className='pb-3'></th>
                <th style={{fontWeight:'600'}} className='pb-3'>Name</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Selling Price</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Discount Price</th>
                <th style={{fontWeight:'600'}} className='pb-3'>Stock Level</th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                <th style={{fontWeight:'600'}} className='pb-3'></th>
                
            </tr>
        </thead>
            <tbody className='py-2'>
            {products?.map((product) => (
              <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={product?.id}  onClick={() => handleProductClick(product.id)}>
                <td style={{paddingTop: '15px', paddingBottom: '15px' }}> <div onClick={(e) => e.stopPropagation()}>
            <Form.Check
                checked={selectedProductIds.includes(product.id)}
                onChange={(e) => {
                    handleCheckboxChange(product.id, e.target.checked);
                }}
            />
        </div>
</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px', color:'#194298', cursor:'pointer', textDecoration:'underline' }} onClick={() =>    navigate(`/admin/products/${product?.id}`)}>{product?.productName}</td>
              
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>₦{product?.price?.toLocaleString()}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{product?.discount ? product?.discount.toLocaleString() : '-'}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(product)}</td>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}><div onClick={(e) => e.stopPropagation()}className='d-flex'><img src={edit}   onClick={() =>
                    navigate(`/admin/products/${product?.id}`, {
                      state: { isEditing: true },
                    })
                  } style={{cursor:'pointer'}}/> &nbsp;&nbsp; <img src={deleteIcon} onClick={() => { setCurrentProduct(product);
    setShowDeleteModal(true);
}}/></div></td>
               <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}}  onClick={() => handleProductClick(product.id)}>View</td>
              </tr>
            ))}
            </tbody>
    </Table> 
 
   
  </div>
    : searchPerformed && products.length === 0 ?  <div className='fw-medium px-5' style={{padding:'40px'}}><h4 className='mb-2'>No products found</h4><Button onClick={() => setSearchProduct('')}>View all products</Button>
    </div>:
    <div className='fw-medium ' style={{padding:'40px'}}><h4>You have no products</h4><Button onClick={() => setSearchProduct('')}>View all products</Button></div>
   }


<DeleteProductModal product={currentProduct}/>
  
    </section>
  
  )
}

export default ViewProductCategories;