
import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Form, Button, Image, Dropdown, DropdownDivider, Overlay, Popover, Row, Col, Pagination, Table, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import profileImage from '../../assets/admin-image.svg'
import search from '../../assets/search.svg';
import exportIcon from '../../assets/export-icon.svg';
import user from '../../assets/profile-circled.svg'
import orderRoute from '../../services/orderRoute';
import { useAppContext } from '../../AppContext';
import edit from '../../assets/edit.svg'
import {useNavigate} from 'react-router-dom'
import deleteIcon from '../../assets/deactivate.svg'
import calendarGreen from '../../assets/calendar-green.svg'
import { CSVLink } from 'react-csv';
// import {ExportToExcel} from 'react-export-table-to-excel';
import { useDownloadExcel, DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers } from "react-icons/fa";
import { IoCardOutline } from "react-icons/io5";
import { IoCalendarClearOutline } from "react-icons/io5"
import InviteAdminModal from '../../components/Modal/InviteAdminModal';
import DeleteProductModal from '../../components/Modal/DeleteProductModal';
import adminRoute from '../../services/adminRoute';




const AdminListing = () => {
  const { showToast,showDeleteModal, setShowDeleteModal } = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [searchAdmin, setSearchAdmin] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  
  const {getAdmins} = adminRoute()
  const [loading, setLoading] = useState(false);

  const [currentAdmin, setCurrentAdmin] = useState(null);
 
  const [exportFormat, setExportFormat] = useState(null); 
  const [selectedAdminIds, setSelectedAdminIds] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isUserGroupOpen, setIsUserGroupOpen] = useState(false);
  const [pagination, setPagination] = useState({
      currentPage: 1,
      itemsPerPage: 20,
      totalPages: 0,
      totalAdmins: 0,
  });
  const tableRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const [adminDetails, setAdminDetails] = useState([]);
  const [adminDisplayDetails, setAdminDisplayDetails] = useState([]);

  useEffect(() => {
    getAdmins(
      setLoading,
      showToast,
      setAdminDetails,
      setAdminDisplayDetails,
      pagination,
      setPagination, 
    )
    
}, [pagination.currentPage]);

   

// useEffect(() => {

//   getGlobalOrders(
//     setLoading, showToast, setGlobalOrders, 
   
//   )
    
//   }, []);

 

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setIsOpen(false);
          }
          if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsDateOpen(false);
          }
          if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setIsExportOpen(false);
          }
          if (dropdownRef5.current && !dropdownRef5.current.contains(event.target)) {
            setIsUserGroupOpen(false);
          }
          if (dropdownRef4.current && !dropdownRef4.current.contains(event.target)) {
            setIsStatusOpen(false);
          }
        }
    
        function handleScroll() {
          setIsOpen(false);
          setIsOpen2(false);
          setIsStatusOpen(false);
          setIsDateOpen(false);
          setIsExportOpen(false);
          setIsUserGroupOpen(false)
        }
    
        // Add event listeners
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener("scroll", handleScroll, true);
    
        return () => {
          // Clean up
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener("scroll", handleScroll, true);
        };
      }, []);

    
      
  
      
      

const toggleDropdown = () => setIsOpen(!isOpen);
const toggleDropdown2 = () => setIsOpen2(!isOpen2);

const toggleStatusDropdown = () => setIsStatusOpen(!isStatusOpen);

const toggleExportDropdown = () => setIsExportOpen(!isExportOpen);
const handleFocus = () => {
  setIsFocused(true)
};

const handleBlur = () => {
  setIsFocused(false);
};
const handlePageChange = (page) => {
  setPagination(prevState => ({
    ...prevState,
    currentPage: page
  }));
};

const handleToggleSelectAll = () => {
  if (selectedAdminIds.length === adminDisplayDetails.length) {
      setSelectedAdminIds([]); // Deselect all
  } else {
      setSelectedAdminIds(adminDisplayDetails.map(admin => admin.id)); // Select all
  }
};

const handleCheckboxChange = (adminId, isChecked, event) => {

  
  if (isChecked) {
      setSelectedAdminIds(prevIds => [...prevIds, adminId]);
  } else {
      setSelectedAdminIds(prevIds => prevIds.filter(id => id !== adminId));
  }
};



const capitalizeFirstLetter = (string) => {
  return string.replace(/\b(\w)/g, s => s.toUpperCase());
};
const getStatusStyle = (status) => {
  switch (status) {
    case 'active':
      return { backgroundColor: 'white', color: '#115F04', border:'1px solid #115F04', padding:'6px 16px 6px 16px', borderRadius:'12px' };
    
    case 'pending':
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #B25E09', padding:'6px 16px 6px 16px', borderRadius:'12px' };
    case 'inactive':
      
    return  { backgroundColor: 'white', color: '#F2994A', border:'1px solid #F2994A', padding:'6px 16px 6px 16px',borderRadius:'12px' };
    default:
      return { backgroundColor: 'white', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px',borderRadius:'12px' };
  }
};

const determineStatus = (admin) => {
  const status = admin?.user?.status?.toLowerCase() || 'unknown';
  const statusText = admin?.user?.status ? capitalizeFirstLetter(admin.user?.status) : ' Unknown';
  const statusStyle = getStatusStyle(status);
  return <span style={statusStyle}>{statusText}</span>;
};







const handleCSVExport = () => {
  const csvData = prepareCSVData();
  if (csvData) {
    return (
      <CSVLink data={csvData.data} headers={csvData.headers} filename="admin-list.csv" className='csv-link'>
        As CSV
      </CSVLink>
    );
  } else {
    console.error("CSV data is not available.");
  }
};

const prepareCSVData = () => {
  if (adminDisplayDetails.length === 0) {
    console.warn("No products available for CSV export.");
    return null;
  }
  
  const headers = [
    { label: 'Profile Image', key: 'profileImage' },
    { label: 'Name', key: 'adminName' },
    { label: 'User Group', key: 'userGroup' },
    { label: 'Email', key: 'adminEmail' },
    { label: 'Status', key: 'status' },
    
  ];
  
  const data = adminDisplayDetails.map(admin => ({
    profileImage: admin?.user?.profilePicture,
    adminName: `${admin?.user?.firstName} ${admin?.user?.lastName}`,
    userGroup: admin.userGroup || '-',
    adminEmail: admin.email,
    status: admin?.user?.status
  }));
  
  return { headers, data };
};



const handlePDFExport = () => {
const input = document.getElementById('admin-table');
html2canvas(input).then((canvas) => {
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgWidth = 190;
  const imgHeight = canvas.height * imgWidth / canvas.width;
  pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  pdf.save('admin-list.pdf');
});
};



const getAllAdmins = ()=> {
  getAdmins(
    setLoading,
    showToast,
    setAdminDetails,
    setAdminDisplayDetails,
    pagination,
    setPagination, 
  )
}

return (
  <section className=''>
  <div className='d-flex justify-content-between align-items-center header-admin  py-5 pe-5 ps-4'>
      <h3 style={{color:'#344054'}}>Admins</h3>
   <div className='d-flex gap-3'>
   <Dropdown show={isExportOpen}  ref={dropdownRef3}>
      <Button  variant='secondary' id="dropdown-basic"   style={{fontWeight:'500', color:'#333333'}} onClick={toggleExportDropdown} className='d-flex align-items-center gap-2 px-3 py-2'>
      <img src={exportIcon} />Export
      </Button>
      <Dropdown.Menu>
      <Dropdown.Item>
      <DownloadTableExcel
                    filename="Admin table"
                    sheet="admins"
                    currentTableRef={tableRef.current}
                >
        <button style={{border:'none', backgroundColor:'transparent'}}>As Excel</button>
        </DownloadTableExcel>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item onClick={handlePDFExport}>As PDF</Dropdown.Item>
        <DropdownDivider />
     
        {handleCSVExport()}
    
    
       
      </Dropdown.Menu>
    </Dropdown>
  
      <Button  variant='secondary'    style={{fontWeight:'500', color:'#333333'}} className='d-flex align-items-center gap-2 py-2 px-3'>
        <FaUsers/>
      User Groups
      </Button>
     

    <Button className='d-flex align-items-center gap-2 py-2 px-3' onClick={()=> setShowInviteModal(true)} >
    <IoCardOutline />
   Invite Admin
    </Button>

    
   </div>
  </div>
  <div className=' pe-5 ps-4' >

 {loading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):adminDisplayDetails?.length > 0 ? <>

  <div className='d-flex align-items-center justify-content-between py-4'>
    <div className='d-flex align-items-center gap-5'>
     
     
{selectedAdminIds.length > 0 ? (
  <Form.Check
  checked={selectedAdminIds.length === adminDisplayDetails.length}
  label={`${selectedAdminIds.length} selected`}
  className='selected-check'
  onClick={handleToggleSelectAll}
/>
) : <p style={{color:'#1D2939', fontWeight:'500'}} className='my-auto'>{ pagination?.totalAdmins !== 0 ? pagination?.totalAdmins : adminDisplayDetails?.length} {pagination?.totalAdmins?.length > 1 || adminDisplayDetails?.length > 1 ? 'Admins' :'Admin'}</p> }


      <div className='d-flex align-items-center position-relative search-bar py-1 px-1' style={{border:'1px solid #D9D9D9', fontWeight:'500', width:'300px'}}>
      {!isFocused && searchAdmin === '' && (<>
      <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
  
       </>)}
       {searchAdmin !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchAdmin('')}></i>}
      <Form.Control
      style={{border: 'none', backgroundColor: 'transparent'}}
      placeholder="Search by admin's name"
      className='w-100'
      value={searchAdmin}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      onChange={(e)=>setSearchAdmin(e.target.value)}
      />
    </div>
    </div>
     
      <div className='d-flex gap-3'>
      <Button variant='secondary'  onClick={()=> getAllAdmins()} className='d-flex align-items-center gap-2 py-2 px-3'>
        All 
        </Button>
          <Dropdown show={isUserGroupOpen}  ref={dropdownRef5}>
  
   <Button variant='secondary' id="dropdown-basic" onClick={() => setIsUserGroupOpen(!isUserGroupOpen)} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
  User Groups {isUserGroupOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
  {isUserGroupOpen && [ 'Super Admin', 'Admin'].map((user, index) => (
  <Dropdown.Item
    key={index}
  > {user}
  </Dropdown.Item>
))}
   
  </Dropdown.Menu>
 </Dropdown>
 <Dropdown show={isDateOpen}  ref={dropdownRef2} >
 <Button variant='secondary' id="dropdown-basic" onClick={() => setIsDateOpen(!isDateOpen)} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
   Date {isDateOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  

  <Dropdown.Menu className='me-5' >
 
 {isDateOpen && (
   <div className='d-flex flex-column gap-3 p-3 ' style={{width:'180px',overflowX:'hidden'}}>
     <Form.Group>
     <Form.Label>Start Date</Form.Label>
     <DatePicker
       selected={startDate}
       onChange={(date) => setStartDate(date)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
     <Form.Group>
     <Form.Label>End Date</Form.Label>
     <DatePicker
       selected={endDate}
       onChange={(date) => setEndDate(date)}
       selectsEnd
       startDate={startDate}
       endDate={endDate}
       minDate={startDate}
       className='w-100' style={{overflowX:'hidden'}}
     />
     </Form.Group>
    
     <Button className='text-center w-30' >Filter</Button>
   </div>
 )}

</Dropdown.Menu>
</Dropdown>
 <Dropdown show={isStatusOpen}  ref={dropdownRef4}>
 
   <Button variant='secondary' id="dropdown-basic" onClick={toggleStatusDropdown} className='d-flex align-items-center gap-2'  style={{fontWeight:'500', color:'#626262'}}>
    Status {isStatusOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
   </Button>

  <Dropdown.Menu className='me-5' >
 
    {isStatusOpen && [ 'Active', 'Pending', 'Inactive'].map((status, index) => (
  <Dropdown.Item
    key={index}
  > {status}
  </Dropdown.Item>
))}
  </Dropdown.Menu>
 </Dropdown>
 
      </div>
  </div>

  <Table hover className='my-3' ref={tableRef} id="admin-table">
     <thead>
          <tr style={{fontWeight:'600'}} className='mb-2'>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
          <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600', width:'10%'}} className='pb-3'>Admin Name</th>
              <th style={{fontWeight:'600'}} className='pb-3'>User Group</th>
              <th style={{fontWeight:'600', width:'8%'}} className='pb-3'> Email Address</th>
         
              <th style={{fontWeight:'600'}} className='pb-3'>Status</th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              <th style={{fontWeight:'600'}} className='pb-3'></th>
              
          </tr>
      </thead>
          <tbody className='py-2'>
          {adminDisplayDetails?.map((admin) => (
            <tr style={{fontSize:'14px', fontWeight:'400',color:'#333333', paddingTop: '15px', paddingBottom: '15px' }} key={admin?.id} >
              <td style={{paddingTop: '15px', paddingBottom: '15px' }}> 
        
  <div>
      <Form.Check
          checked={selectedAdminIds.includes(admin.id)}
          onChange={(e) => {
              handleCheckboxChange(admin.id, e.target.checked);
          }}
      />
  </div>


</td>
<td style={{ }}> <img src={admin?.user?.profilePicture ? admin?.user?.profilePicture : user} style={{width:'40px', height:'40px', borderRadius:"50%"}}/></td> 
             <td style={{paddingTop: '15px', paddingBottom: '15px',color:'#115F04', cursor:'pointer', width:'10%',textDecoration:'underline' }} onClick={() => navigate(`/admin/listing/${admin?.user?.id}`)}>{admin?.user?.firstName}&nbsp;{admin?.user?.lastName}</td>
         <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{admin?.userGroup ? admin?.userGroup : '-'}</td> 
             
             <td style={{paddingTop: '15px', paddingBottom: '15px', width:'8%' }}>{admin?.email ? admin?.email : '-'}</td>
           
             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(admin)}</td>

             <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
          <img src={edit}/> &nbsp;&nbsp; <img src={deleteIcon} onClick={() => { setCurrentAdmin(admin);
    setShowDeleteModal(true);
}}/></td>
             <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={() => navigate(`/admin/listing/${admin?.user?.id}`)}>View</td>
            </tr>
          ))}
          </tbody>
  </Table> 

  { adminDisplayDetails.length > 0 && pagination.totalPages > 1 &&  (

<div className='d-flex justify-content-between align-items-center my-5 pt-5' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>Showing {adminDisplayDetails?.length} of {pagination.totalAdmins} Results</p>
  {pagination.totalPages > 1 &&
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}
</>
 :
 <div className='fw-medium ' style={{padding:'50px 0'}}><h4>There are no admins</h4></div>
}
</div>
<InviteAdminModal showInviteModal={showInviteModal} setShowInviteModal={setShowInviteModal}/>
<DeleteProductModal  item={currentAdmin}
      itemType="admin"
    />

  </section>

)
}

export default AdminListing
