import { useState } from 'react'
import {Image,Form, Button,Modal, Card } from 'react-bootstrap'

import logo from '../../assets/vs-word-logo.png'
import { Link, useNavigate } from 'react-router-dom'
import google from '../../assets/google-icon.svg'
import apple from '../../assets/apple-icon.svg'
import facebook from '../../assets/facebook-icon.svg'
import GuestNavbar from '../../components/Navbar/Navbar';
import eye from '../../assets/eye.svg'
import authRoute from '../../services/authRoute'
import { useAppContext } from '../../AppContext'
import homeBg from '../../assets/home-bg.png'
import { getParsedLocalStorageItem } from './AdminProfile'
import user from '../../assets/profile-circled.svg'


const AdminLogin = () => {

  const {showToast}= useAppContext()
 
 const navigate = useNavigate();
  const {login}= authRoute();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const userData = getParsedLocalStorageItem('userData');
  
 const handleTogglePassword = () => {
        setShowPassword(!showPassword);
      };

    
    const handleLogin = (e)=> {
        e.preventDefault();
      
        const body = { 
          password: password,
          email: email || userData?.email,
        }
  
   login (
    body,
    setLoading,
    showToast
    
  )
    }


 
    
   
  return (
    <> 
  
             
           <section className=''>
           <Card style={{border:"none", borderRadius:'0px'}} className='d-block d-md-none '>
         
              <div style={{width:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white', marginTop:'80px'}} className=' mx-auto p-5'>
           

            {userData ?   <> <div className='text-center d-block'><Image fluid src={logo} style={{width:'75px'}} className='mb-4'/>
            <br/>
            <Image fluid src={userData?.profilePicture ? userData?.profilePicture : user} style={{width:'100px'}}/> <h2 className='mt-2 mb-1' style={{color:'#115F04'}}>Welcome back, {userData.firstName}</h2>
            <p style={{color:'#6B7280'}} className='mt-0'>Enter your password to continue</p>
            </div>

                 
              
<Form onSubmit={handleLogin}>
 
   
    <Form.Group className=''>
    <Form.Label>Password</Form.Label>
    <div className='position-relative'>
  <button type="button"
className=" border-0 position-absolute bg-white"
onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}

>
{showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
</button> 
<Form.Control
       type={showPassword ? 'text' : 'password'}
    name="password"
    id="password"
    placeholder="••••••••"
    className=""
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  </div>
 
    </Form.Group>
    <div className='d-flex justify-content-end mt-1'>
     <p style={{cursor:'pointer', color:'#919191'}} onClick={()=>navigate('/admin/forgot-password')}>Forgot password?</p>
    </div>
    
    <div className='mt-3'><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
  
</Form> </>: <>
<div className='text-center'><Image fluid src={logo} style={{width:'75px'}} />

              <h2 className='mt-1 mb-3' style={{color:'#115F04'}}>Login to your account</h2></div>

                 
              
                   <Form onSubmit={handleLogin}>
                       <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                       <Form.Group className=''>
                       <Form.Label>Password</Form.Label>
                       <div className='position-relative'>
                     <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
         
       >
         {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
       </button> 
       <Form.Control
                          type={showPassword ? 'text' : 'password'}
                       name="password"
                       id="password"
                       placeholder="••••••••"
                       className=""
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       required
                     />
                     </div>
                    
                       </Form.Group>
                       <div className='d-flex justify-content-end mt-1'>
                        <p style={{cursor:'pointer', color:'#919191'}} onClick={()=>navigate('/admin/forgot-password')}>Forgot password?</p>
                       </div>
                       
                       <div className='mt-3'><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
                     
                   </Form></>}
               
                   </div>
         
          
                
                

                  </Card> 
            <Card style={{border:"none", borderRadius:'0px'}} className='d-none d-md-block'>
            <Card.Img src={homeBg} alt='bg' style={{ opacity: '', width:'100%', maxHeight: '750px', objectFit: 'cover' }} />

            <Card.ImgOverlay style={{backgroundColor: 'rgba(0, 0, 0, 0.4)'}} className='r'>
              <div style={{width:'32rem',border:'none', boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px',backgroundColor:'white', marginTop:'80px'}} className=' mx-auto p-5'>
           

            {userData ?   <> <div className='text-center d-block'><Image fluid src={logo} style={{width:'75px'}} className='mb-4'/>
            <br/>
            <Image fluid src={userData?.profilePicture ? userData?.profilePicture : user} style={{width:'100px'}}/> <h2 className='mt-2 mb-1' style={{color:'#115F04'}}>Welcome back, {userData.firstName}</h2>
            <p style={{color:'#6B7280'}} className='mt-0'>Enter your password to continue</p>
            </div>

                 
              
<Form onSubmit={handleLogin}>
 
   
    <Form.Group className=''>
    <Form.Label>Password</Form.Label>
    <div className='position-relative'>
  <button type="button"
className=" border-0 position-absolute bg-white"
onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}

>
{showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
</button> 
<Form.Control
       type={showPassword ? 'text' : 'password'}
    name="password"
    id="password"
    placeholder="••••••••"
    className=""
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  </div>
 
    </Form.Group>
    <div className='d-flex justify-content-end mt-1'>
     <p style={{cursor:'pointer', color:'#919191'}} onClick={()=>navigate('/admin/forgot-password')}>Forgot password?</p>
    </div>
    
    <div className='mt-3'><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
  
</Form> </>: <>
<div className='text-center'><Image fluid src={logo} style={{width:'75px'}} />

              <h2 className='mt-1 mb-3' style={{color:'#115F04'}}>Login to your account</h2></div>

                 
              
                   <Form onSubmit={handleLogin}>
                       <Form.Group className='mb-2'>
                       <Form.Label style={{color:'#5D5D5D'}}>Email address</Form.Label>
                       <Form.Control
                       type="email"
                       name="email"
                       id="email"
                       className=""
                       placeholder="e.g. user@email.com"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       required
                     />
                       </Form.Group>
                      
                       <Form.Group className=''>
                       <Form.Label>Password</Form.Label>
                       <div className='position-relative'>
                     <button type="button"
         className=" border-0 position-absolute bg-white"
         onClick={handleTogglePassword} style={{top:'20%', right:'3%'}}
         
       >
         {showPassword ? <i className="bi bi-eye-slash" style={{color:'#B0B0B0'}}></i> : <img src={eye} style={{width:'16px', height:'16px'}}/>}
       </button> 
       <Form.Control
                          type={showPassword ? 'text' : 'password'}
                       name="password"
                       id="password"
                       placeholder="••••••••"
                       className=""
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       required
                     />
                     </div>
                    
                       </Form.Group>
                       <div className='d-flex justify-content-end mt-1'>
                        <p style={{cursor:'pointer', color:'#919191'}} onClick={()=>navigate('/admin/forgot-password')}>Forgot password?</p>
                       </div>
                       
                       <div className='mt-3'><Button type='submit' className='btn-primary w-100'>Sign in</Button></div>
                     
                   </Form></>}
               
                   </div>
            </Card.ImgOverlay>
          
                
                

                  </Card> 
                  
               </section>
          
                  
     </>
    
 
       
   
  )
}

export default AdminLogin
